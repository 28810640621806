import {CREATE_INVENTORY} from './types';

const initialState = {
    data: {}
};

export function createInventoryRed(state = initialState, action: any) {
    if (action.type === CREATE_INVENTORY) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}