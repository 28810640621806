import {
  BASE_64_URL,
  GET_ORG_FAILURE,
  GET_ORG_REQUEST,
  GET_ORG_SUCCESS,
  GET_ACCESS_TOKEN,
  SET_USER_OS_CONTEXT
} from './types'

export const storeBase64Email = (base64Email: string) => {
  return {
    type: BASE_64_URL, // Replace with an appropriate action type
    payload: base64Email
  }
}

export const getOrgReq = () => ({
  type: GET_ORG_REQUEST
})

export const getOrgSuc = (orgList: any) => ({
  type: GET_ORG_SUCCESS,
  payload: orgList
})

export const getOrgFail = (orgList: any) => ({
  type: GET_ORG_FAILURE,
  payload: orgList
})

export const storeAccessToken = (token: string) => {
  return {
    type: GET_ACCESS_TOKEN,
    payload: `Bearer ${token}`
  }
}

export const setOsContext = (osContext: any) => ({
  type: SET_USER_OS_CONTEXT,
  payload: osContext
})
