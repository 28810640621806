import React, { useEffect, useRef, useState } from 'react'

import { Grid, Container } from '@mui/material'
import { WppBanner, WppProgressIndicator, WppSelect, WppListItem } from '@wppopen/components-library-react'
import { Chart, registerables } from 'chart.js'
import { useSelector } from 'react-redux'

import { buildAssessmentChart } from './charts'
import styles from './PIADashboard.module.scss'
import DashboardCards from '../../../components/dashboardCards/DashboardCards'
import { StatusObject } from '../../../helper/interface'
import serviceURL from '../../../helper/serviceURL'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'

export const PIADashboard = () => {
  Chart.register(...registerables)
  const canvasRefAssessment = useRef(null)
  const orgList = useSelector((state: any) => state.getOrgByUserRed.data)
  const userContext = useSelector((state: any) => state.getOrgByUserRed.context)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [approved, setApproved] = useState(0)
  const [expired, setExpired] = useState(0)
  const [rejected, setRejected] = useState(0)
  const [waiting, setWaiting] = useState(0)
  const [review, setReview] = useState(0)
  const [risky, setRisky] = useState(0)
  const [created, setCreated] = useState(0)
  const [selectedOrg, setSelectedOrg] = useState<{ id: number; name: string }>({ id: 0, name: '' })
  const { axiosInstance } = useAxiosInterceptors()

  useEffect(() => {
    const fetchData = () => {
      const apiUrl =
        selectedOrg?.name === 'All'
          ? serviceURL.pgpBaseAPI + '/api/report/VENDOR/privacy-review'
          : serviceURL.pgpBaseAPI + '/api/report/VENDOR/privacy-review?orgId=' + selectedOrg?.id
      setIsLoading(true)
      axiosInstance
        .get(apiUrl)
        .then((res: any) => {
          createAssessmentStatusChart(res?.data)
          setIsLoading(false)
          setIsToShowBanner(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          setIsToShowBanner(true)
        })
    }

    if (selectedOrg?.id || selectedOrg?.name) {
      fetchData()
    }
  }, [axiosInstance, selectedOrg?.id])

  useEffect(() => {
    const defaultOrg = orgList?.organizations?.find((org: { id: number }) => org?.id === userContext?.id)
    setSelectedOrg(defaultOrg)
  }, [orgList?.organizations, userContext?.id])

  const createAssessmentStatusChart = (response: StatusObject) => {
    let approved = 0
    let expired = 0
    let rejected = 0
    let waiting = 0
    let review = 0
    let risky = 0
    let created = 0
    if (response?.assessmentByStatus) {
      approved = response?.assessmentByStatus.approved
      expired = response?.assessmentByStatus.expired
      waiting = response?.assessmentByStatus.waiting
      rejected = response?.assessmentByStatus.rejected
      review = response?.assessmentByStatus.review
      created = response?.assessmentByStatus.created
      risky = response?.assessmentByStatus.riskcalculation
      setApproved(isNaN(approved) ? 0 : approved)
      setExpired(isNaN(expired) ? 0 : expired)
      setRejected(isNaN(rejected) ? 0 : rejected)
      setWaiting(isNaN(waiting) ? 0 : waiting)
      setReview(isNaN(review) ? 0 : review)
      setRisky(isNaN(risky) ? 0 : risky)
      setCreated(isNaN(created) ? 0 : created)
    }
    buildAssessmentChart(canvasRefAssessment, [approved, expired, waiting, rejected, review, risky, created])
  }

  return (
    <Container maxWidth="xl">
      {isLoading && <WppProgressIndicator variant="bar" className={styles.customLoader} />}
      <WppBanner id="banner" type="information" show={isToShowBanner}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
      <div className={styles.network}>
        <WppSelect
          onWppChange={e => setSelectedOrg(e.target.value)}
          placeholder="Select Organisation"
          value={selectedOrg}
          size="s"
        >
          <WppListItem value={{ name: 'All' }}>
            <p slot="label">All</p>
          </WppListItem>
          {orgList &&
            orgList.organizations.map(
              (
                item: {
                  name: string
                },
                idx: number
              ) => {
                return (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                )
              }
            )}
        </WppSelect>
      </div>

      <Grid container spacing={3} marginTop="5rem" justifyContent="center">
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Total Assessments"
            count={approved + expired + rejected + review + waiting + risky + created}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Approved"
            count={approved}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Expired"
            count={expired}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Rejected"
            count={rejected}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Created"
            count={created}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Response"
            count={waiting}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Review"
            count={review}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Risky"
            count={risky}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={10} marginTop="5rem">
          <div className={styles.sectionDoughnut}>
            <p>Assessments Status</p>
            <div ref={canvasRefAssessment} style={{ width: '100%', height: '400px' }} />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PIADashboard
