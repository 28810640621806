// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tanlB{display:flex;justify-content:flex-end;margin-top:5px}`, "",{"version":3,"sources":["webpack://./src/components/textField/TextField.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,wBAAA,CACA,cAAA","sourcesContent":[".charactersLimit{\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charactersLimit": `tanlB`
};
export default ___CSS_LOADER_EXPORT___;
