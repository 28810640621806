export const RESPONSE_STATS = 'RESPONSE_STATS';
export const PORTAL_TYPE = 'PORTAL_TYPE';
export const COUNTRY_TYPE = 'COUNTRY_TYPE';
export const STATE_TYPE = 'STATE_TYPE';
export const DSAR_BY_TYPE = 'DSAR_BY_TYPE';
export const COMPLETED_TYPE = 'COMPLETED_TYPE';
export const PROGRESS_DS_TYPE = 'PROGRESS_DS_TYPE';
export const PROGRESS_DMP_TYPE = 'PROGRESS_DMP_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';
export const CANCELLED_TYPE = 'CANCELLED_TYPE';
export const FINISHED_BY_DMP = 'FINISHED_BY_DMP';
export const PROGRESS_BY_DMP = 'PROGRESS_BY_DMP';
export const ERROR_BY_DMP = 'ERROR_BY_DMP';
