export const WPP_CATEGORICAL_COLORS = [
    '#C2F5D4',
    '#84EBAA',
    '#E0FAEA',
    '#47E17F'
    // '#cdb4db',
    // '#ffc8dd',
    // '#ffafcc',
    // '#bde0fe',
    // '#a2d2ff',
    // '#d8f3dc',
    // '#b7e4c7',
    // '#74c69d',
    // '#07beb8',
    // '#3dccc7',
    // '#68d8d6',
    // '#9ceaef',
    // '#c4fff9',
    // '#07B6D4',
    // '#7B90DF',
    // '#8F979D',
    // '#4d86a5',
    // '#ccd7e4',
    // '#12e2f1',
    // '#9ad4d6',
    // '#c5d5ea',
    // '#d8e1e9',
    // '#c3c4e9',
    // '#2dffdf',
];
