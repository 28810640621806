import React, { useEffect, useRef, useState } from 'react'

import {
  WppIconButton,
  WppIconAttach,
  WppTypography,
  WppIconDownload,
  WppIconTrash,
  WppModal,
  WppButton,
  WppIconClose,
  WppSpinner,
  WppTag
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import axios from 'axios'

import { FileUpload } from './FileUpload'
import styles from './QueAnsAssessment.module.scss'
import appConfig from '../../app.config'
import { uploadFileName } from '../../helper/Helper'

export const AttachmentList = ({ files, onDelete, onDownload, isValidToViewUpload }: any) => (
  <div className={styles.attachmentList}>
    {files.map(({ filename, id }: any, index: any) => (
      <div key={`${filename}-${index}`} className={styles.attachmentItem}>
        <WppTypography className={styles.fileName}>
          {index + 1}. &nbsp;{uploadFileName(filename)}
        </WppTypography>
        <a href={onDownload(filename)} className={styles.downloadLink}>
          <WppIconDownload style={{ cursor: 'pointer' }} color="primary" size="s" />
        </a>
        {isValidToViewUpload && (
          <WppIconTrash
            style={{ cursor: 'pointer' }}
            color='primary'
            size='s'
            onClick={() => onDelete({ filename, id })}
          />
        )}
      </div>
    ))}
  </div>
)

export const ConfirmationModal = ({ open, onClose, onConfirm, loading }: any) => (
  <WppModal open={open} size='m' className={styles.modalBox}>
    <div slot='header' className={styles.modalHeader}>
      <h3>File Delete</h3>
      <WppIconClose color='black' onClick={onClose} className={styles.close} size="m" />
    </div>
    <p slot='body' className={styles.modalBody}>
      Are you sure you want to delete this file?
    </p>
    <div slot='actions' className={styles.modalAction}>
      <WppButton variant='secondary' size='s' className={styles.btn} onClick={onConfirm}>
        {loading ? <WppSpinner size='s' /> : 'Delete'}
      </WppButton>
      <WppButton variant='secondary' className={styles.btn} size='s' onClick={onClose}>
        Cancel
      </WppButton>
    </div>
  </WppModal>
)

const RenderAttachment = React.memo(
  ({ attachmentFile, queId, assessmentId, isValidToViewUpload, updateData, dynamicKey }: any) => {
    const {
      osApi: { getAccessToken }
    } = useOs()

    const headers = {
      accept: '*/*',
      Authorization: `Bearer ${getAccessToken()}`
    }

    const [isFileModalOpen, setIsFileModalOpen] = useState(false)
    const [attachedFiles, setAttachedFiles] = useState<Array<File>>(attachmentFile)
    const [isDeleteFileConfirm, setIsFileDeleteConfirm] = useState(false)
    const [loadingFiles, setLoadingFiles] = useState<{ [key: string]: boolean }>({})
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const isFirstRun = useRef(true)

    useEffect(() => {
      if (isFirstRun.current) {
        isFirstRun.current = false
        return
      }
      updateData({ dynamicKey, attachedFiles })
    }, [attachedFiles])
    const handleDelete = async (data: any) => {
      const apiUrl = `${appConfig.RA_HOST_URL}/api/file/${data?.id}?filename=${data?.filename}`
      const res = await axios.delete(apiUrl, { headers })
      return res.data
    }

    const handleFileDelete = async ({ filename, id }: any) => {
      setLoadingFiles(prevLoading => ({ ...prevLoading, [filename]: true }))
      const result = await handleDelete({ filename, id })
      if (result) {
        const filteredFiles = attachedFiles.filter((file: any) => file.filename !== filename)
        setAttachedFiles(filteredFiles)
        setLoadingFiles(prevLoading => ({ ...prevLoading, [filename]: false }))
        setIsFileDeleteConfirm(false)
      }
    }

    const handleOpenDeleteModal = (file: any) => {
      setSelectedFile(file)
      setIsFileDeleteConfirm(true)
    }

    const downloadLink = (filename: string) => `${appConfig.RA_HOST_URL}/api/responses/download?filename=${filename}`

    return (
      <div className={styles.attachmentFile}>
        {isValidToViewUpload && (
          <>
            <div className={styles.uploadBtn}>
              <WppTag label='Upload file on behalf of vendor: ' variant="warning" maxLabelLength={100} />
              <WppIconButton
                className={styles.btn}
                size="s"
                onClick={() => setIsFileModalOpen(true)}
                style={{ margin: '0px !important' }}
              >
                <WppIconAttach style={{ color: '#000000' }} />
              </WppIconButton>
            </div>
            <FileUpload
              open={isFileModalOpen}
              handleClose={setIsFileModalOpen}
              questionId={queId}
              assessmentId={assessmentId}
              attachedFiles={attachedFiles}
              setAttachedFiles={setAttachedFiles}
            />
          </>
        )}
        {attachedFiles && attachedFiles.length ? (
          <AttachmentList
            files={attachedFiles}
            onDelete={handleOpenDeleteModal}
            onDownload={downloadLink}
            isValidToViewUpload={isValidToViewUpload}
          />
        ) : null}
        <ConfirmationModal
          open={isDeleteFileConfirm}
          onClose={() => setIsFileDeleteConfirm(false)}
          onConfirm={() => handleFileDelete(selectedFile)}
          loading={selectedFile ? loadingFiles[selectedFile.filename] : false}
        />
      </div>
    )
  }
)

export default RenderAttachment
