import validJson from './validation.json';
const spaceBetweenWithNoSpecialCharacterValidation = (field: any) => {
    if (field.value.length && !field.value.match(new RegExp('^(?!.*[!@#$%^*()<>.;:\\\\|\\]\\[=+`~])\\s*[A-Za-z0-9_-][A-Za-z0-9 _-]*$'))) {
        field.error = 'Special characters not allowed';
    }
};

const validateEmail = (field: any) => {
    if (field.value?.length && !field.value.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        field.error = 'Invalid email address';
    }
};

const checkCharacterLimit = (field: any, limit: number) => {
    if(field?.value?.length > limit){
        field.error = `Max limit is ${limit} characters.`;
    }
}

export const validate = (values: any) => {
    const extractedValues: any = [];
    validJson.forEach((valid: any) => {
        if (valid.name in values) {
            const fieldToCheck = {
                value: values[valid.name],
                error: ''
            };
            if (valid?.criteria.includes('Required')) {
                if (typeof fieldToCheck.value === 'number') {
                    fieldToCheck.error = fieldToCheck.value === 0 ? 'Field is Mandatory' : '';
                } else if (Array.isArray(fieldToCheck.value)) {
                    fieldToCheck.error = fieldToCheck.value.length === 0 ? 'Field is Mandatory' : '';
                } else {
                    fieldToCheck.error = fieldToCheck.value === null || fieldToCheck.value === undefined ||
                    fieldToCheck.value === '' || fieldToCheck.value.id === 0
                        ? 'Field is Mandatory'
                        : '';
                }
            }

            if(valid?.criteria.includes('SpaceBetweenWithNoSpecialCharacter')){
                spaceBetweenWithNoSpecialCharacterValidation(fieldToCheck);
            }

            if(valid?.criteria.includes('EmailValidation')){
                validateEmail(fieldToCheck);
            }
            if(valid?.criteria.includes('MaxLength')){
                const limit = 70;
                checkCharacterLimit(fieldToCheck, limit);
            }
            extractedValues.push({ name: valid.name, error: fieldToCheck.error });
        }
    });
    return extractedValues;
};
