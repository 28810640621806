import axios from 'axios'

import { GET_INITIAL_VENDOR_DATA, SET_CREATE_VENDOR_DATA, SET_TRIGGER_RELOAD } from './types'
import { serviceURL } from '../../../../helper/serviceURL'

export const getInitialData = (data: any) => ({
  type: GET_INITIAL_VENDOR_DATA,
  payload: data
})

export const getAssessmentData = (data: any) => ({
  type: GET_INITIAL_VENDOR_DATA,
  payload: data
})

export const setCreateVendorData = (data: any) => ({
  type: SET_CREATE_VENDOR_DATA,
  payload: data
})

export const setTriggerReload = (data: boolean) => ({
  type: SET_TRIGGER_RELOAD,
  payload: data
})

export const fetchInitialData = (type: string, id: string, header: object) => (dispatch: any) => {
  const vendorDetails = serviceURL.pgpBaseAPI + '/api/inventory/' + type + '/' + id
  return new Promise((resolve, reject) => {
    axios
      .get(vendorDetails, { headers: header })
      .then(vendorResponse => {
        const data = {
          vendorData: vendorResponse.data
        }
        dispatch(getInitialData(data))
        resolve(data)
      })
      .catch(error => {
        reject(new Error(`Failed to fetch initial data: ${error?.message || error}`));
      })
  })
}
