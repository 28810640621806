import { ColDef } from 'ag-grid-community'

import { IdNameProps, ProgressStatus, SearchData } from './interface'
import MultipleValueWithToolTip from './MultipleValueWithToolTip'
import TagStatusCategoryIndicator from './TagStatusCategoryIndicator'
import TagStatusVariantIndicator from './TagStatusVariantIndicator'
import TagTotalRiskIndicator from './TagTotalRiskIndicator'
import appConfig from '../app.config'
import StatusIndicator from '../components/dataGrid/StatusIndicator'

export const REGION = [
  { key: 'DEV', value: 'DEV' },
  { key: 'QA', value: 'QA' },
  { key: 'INT', value: 'INT' },
  { key: 'AMER', value: 'AMER' },
  { key: 'APAC', value: 'APAC' },
  { key: 'EMEA', value: 'EMEA' }
]

export const PROD = 'PROD'
export const NPROD = 'NPROD'
export const DATA_MAPPING = 'Data Mapping'
export const REMOVE_FILTER = ['APAC', 'EMEA']

export const getRegion = () => {
  if (appConfig.NPROD === PROD) {
    return [
      { key: 'AMER', value: 'AMER' },
      { key: 'APAC', value: 'APAC' },
      { key: 'EMEA', value: 'EMEA' }
    ]
  } else {
    return [
      { key: 'DEV', value: 'DEV' },
      { key: 'QA', value: 'QA' },
      { key: 'INT', value: 'INT' }
    ]
  }
}

export const DURATION = [
  { key: '24hrs', value: 'Past 24 Hours' },
  { key: '1weak', value: 'Past 1 Weak' },
  { key: '1month', value: 'Past 1 Month' },
  { key: '6month', value: 'Past 6 Month' },
  { key: '1yr', value: 'Past 1 Year' },
  { key: '2yr', value: 'Past 2 Year' },
  { key: '3yr', value: 'Past 3 Year' }
]

export const SELECT_TYPES: IdNameProps[] = [
  { id: 1, name: 'DATA_ASSET' },
  { id: 2, name: 'PROCESSING_ACTIVITY' },
  { id: 3, name: 'VENDOR' },
  { id: 4, name: 'LEGAL_ENTITY' }
]

export const BUSINESS_TYPES: IdNameProps[] = [
  { id: 1, name: 'AFFILIATE' },
  { id: 2, name: 'BUSINESS_PARTNER' },
  { id: 3, name: 'HOLDING_COMPANY' },
  { id: 4, name: 'CLIENT' },
  { id: 5, name: 'SUPPLIER_OR_VENDOR' },
  { id: 6, name: 'SUBSIDIARY' },
  { id: 7, name: 'WHOLLY_OWNED_SUBSIDIARY' }
]

export const INVENTORY = {
  PA_TO_LE: 'paLegalEntityInventories',
  PA_TO_VE: 'paVendorInventories',
  PA_TO_DA: 'paDataAssetInventories',
  PA_TO_PA: 'paProcessingActivityInventories',
  DA_TO_LE: 'daLegalEntityInventories',
  DA_TO_VE: 'daVendorInventories',
  DA_TO_DA: 'daDataAssetInventories',
  DA_TO_PA: 'daProcessingActivityInventories',
  VE_TO_LE: 'vendorLegalEntityInventories',
  VE_TO_VE: 'vendorVendorInventories',
  VE_TO_DA: 'vendorDataAssetInventories',
  VE_TO_PA: 'vendorProcessingActivityInventories',
  LE_TO_LE: 'leLegalEntityInventories',
  LE_TO_VE: 'leVendorInventories',
  LE_TO_DA: 'leDataAssetInventories',
  LE_TO_PA: 'leProcessingActivityInventories',
  LINK_LE: 'Linked Legal Entity',
  LINK_VE: 'Linked Vendor',
  LINK_DA: 'Linked Data Asset',
  LINK_PA: 'Linked Processing Activity',
  TYPE_LE: 'LEGAL_ENTITY',
  TYPE_VE: 'VENDOR',
  TYPE_DA: 'DATA_ASSET',
  TYPE_PA: 'PROCESSING_ACTIVITY',
  DATA_PRIVACY_MANAGER: 'Data Privacy Team Only'
}
export const ASSESSMENT = {
  DATA_PRIVACY_MANAGER: 'Data Privacy Team Only'
}
type searchList = [
  SearchData?,
  SearchData?,
  SearchData?,
  SearchData?,
  SearchData?,
  SearchData?,
  SearchData?,
  SearchData?
]
export const CPP_SEARCHS: searchList = [{ id: 1, name: 'DSAR Id. ', apiName: 'id', value: '' }]
export const INVENTORY_SEARCHS: searchList = [
  { id: 1, name: 'Inventory name', apiName: 'name', value: '', placeholder: 'Assessment name' },
  { id: 2, name: 'CreatedBy', apiName: 'createdBy', value: '', placeholder: 'Created By' },
  { id: 3, name: 'Status', apiName: 'status', value: '', placeholder: 'Active | Pending | Rejected' }
]
export const ASSESSMENT_SEARCHS: searchList = [
  { id: 1, name: 'Assessment name', apiName: 'name', value: '', placeholder: 'Assessment name' },
  { id: 2, name: 'CreatedBy', apiName: 'createdBy', value: '', placeholder: 'Created By' },
  {
    id: 3,
    name: 'Status',
    apiName: 'status',
    value: '',
    placeholder: 'Approved | Created | Review / Response Pending | Rejected'
  },
  { id: 4, name: 'Description', apiName: 'description', value: '', placeholder: 'Description' }
]
export const VENDORS_SEARCHS: searchList = [
  { id: 1, name: 'Vendor Name', apiName: 'name', value: '', placeholder: 'Vendor Name' },
  { id: 2, name: 'CreatedBy', apiName: 'createdBy', value: '', placeholder: 'Created By' },
  { id: 3, name: 'Status', apiName: 'status', value: '', placeholder: 'Active | Pending | Rejected' },
  { id: 7, name: 'Service Type', apiName: 'inventoryServiceType.serviceType', value: '', placeholder: 'Service Type' },
  { id: 4, name: 'Market', apiName: 'market.name', value: '', placeholder: 'Market' },
  { id: 5, name: 'Region', apiName: 'region.name', value: '', placeholder: 'Region' },
  { id: 6, name: 'Network', apiName: 'organization.name', value: '', placeholder: 'Network' },
  { id: 7, name: 'Agency', apiName: 'agency.name', value: '', placeholder: 'Agency' }
]

export const SELECT_ASSESSMENT_REVIEW_OUTCOME: IdNameProps[] = [
  { id: 1, name: 'APPROVED' },
  { id: 2, name: 'CANCELLED' },
  { id: 3, name: 'REJECTED' },
  { id: 6, name: 'SEND_FOR_RE_ASSESSMENT' }
]
export const INV_GRID_HEADER_DETAILS: ColDef[] = [
  {
    field: 'name',
    headerName: 'Inventory',
    width: 250,
    pinned: 'left',
    sortable: true,
    colId: 'name',
    unSortIcon: true
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    colId: 'status',
    cellRenderer: TagStatusVariantIndicator,
    sortable: true,
    unSortIcon: true
  },
  { field: 'market', headerName: 'Market', width: 200, colId: 'market', sortable: true, unSortIcon: true },
  {
    field: 'masterInventory',
    headerName: 'Master Inventory',
    width: 150,
    colId: 'masterInv',
    cellRenderer: TagStatusCategoryIndicator
  },
  { field: 'region', headerName: 'Region', width: 200, colId: 'region', sortable: true, unSortIcon: true },
  { field: 'createdBy', headerName: 'Created By', width: 250, colId: 'createdBy' },
  { field: 'createdAt', headerName: 'Created At', width: 300, colId: 'createdAt' }
]
export const ASSES_GRID_HEADER_DETAILS: ColDef[] = [
  {
    field: 'name',
    headerName: 'Assessment',
    width: 250,
    pinned: 'left',
    sortable: true,
    colId: 'name',
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'inventoryName',
    headerName: 'Vendor',
    width: 250,
    colId: 'inventoryName',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'serviceType.serviceType',
    headerName: 'Service Type',
    width: 200,
    colId: 'serviceType',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    unSortIcon: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    colId: 'status',
    cellRenderer: TagStatusCategoryIndicator,
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'agency.name',
    headerName: 'Agency',
    width: 200,
    colId: 'agencyName',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'agency.market.name',
    headerName: 'Market',
    width: 200,
    colId: 'marketName',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'agency.organization.name',
    headerName: 'Network',
    width: 200,
    colId: 'networkName',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    width: 200,
    colId: 'riskScore',
    filter: 'agTextColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    cellRenderer: TagTotalRiskIndicator,
    cellStyle: { textAlign: 'center' },
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['equals'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'template.name',
    headerName: 'Template',
    width: 200,
    colId: 'templateName',
    filter: 'agTextColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'reviewedBy',
    headerName: 'Reviewed By',
    width: 200,
    colId: 'reviewedBy',
    filter: 'agTextColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'reviewerStatus',
    headerName: 'Reviewer Action',
    width: 200,
    colId: 'reviewerStatus',
    filter: 'agTextColumnFilter',
    cellRenderer: TagStatusCategoryIndicator,
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'reviwerActionAt',
    headerName: 'Reviewed At',
    width: 200,
    colId: 'reviwerActionAt',
    filter: 'agDateColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      filterOptions: ['inRange'],
      suppressFilterButton: true,
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 250,
    colId: 'createdBy',
    filter: 'agTextColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 300,
    colId: 'createdAt',
    filter: 'agDateColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      filterOptions: ['inRange'],
      suppressFilterButton: true,
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'modifiedAt',
    headerName: 'Last Activity Date',
    width: 300,
    colId: 'modifiedAt',
    filter: 'agDateColumnFilter',
    sortable: true,
    unSortIcon: true,
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      filterOptions: ['inRange'],
      suppressFilterButton: true,
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    colId: 'description',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  }
]

export const VENDORS_GRID_HEADER_DETAILS: ColDef[] = [
  {
    field: 'name',
    headerName: 'Vendor',
    width: 250,
    pinned: 'left',
    sortable: true,
    colId: 'name',
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    colId: 'status',
    cellRenderer: TagStatusVariantIndicator,
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'serviceTypes',
    headerName: 'Service Type',
    width: 200,
    colId: 'serviceTypes',
    cellRenderer: MultipleValueWithToolTip,
    cellStyle: { textAlign: 'center' },
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'dataSubjectLocations',
    headerName: 'Data Subject Locations',
    width: 230,
    colId: 'dataSubjectLocations',
    cellRenderer: MultipleValueWithToolTip,
    cellStyle: { textAlign: 'center' },
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'country.name',
    headerName: 'Country',
    width: 200,
    colId: 'country.name',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'industry.name',
    headerName: 'Industry',
    width: 200,
    colId: 'industry.name',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 250,
    colId: 'createdBy',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 300,
    colId: 'createdAt',
    sortable: true,
    unSortIcon: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      filterOptions: ['inRange'],
      suppressFilterButton: true,
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'modifiedAt',
    headerName: 'Modified At',
    width: 300,
    colId: 'modifiedAt',
    sortable: true,
    unSortIcon: true,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      filterOptions: ['inRange'],
      suppressFilterButton: true,
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    colId: 'description',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    filterParams: {
      suppressFilterButton: true,
      filterOptions: ['contains', 'Start With'],
      debounceMs: 500,
      suppressAndOrCondition: true
    }
  }
]

export const RISK_REGISTER_GRID_HEADER_DETAILS: ColDef[] = [
  {
    field: 'inventoryId',
    headerName: 'Inventory Id',
    width: 300,
    pinned: 'left',
    colId: 'inventoryId',
    sortable: true,
    unSortIcon: true
  },
  { field: 'riskScore', headerName: 'Risk Score', width: 150, colId: 'riskScore', sortable: true, unSortIcon: true },
  {
    field: 'riskLevel',
    headerName: 'Risk Level',
    width: 150,
    colId: 'riskLevel',
    cellRenderer: StatusIndicator,
    sortable: true,
    unSortIcon: true
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    colId: 'status',
    cellRenderer: TagStatusVariantIndicator,
    sortable: true,
    unSortIcon: true
  },
  {
    field: 'mitigationSteps',
    headerName: 'Mitigation Step',
    width: 300,
    colId: 'mitigationSteps',
    sortable: true,
    unSortIcon: true
  },
  {
    field: 'dateIdentified',
    headerName: 'Date Identified',
    width: 300,
    colId: 'dateIdentified',
    sortable: true,
    unSortIcon: true
  },
  { field: 'lastUpdated', headerName: 'Last Updated', width: 300, colId: 'lastUpdated' }
]

export const ASSESS_PROGRESS_STATUS: ProgressStatus[] = [
  { id: 1, name: 'loading', value: 0 },
  { id: 2, name: 'Created', value: 0.25 },
  { id: 3, name: 'Waiting For Response', value: 0.5 },
  { id: 4, name: 'Response Pending', value: 0.5 },
  { id: 5, name: 'In Review', value: 0.75 },
  { id: 6, name: 'Review Pending', value: 0.75 },
  { id: 7, name: 'Risk-Calculation Pending', value: 0.75 },
  { id: 8, name: 'Pending', value: 0.75 },
  { id: 9, name: 'Completed', value: 1.0 },
  { id: 10, name: 'Approved', value: 1.0 },
  { id: 11, name: 'Rejected', value: 1.0 },
  { id: 12, name: 'Expired', value: 1.0 },
  { id: 13, name: 'Archived', value: 1.0 }
]

export const USER_ROLE = {
  DPM: 'DPM',
  SAD: 'SAD',
  SU: 'SU',
  CPPA: 'CPPA',
  SYS: 'SYS'
}

export const TEXT = {
  MASTER_INV_SUB_DESC:
    'The feature allows you to set up inventory records as master records and then create local versions (linked copies) of that master record. This creates a relationship between the master process and the local version to help with organisation, maintenance, and easy comparison to identify variation that may exist across all local versions of a central process.',
  CPP_CARD:
    "Our Consumer Preference Centre enables consumers to exercise their privacy rights.  In today's digital age, where data privacy and control are paramount, empowering consumers to choose how we use their data is an integral part of our compliance with privacy laws.",
  DM_CARD:
    "Data mapping allows us to visualize and navigate the flow of data within our organisation. Understanding how data moves, where it resides, and how it's processed is crucial for complying with regulations and ensuring its responsible use.  Greater transparency, accountability & improved information governance enables us to build & maintain client trust.",
  PR_CARD:
    "This module provides a comprehensive list of assessments conducted for assets, processing activities, vendors, and legal entities. It enables privacy professionals to review the assessments, identify potential risks, and apply appropriate controls to ensure compliance with data protection regulations. The module helps mitigate potential risks and protect customers' personal information",
  TPRM_CARD:
    "Vendor Risk Management allows us to effectively manage risks associated with third-party vendors. In today's interconnected business landscape, relying on external partners opens doors to potential vulnerabilities. This module enables us to identify, assess, and mitigate these risks.\n" +
    'For further information on how to use this module, click here to navigate to our learning resources.'
}

export const ERROR_MSG = {
  UNABLE_FETCH: 'Unable to fetch information. Please refresh or try after some time.'
}

export const QUE_TYPE = {
  TEXT: 'TEXT', // text area
  SINGLE_SELECT: 'SINGLE_SELECT', // radio button
  MULTI_SELECT: 'MULTI_SELECT', // checkboxes
  DROPDOWN: 'DROPDOWN', // dropdown without api call
  API_DROPDOWN: 'API_DROPDOWN', // dropdown and populate the dropdown from api call
  SINGLE_SELECT_TEXT: 'SINGLE_SELECT_TEXT', // radio button with text
  MULTI_SELECT_TEXT: 'MULTI_SELECT_TEXT', // checkbox with text
  MULTI_SELECT_API: 'MULTI_SELECT_API', // checkbox and populate the checkbox from api call
  PRESELECTED: 'PRESELECTED', // value will be pre-selected
  SINGLE_SELECT_TEXT_OPTIONAL_ATTACHMENT: 'SINGLE_SELECT_TEXT_OPTIONAL_ATTACHMENT', // attachment is mandatory and text is optional
  SINGLE_SELECT_TEXT_OPTIONAL: 'SINGLE_SELECT_TEXT_OPTIONAL', // input value is optional
  MULTI_SELECT_TEXT_OPTIONAL: 'MULTI_SELECT_TEXT_OPTIONAL', // input value is optional
  MULTI_SELECT_TEXT_OPTIONAL_ATTACHMENT: 'MULTI_SELECT_TEXT_OPTIONAL_ATTACHMENT', // attachment is mandatory and text is optional
  MULTI_SELECT_TEXT_CONDITIONAL: 'MULTI_SELECT_TEXT_CONDITIONAL', // input box shown on condition
  SINGLE_SELECT_TEXT_CONDITIONAL: 'SINGLE_SELECT_TEXT_CONDITIONAL' // input box shown on condition
}
export const RISK_LEVEL = {
  NO_RISK: '#CCCCCC',
  LOW: '#6ec0de',
  MEDIUM: '#f6b75a',
  HIGH: '#d1605d',
  VERY_HIGH: '#8d2623',
  CRITICAL: '#380505'
} as const

export const REVIEW_STATUS = {
  REVIEW_PENDING: 'Review Pending'
}
export const GROUPS = {
  DPM: 'DPM'
}
export const STATUS = {
  CREATED: 'Created',
  RESPONSE_PENDING: 'Response Pending',
  REVIEW_PENDING: 'Review Pending',
  COMPLETED: 'Completed',
  RISK_CALCULATION_PENDING: 'Risk Calculation Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  OPEN: 'OPEN'
}
export const NETWORK = 'Network'

export const VENDOR_GRID_HEADER_DETAILS: ColDef[] = [
  {
    field: 'agency.name',
    headerName: 'Agency',
    colId: 'agency.name'
  },
  { field: 'name', headerName: 'Assessment Name', width: 250, colId: 'name' },
  { field: 'serviceType.serviceType', headerName: 'Service Type', colId: 'serviceType.serviceType' },
  { field: 'status', headerName: 'Stage', colId: 'status', cellRenderer: TagStatusCategoryIndicator },
  { field: 'template.name', headerName: 'Template', colId: 'template.name' },
  { field: 'reviewedBy', headerName: 'Reviewed By', colId: 'reviewedBy' },
  {
    field: 'reviewerStatus',
    headerName: 'Reviewer Action',
    colId: 'reviewerStatus',
    cellRenderer: TagStatusCategoryIndicator
  },
  { field: 'reviwerActionAt', headerName: 'Reviewed At', colId: 'reviewerStatus' },
  { field: 'createdAt', headerName: 'Created At', width: 250, colId: 'createdAt' },
  { field: 'createdBy', headerName: 'Created By', width: 250, colId: 'createdBy' }
]

export const BAD_REQUEST = 'BAD_REQUEST'