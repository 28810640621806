export interface serviceUrlProps {
    UI_VERSION: string | null,
    RA_HOST_URL: string,
    DPS_HOST_URL: string,
    RA_PASSWORD: string
    DPS_TOKEN: string,
    NPROD: string,
    CUTOFF_DATE: string
}

/**************************************
 configuration for production
 please do not make any changes here
 **************************************/

const appConfig: serviceUrlProps = {
    UI_VERSION: 'WPPPRDSDN-349-2.0.2',
    RA_HOST_URL: 'https://dev-ra.choreograph.com',
    DPS_HOST_URL: 'https://dev-dps.choreograph.com',
    RA_PASSWORD: 'password',
    DPS_TOKEN: 'YWRtaW46QWRtaW4xMjM0JA==',
    NPROD: 'NPROD',
    CUTOFF_DATE: 'NA'
}

/*******************************************
 configuration for local
 make changes here to run the app locally
 *******************************************/

// const appConfig: serviceUrlProps = {
//     UI_VERSION: 'local-1.0.0',
//     RA_HOST_URL: 'https://dev-ra.choreograph.com',
//     DPS_HOST_URL: 'https://dev-dps.choreograph.com',
//     RA_PASSWORD: 'password',
//     DPS_TOKEN: '',
//     NPROD: 'NPROD',
//     CUTOFF_DATE: 'NA'
// }



export default appConfig;