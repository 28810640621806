import React, { useState, useEffect } from 'react'

import { Grid } from '@mui/material'
import { SelectChangeEventDetail } from '@wppopen/components-library'
import { WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppButton,
  WppModal,
  WppIconClose,
  WppFileUpload,
  WppSelect,
  WppListItem,
  WppLabel,
  WppTag
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  getRegListDispatcher,
  getServiceTypeListDispatcher,
  selectedRegionDispatcher,
  selectedMarketDispatcher,
  selectedAgencyDispatcher,
  getMarketList,
  getAgencyList,
  getRegList,
  getServiceTypeList,
  selectedTypeOfServiceDispatcher,
  getMarketListDispatcher,
  getAgencyListDispatcher
} from 'containers/piaModule/assessmentList/createAssessment/step1/action'
import { getTemplatesDispatcher } from 'containers/piaModule/assessmentList/createAssessment/step2/action'
import { SELECT_TYPES, USER_ROLE } from 'helper/constants'
import { serviceURL } from 'helper/serviceURL'
import useAxiosInterceptors from 'hooks/useAxiosInterceptors'

import styles from './FileUpload.module.scss'

interface FileUploadProps {
  open: boolean
  onClose: (value: boolean) => void
  uploadTost: (value: boolean) => void
}

const FileUpload = ({ open, onClose, uploadTost }: FileUploadProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Array<{ header: string; message: string }>>([])
  const [status, setStatus] = useState<{ name: string; message: string }>({ name: '', message: '' })
  const [selectedTemplate, setSelectedTemplate] = useState<{ id: string }>({ id: '' })

  const templateList = useSelector((state: any) => state?.caGetTemplateReducer?.data)
  const selectedRegion = useSelector((state: any) => state?.caSelectedRegReducer?.data)
  const selectedMarket = useSelector((state: any) => state?.caSelectedMarketReducer?.data)
  const selectedAgency = useSelector((state: any) => state?.caSelectedAgencyReducer?.data)
  const selectedTypeOfService = useSelector((state: any) => state?.caTypeOfServiceReducer?.data)
  const regList = useSelector((state: any) => state?.caRegListReducer?.data)
  const marketList = useSelector((state: any) => state?.caMarketListReducer?.data)
  const agencyList = useSelector((state: any) => state?.caAgencyListReducer?.data)
  const serviceTypeList = useSelector((state: any) => state?.caServiceTypeListReducer?.data)
  const userContext = useSelector((state: any) => state?.getOrgByUserRed?.context)
  const userData = useSelector((state: any) => state?.getOrgByUserRed?.data)
  const vendor = useSelector((state: any) => state?.vendorDetails?.vendor)

  const { axiosInstance } = useAxiosInterceptors()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const isSiteAdmin = userData?.groups.some((group: any) => group?.name === USER_ROLE.SAD)

  const sanitizeFileName = (filename: string) => {
    // eslint-disable-next-line no-useless-escape
    return filename.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9$!~\-_'\.]/g, '')
  }

  const handleFileUploadChange = (event: any) => {
    setError([])
    setStatus({ name: '', message: '' })
    const file = event?.detail?.value[0]

    if (file) {
      const lastDotIndex = file.name.lastIndexOf('.')
      const originalFileName = file.name.substring(0, lastDotIndex)
      const extension = file.name.substring(lastDotIndex)
      const sanitizedFileName = sanitizeFileName(originalFileName) + extension
      setSelectedFile(new File([file], sanitizedFileName, { type: file.type }))
    } else {
      setSelectedFile(null)
    }
  }

  const handleUpload = () => {
    setIsLoading(true)
    const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/offline'
    const formData = new FormData()
    const payload = {
      assessmentTemplateId: selectedTemplate?.id,
      inventoryTypeId: 3,
      inventoryId: vendor?.id,
      name: `${vendor?.name?.trim()} | ${selectedTypeOfService?.serviceType} | ${
        selectedMarket?.name
      } | ${new Date()?.getFullYear()}`,
      agencyId: selectedAgency?.id,
      marketId: selectedMarket?.id,
      serviceTypeId: selectedTypeOfService?.id,
      inventoryName: vendor?.name
    }

    formData.append('data', JSON.stringify(payload))

    if (selectedFile) {
      formData.append('file', selectedFile)
    } else {
      console.error('No file selected')
      setIsLoading(false)
      return
    }

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          accept: '*/*'
        }
      })
      .then(response => {
        setIsLoading(false)
        uploadTost(true)
        dispatch(getRegList?.(''))
        dispatch(getMarketList?.(''))
        dispatch(getAgencyList?.(''))
        dispatch(getServiceTypeList?.(''))
        dispatch(selectedMarketDispatcher?.(''))
        dispatch(selectedAgencyDispatcher?.(''))
        dispatch(selectedRegionDispatcher?.(''))
        dispatch(selectedTypeOfServiceDispatcher?.(''))
        onClose(false)
        navigate(`/vrm/assessments-list/${response?.data?.id}`)
      })
      .catch(error => {
        const errors = error?.response?.data?.errors
        setIsLoading(false)
        errors && errors.length > 0
          ? setError(errors)
          : setStatus({ name: error?.response?.data?.status, message: error?.response?.data?.message })
      })
  }

  useEffect(() => {
    dispatch(getRegList?.(''))
    dispatch(getMarketList?.(''))
    dispatch(getAgencyList?.(''))
    dispatch(getServiceTypeList?.(''))
    dispatch(selectedMarketDispatcher?.(''))
    dispatch(selectedAgencyDispatcher?.(''))
    dispatch(selectedRegionDispatcher?.(''))
    dispatch(selectedTypeOfServiceDispatcher?.(''))
    dispatch(getTemplatesDispatcher?.(headers, SELECT_TYPES[2]))
    dispatch(getRegListDispatcher?.(headers))
    dispatch(getServiceTypeListDispatcher?.(headers))
  }, [])

  const handleTemplateChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setSelectedTemplate(event.target.value)
  }

  useEffect(() => {
    if (selectedRegion?.name) {
      dispatch(getMarketListDispatcher?.(selectedRegion?.id, headers))
    }
  }, [selectedRegion])

  useEffect(() => {
    if (selectedMarket?.name) {
      dispatch(getAgencyListDispatcher?.(userContext?.id, selectedMarket?.id, isSiteAdmin, headers))
    }
  }, [selectedMarket])

  const handleRegion = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    dispatch(selectedRegionDispatcher?.(event.target.value))
    dispatch(getMarketList?.(''))
    dispatch(getAgencyList?.(''))
    dispatch(selectedMarketDispatcher?.(''))
    dispatch(selectedAgencyDispatcher?.(''))
  }

  const handleMarket = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    dispatch(selectedMarketDispatcher?.(event.target.value))
    dispatch(getAgencyList?.(''))
    dispatch(selectedAgencyDispatcher?.(''))
  }

  const handleAgency = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    dispatch(selectedAgencyDispatcher?.(event.target.value))
  }

  const handleTypeOfService = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    dispatch(selectedTypeOfServiceDispatcher?.(event.target.value))
  }

  const handleDisableUpload = () => {
    return !selectedTemplate?.id || !selectedAgency?.id || !selectedTypeOfService?.id || !selectedMarket?.id
  }

  const handleClose = () => {
    onClose(false)
    dispatch(getRegList?.(''))
    dispatch(getMarketList?.(''))
    dispatch(getAgencyList?.(''))
    dispatch(getServiceTypeList?.(''))
    dispatch(selectedMarketDispatcher?.(''))
    dispatch(selectedAgencyDispatcher?.(''))
    dispatch(selectedRegionDispatcher?.(''))
    dispatch(selectedTypeOfServiceDispatcher?.(''))
  }

  return (
    <WppModal
      open={open}
      size='m'
      onWppModalClose={() => handleClose()}
      className={styles.uploadContainer}
      data-testid='file-upload-offline'
    >
      <div slot='header' className={styles.uploadHeader}>
        <h3 className={styles.assessNameProgressContainer}>
          <span>Upload Assessment File</span>
        </h3>
        <WppIconClose color='black' onClick={() => handleClose()} className={styles.close} size='m' />
      </div>
      <div slot='body'>
        <Grid container spacing={{ xs: 2, md: 3 }} rowSpacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel className={styles.vLabel} config={{ text: 'Region' }} htmlFor='v-region' typography='s-strong' />
            <WppSelect
              id='v-region'
              onWppChange={handleRegion}
              placeholder='Select Region'
              value={selectedRegion}
              withSearch={true}
              size='s'
              data-testid='file-upload-region'
            >
              {regList?.length ? (
                regList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot='label'>{item?.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot='label'>No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel className={styles.vLabel} config={{ text: 'Market' }} htmlFor='v-market' typography='s-strong' />
            <WppSelect
              id='v-market'
              onWppChange={handleMarket}
              placeholder='Select Market'
              disabled={!selectedRegion?.name}
              value={selectedMarket}
              withSearch={true}
              size='s'
              data-testid='file-upload-market'
            >
              {marketList?.length ? (
                marketList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot='label'>{item.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot='label'>No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel className={styles.vLabel} config={{ text: 'Agency' }} htmlFor='v-agency' typography='s-strong' />
            <WppSelect
              id='v-agency'
              onWppChange={e => handleAgency(e)}
              placeholder='Select Agency'
              disabled={!selectedMarket?.name}
              value={selectedAgency}
              withSearch={true}
              size='s'
              data-testid='file-upload-agency'
            >
              {agencyList?.length ? (
                agencyList?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot='label'>{item.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot='label'>No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <WppLabel
              className={styles.vLabel}
              config={{ text: 'Type of Service' }}
              htmlFor='v-type-service'
              typography='s-strong'
            />
            <WppSelect
              id='v-type-service'
              onWppChange={handleTypeOfService}
              placeholder='Select Service'
              value={selectedTypeOfService}
              withSearch={true}
              size='s'
              data-testid='file-upload-service'
            >
              {serviceTypeList?.length ? (
                serviceTypeList?.map((item: { serviceType: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot='label'>{item.serviceType}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot='label'>No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <WppLabel
              className={styles.label}
              config={{ text: 'Assessment Template' }}
              htmlFor='assessment-template'
              typography='s-strong'
            />
            <WppSelect
              id='assessment-template'
              onWppChange={e => handleTemplateChange(e)}
              placeholder='Select Template'
              value={selectedTemplate}
              withSearch={true}
              size='s'
              data-testid='file-upload-template'
            >
              {templateList &&
                templateList.map(
                  (
                    item: {
                      name: string
                      id: string
                    },
                    idx: number
                  ) => {
                    return (
                      <WppListItem
                        key={idx}
                        value={item}
                        disabled={item?.id !== 'af9aba2b-6130-4619-961a-d457cd40144e'}
                      >
                        <p
                          slot='label'
                          className={item?.id !== 'af9aba2b-6130-4619-961a-d457cd40144e' ? styles.disable : ''}
                        >
                          {item.name}
                        </p>
                      </WppListItem>
                    )
                  }
                )}
            </WppSelect>
          </Grid>
        </Grid>
        <WppFileUpload
          onWppChange={handleFileUploadChange}
          acceptConfig={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls']
          }}
          className={styles.uploadFile}
          multiple={false}
          disabled={handleDisableUpload()}
          data-testid='file-upload-select'
        />
        {error?.length > 0 ? (
          <ul className={styles.listMain}>
            <h3>Please fix the below error(s)</h3>
            {error?.map(item => {
              return (
                <li className={styles.listChild}>
                  <span className={styles.innerLabel}>{item?.header}</span>
                  <span className={styles.innerLabelValue}>{item?.message}</span>
                </li>
              )
            })}
          </ul>
        ) : status.message ? (
          <WppTag label={status.message} variant='negative' className={styles.errors} maxLabelLength={300} />
        ) : null}
      </div>
      <div slot='actions' className={styles.btnClose}>
        <Grid item xs={12} sm={12} md={12} display='flex' gap={1} justifyContent='right'>
          <WppButton
            className={styles.customBtnSecondary}
            variant='secondary'
            loading={isLoading}
            onClick={handleUpload}
            disabled={!selectedFile?.name?.trim().length || handleDisableUpload()}
            data-testid='file-upload-button'
          >
            Upload
          </WppButton>
          <WppButton className={styles.customBtnSecondary} variant='secondary' onClick={() => handleClose()} data-testid='file-upload-cancel'>
            Cancel
          </WppButton>
        </Grid>
      </div>
    </WppModal>
  )
}

export default FileUpload
