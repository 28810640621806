
import { Grid } from '@mui/material'
import { WppTag, WppIconEdit } from '@wppopen/components-library-react'

import { USER_ROLE } from 'helper/constants'

import styles from './VendorDetails.module.scss'
import { getLocalDate } from '../../../../helper/Helper'
import TagStatusVariantIndicator from '../../../../helper/TagStatusVariantIndicator'

interface CardDetailsProps {
  details: {
    industry?: { name: string } | null
    country?: { name: string } |  null
    status?: string
    createdBy?: string | null
    createdAt?: string | null
    modifiedAt?: string | null
    serviceTypes?: string[]
    description?: string
  }
  hasPermission: (permission: string[]) => boolean
  setIsDescriptionEditEnable: (value: boolean) => void
  setIsIndustryEditEnable: (value: boolean) => void
  setIsCountryEditEnable: (value: boolean) => void
}

const CardDetails: React.FC<CardDetailsProps> = ({ details, hasPermission, setIsDescriptionEditEnable, setIsIndustryEditEnable, setIsCountryEditEnable }) => {
  return (
    <Grid item xs={12} className={styles.container}>
      <div className={styles.secInventoryDetailsTop}>
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.headerBox}>
              <span className={styles.key}>Industry: </span>
              {details?.industry?.name ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.industry?.name}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                    data-testid="vendor-detail-industry"
                  />
                </span>
              ) : (
                ''
              )}
              {hasPermission([USER_ROLE.SAD, USER_ROLE.DPM]) &&
                  <WppIconEdit 
                      data-testid="vendor-industry-edit"
                      className={styles.editBtn} 
                      size="s"
                      onClick={() => {
                        setIsIndustryEditEnable(true)
                      }} 
                    />
                }
            </div>
            <div className={styles.headerBox}>
              <span className={styles.key}>Location: </span>
              {details?.country?.name ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.country?.name}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                    data-testid="vendor-detail-country"
                  />
                </span>
              ) : (
                ''
              )}
              {hasPermission([USER_ROLE.SAD, USER_ROLE.DPM]) &&
                  <WppIconEdit 
                      data-testid="vendor-country-edit"
                      className={styles.editBtn} 
                      size="s"
                      onClick={() => {
                        setIsCountryEditEnable(true)
                      }} 
                    />
                }
            </div>
            <div className={styles.headerBox}>
              <span className={styles.key}>Vendor Status: </span>
              <TagStatusVariantIndicator className={styles.customTag} params={{ value: details?.status }} />
            </div>
            <div className={styles.headerBox}>
              <span className={styles.key}>Service Type: </span>
              {details?.serviceTypes?.length ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.serviceTypes?.map(type => type)?.join(', ')}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                  />
                </span>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={styles.rightContainer}>
          <div className={styles.headerBox}>
              <span className={styles.key}>Description: </span>
              {details?.description ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.description}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                    data-testid="vendor-detail-description-text"
                  />
                </span>
              ) : (
                ''
              )}
              {hasPermission([USER_ROLE.SAD, USER_ROLE.DPM]) &&
                  <WppIconEdit 
                      data-testid="vendor-description-edit"
                      className={styles.editBtn} 
                      size="s"
                      onClick={() => {
                      setIsDescriptionEditEnable(true)
                      }} 
                    />
                }
            </div>
            <DetailItem label="Created By" value={details?.createdBy} />
            <DetailItem label="Created At" value={details?.createdAt && getLocalDate(details?.createdAt, true)} />
            <DetailItem label="Last Activity Date" value={details?.modifiedAt && getLocalDate(details?.modifiedAt, true)} />
          </div>
        </div>
      </div>
    </Grid>
  )
}

interface DetailItemProps {
  label: string
  value?: string | null
}

const DetailItem: React.FC<DetailItemProps> = ({ label, value }) => (
  <div className={styles.headerBox}>
    <span className={styles.key}>{label}: </span>
    <span className={styles.value}>{value || 'N/A'}</span>
  </div>
)

export default CardDetails
