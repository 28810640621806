import { Grid } from '@mui/material'
import { WppTag } from '@wppopen/components-library-react'

import styles from './VendorDetails.module.scss'
import { getLocalDate } from '../../../../helper/Helper'
import TagStatusVariantIndicator from '../../../../helper/TagStatusVariantIndicator'

interface DetailsProps {
  details: {
    industry?: { name: string } | null
    country?: { name: string } |  null
    status?: string
    createdBy?: string | null
    createdAt?: string | null
    modifiedAt?: string | null
    serviceTypes?: string[]
    description?: string
  }
}

const DetailsCard: React.FC<DetailsProps> = ({ details }) => {
  return (
    <Grid item xs={12} className={styles.container}>
      <div className={styles.secInventoryDetailsTop}>
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <DetailItem label="Industry" value={details?.industry?.name} />
            <DetailItem label="Location" value={details?.country?.name} />
            <div className={styles.headerBox}>
              <span className={styles.key}>Vendor Status: </span>
              <TagStatusVariantIndicator className={styles.customTag} params={{ value: details?.status }} />
            </div>
            <div className={styles.headerBox}>
              <span className={styles.key}>Service Type: </span>
              {details?.serviceTypes?.length ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.serviceTypes?.map(type => type)?.join(', ')}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                  />
                </span>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={styles.rightContainer}>
          <div className={styles.headerBox}>
              <span className={styles.key}>Description: </span>
              {details?.description ? (
                <span className={styles.respondedContainer}>
                  <WppTag
                    label={details?.description}
                    className={styles.tag}
                    maxLabelLength={40}
                    tooltipConfig={{ placement: 'bottom' }}
                  />
                </span>
              ) : (
                ''
              )}
            </div>
            <DetailItem label="Created By" value={details?.createdBy} />
            <DetailItem label="Created At" value={details?.createdAt && getLocalDate(details?.createdAt, true)} />
            <DetailItem label="Last Activity Date" value={details?.modifiedAt && getLocalDate(details?.modifiedAt, true)} />
          </div>
        </div>
      </div>
    </Grid>
  )
}

interface DetailItemProps {
  label: string
  value?: string | null
}

const DetailItem: React.FC<DetailItemProps> = ({ label, value }) => (
  <div className={styles.headerBox}>
    <span className={styles.key}>{label}: </span>
    <span className={styles.value}>{value || 'N/A'}</span>
  </div>
)

export default DetailsCard
