import React, { memo, useEffect, useState } from 'react'

import { WppRadio } from '@wppopen/components-library-react'

import styles from './QueAnsAssessment.module.scss'

interface SingleSelectProps {
   response: string
 }

const SingleSelect = memo((props: SingleSelectProps) => {
  const [singleSelectVal, setSingleSelectVal] = useState('')

  const initialiseSingleSelect = (data: string) => {
    if (data) {
      const trimmedData = data.trim() 
      const splitData = trimmedData.split('$$') 
      const firstValue = splitData[0] || ''
      setSingleSelectVal(firstValue)
    }
  }

  useEffect(() => {
    initialiseSingleSelect(props.response)
  }, [props.response])

  return (
    <WppRadio
      disabled
      className={styles.customRadio}
      checked={true}
      value={singleSelectVal}
      labelConfig={{ text: singleSelectVal }}
      required
      name={singleSelectVal}
    />
  )
})

export default SingleSelect
