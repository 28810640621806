import React from 'react'

import { WppSpinner } from '@wppopen/components-library-react'

interface Props {
  loadingMessage?: string
}

const CustomLoading: React.FC<Props> = ({ loadingMessage }) => {
  return (
    <>
      <WppSpinner size="l" color="black" />
      <div
        className="ag-overlay-loading-center"
        style={{ backgroundColor: 'transparent', height: '9%', boxShadow: 'unset', border: 0 }}
      >
        <i className="fas fa-hourglass-half"> {loadingMessage} </i>
      </div>
    </>
  )
}

export default CustomLoading
