import React from 'react'

import { WppTag } from '@wppopen/components-library-react'

const TagStatusCategoryIndicator = (params: any) => {
  const categoryColor = (stat: string) => {
    switch (stat?.toLowerCase()) {
      case 'rejected':
      case 'expired':
      case 'undefined':
        return 3
      case 'approved':
        return 7
      case 'response pending':
        return 2
      case 'review pending':
        return 1
      case 'risk calculation':
        return 8
      default:
        return 8
    }
  }

  return (
    <React.Fragment>
      {params?.value || params?.params?.value ? (
        <WppTag
          className={params?.className}
          categoricalColorIndex={categoryColor(params?.value || params?.params?.value)}
          label={params?.value || params?.params?.value}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TagStatusCategoryIndicator
