import {GET_INVENTORY_BY_ID, LAUNCH_ASSESS, CLEAR_SELECTED_ROW_DATA} from './types';

const initialState = {
    data: {}
};

export function inventoryRowDetailsRed(state = initialState, action: any) {
    if (action.type === GET_INVENTORY_BY_ID) {
        return {
            ...state,
            data: action.payload,
        };
    }else if (action.type === CLEAR_SELECTED_ROW_DATA){
        return {
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function launchAssessRed(state = initialState, action: any) {
    if (action.type === LAUNCH_ASSESS) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}