// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NA_KU{width:100%;margin-top:1rem;height:85%}.ORQwh{opacity:.4;margin-bottom:2rem}.a8iZO{color:#000 !important}.a8iZO svg{text-align:left !important}.GgJZr{margin-bottom:2rem}.PRRGy{margin-bottom:2rem;position:absolute}.zevBy{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}.tZBVK{color:#000}.vbhT3{position:relative}`, "",{"version":3,"sources":["webpack://./src/containers/tprmModule/vendorsList/VendorsList.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CACA,UAAA,CAGF,OACE,UAAA,CACA,kBAAA,CAGF,OACE,qBAAA,CACA,WACE,0BAAA,CAIJ,OACE,kBAAA,CAGF,OACE,kBAAA,CACA,iBAAA,CAGF,OACE,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CAGF,OACE,UAAA,CAGF,OACE,iBAAA","sourcesContent":[".vContainer {\n  width: 100%;\n  margin-top: 1rem;\n  height: 85%;\n}\n\n.vLoader {\n  opacity: 0.4;\n  margin-bottom: 2rem;\n}\n\n.vHeader {\n  color: black !important;\n  & svg {\n    text-align: left !important;\n  }\n}\n\n.vLoadingArea {\n  margin-bottom: 2rem;\n}\n\n.vToast {\n  margin-bottom: 2rem;\n  position: absolute;\n}\n\n.customBtnSecondary {\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lightgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\n.customIcon {\n  color: black;\n}\n\n.vendorContainer{\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vContainer": `NA_KU`,
	"vLoader": `ORQwh`,
	"vHeader": `a8iZO`,
	"vLoadingArea": `GgJZr`,
	"vToast": `PRRGy`,
	"customBtnSecondary": `zevBy`,
	"customIcon": `tZBVK`,
	"vendorContainer": `vbhT3`
};
export default ___CSS_LOADER_EXPORT___;
