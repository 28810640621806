import {
    FINISHED_BY_DMP,
    COMPLETED_TYPE,
    COUNTRY_TYPE,
    CANCELLED_TYPE,
    DSAR_BY_TYPE,
    ERROR_BY_DMP,
    ERROR_TYPE,
    PORTAL_TYPE,
    PROGRESS_DMP_TYPE,
    PROGRESS_DS_TYPE,
    RESPONSE_STATS,
    STATE_TYPE,
    PROGRESS_BY_DMP,
} from './types';

const initialState = {
    data: {},
};

const appendState = (state = initialState, action: any, type: any) => {
    if (action.type === type) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
};

export function responseStatsReducer(state = initialState, action: any) {
    return appendState(state, action, RESPONSE_STATS);
}

export function portalTypeReducer(state = initialState, action: any) {
    return appendState(state, action, PORTAL_TYPE);
}

export function countryTypeReducer(state = initialState, action: any) {
    return appendState(state, action, COUNTRY_TYPE);
}

export function stateTypeReducer(state = initialState, action: any) {
    return appendState(state, action, STATE_TYPE);
}

export function dsarByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, DSAR_BY_TYPE);
}

export function finishedByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, COMPLETED_TYPE);
}

export function progressDSByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, PROGRESS_DS_TYPE);
}

export function progressDMPByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, PROGRESS_DMP_TYPE);
}

export function errorByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, ERROR_TYPE);
}

export function cancelledByTypeReducer(state = initialState, action: any) {
    return appendState(state, action, CANCELLED_TYPE);
}

export function finishedByDMPReducer(state = initialState, action: any) {
    return appendState(state, action, FINISHED_BY_DMP);
}

export function progressByDMPReducer(state = initialState, action: any) {
    return appendState(state, action, PROGRESS_BY_DMP);
}

export function errorByDMPReducer(state = initialState, action: any) {
    return appendState(state, action, ERROR_BY_DMP);
}
