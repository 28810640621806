import React from 'react'

import { WppFilterButton } from '@wppopen/components-library-react'

const FilterComp = ({ filterClick, counter, isShowTxt }: any) => {
  const wppFilterHandler = () => {
    filterClick()
  }
  return (
    <WppFilterButton counter={counter} onClick={() => wppFilterHandler()}>
      {isShowTxt ? 'Show Filters' : 'Clear Filter'}
    </WppFilterButton>
  )
}

export default FilterComp
