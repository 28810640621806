import React, { useLayoutEffect } from 'react'

import { Grid } from '@mui/material'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import styles from './AssessmentSummary.module.scss'
import { STATUS } from '../../helper/constants'
import serviceURL from '../../helper/serviceURL'
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'

const mapStateToProps = (state: any) => {
  return {
    isLaunchAssess: state.launchAssessRed.data,
  }
}

const AssessmentSummary = ({
  assessRowDetails,
  callBackDataLoaded,
  reviewerCommentLoaded,
  assessmentRiskCountDetails,
}: any) => {
  const {
    osApi: { getAccessToken }
  } = useOs()

  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const { axiosInstance } = useAxiosInterceptors()

  const riskCount =
    (assessmentRiskCountDetails?.details?.length > 0 &&
      assessmentRiskCountDetails.details[assessmentRiskCountDetails.details.length - 1]?.riskQnsCount) ||
    0

  useLayoutEffect(() => {
    if (
      assessRowDetails?.status &&
      (assessRowDetails.status.toLowerCase() === 'approved' || assessRowDetails.status.toLowerCase() === 'rejected') &&
      !reviewerCommentLoaded
    ) {
      const fetchReviewerComments = (assessRowDetails: any) => {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/assessment/' + assessRowDetails.id + '/review-comment'
        axiosInstance
          .get(apiUrl, {
            headers: headers
          })
          .then((res: any) => {
            if (res && res.status === 200 && res.data?.comment?.length > 0) {
              callBackDataLoaded?.(res?.data?.comment)
            }
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
      fetchReviewerComments(assessRowDetails)
    }
  }, [assessRowDetails, axiosInstance, callBackDataLoaded, reviewerCommentLoaded])

  const stageIdentifier = (status: string) => {
    switch (status) {
      case 'created':
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case 'waiting for response':
      case 'response pending':
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={` ${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case 'review pending':
      case 'in review':
      case 'risk-calculation pending':
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              {status === 'risk-calculation pending' && (
                <div className={styles.flowContent}>{STATUS.RISK_CALCULATION_PENDING}</div>
              )}
              {status === 'review pending' && <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>}
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
      case 'completed':
      case 'approved':
      case 'rejected':
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.selectedRectangle}`}>
              {status === 'completed' && <div className={styles.flowContent}>{STATUS.COMPLETED}</div>}
              {status === 'approved' && <div className={styles.flowContent}>{STATUS.APPROVED}</div>}
              {status === 'rejected' && <div className={styles.flowContent}>{STATUS.REJECTED}</div>}
            </div>
          </div>
        )
          case 'expired':
            return (
              <div className={styles.centreComp}>
                <div className={`${styles.expiredRectangleBox} ${styles.selectedRectangle} ${styles.arrowRight}`}>
                  <div className={styles.flowContent}>{STATUS.CREATED}</div>
                </div>
                <div className={`${styles.expiredRectangleBox} ${styles.selectedRectangle} ${styles.arrowRight}`}>
                  <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
                </div>
                <div className={`${styles.expiredRectangleBox} ${styles.selectedRectangle} ${styles.arrowRight}`}>
                  <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
                </div>
                <div className={`${styles.expiredRectangleBox} ${styles.selectedRectangle} ${styles.arrowRight}`}>
                  <div className={styles.flowContent}>{STATUS.APPROVED}</div>
                </div>
                <div className={`${styles.expiredRectangleBox} ${styles.expiredRectangle}`}>
                  <div className={styles.flowContent}>{STATUS.EXPIRED}</div>
                </div>
              </div>
            )
      default:
        return (
          <div className={styles.centreComp}>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.CREATED}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.RESPONSE_PENDING}</div>
            </div>
            <div className={`${styles.rectangle} ${styles.arrowRight}`}>
              <div className={styles.flowContent}>{STATUS.REVIEW_PENDING}</div>
            </div>
            <div className={`${styles.rectangle}`}>
              <div className={styles.flowContent}>{STATUS.COMPLETED}</div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className={styles.assessSumryContr}>
      <div className={styles.innerAssessSumryContr}>
        <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={3}>
          {assessRowDetails?.status && (
            <Grid item xs={12} sm={riskCount <= 0 ? 12 : 6} md={riskCount <= 0 ? 12 : 6} display="flex">
              <div className={styles.flowComp}>
                {stageIdentifier(assessRowDetails?.status?.toLowerCase())}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(AssessmentSummary)
