import * as React from 'react';
import { LegacyRef } from 'react';

interface DoughnutChartProps {
  title: string;
  chartRef: LegacyRef<HTMLCanvasElement>;
}
export const DoughnutChart = ({ title, chartRef }: DoughnutChartProps) => {
  return (
    <>
      <p>{title}</p>
      <canvas className="doughnut-charts" ref={chartRef} />
    </>
  );
};
