import {
    SELECTED_RESPONDENT,
    SELECTED_DAYS, ASSESSMENT_NAME,
    RESPONDENT_NOTE, GET_ASSESSER,
    SELECTED_ASSESSER, GET_RESPONDENT,
} from './types';

const initialState = {
    data: ''
};

export function caAssessmentName(state = initialState, action: any) {
    if (action.type === ASSESSMENT_NAME) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedRespondentReducer(state = initialState, action: any) {
    if (action.type === SELECTED_RESPONDENT) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caGetRespondentReducer(state = initialState, action: any) {
    if (action.type === GET_RESPONDENT) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caGetAssesserRed(state = initialState, action: any) {
    if (action.type === GET_ASSESSER) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedDaysReducer(state = initialState, action: any) {
    if (action.type === SELECTED_DAYS) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caRespondentNoteReducer(state = initialState, action: any) {
    if (action.type === RESPONDENT_NOTE) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caSelectedAssesserReducer(state = initialState, action: any) {
    if (action.type === SELECTED_ASSESSER) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}