import React from 'react'

import './DataGridCompModule.scss'
import { WppPill } from '@wppopen/components-library-react'

const StatusIndicator = (params: any) => {
  const statusColorIdentifier = (stat: string) => {
    switch (stat) {
      case 'Open':
        return 'openStatus'
      case 'Closed':
        return 'closeStatus'
      case 'High':
        return 'riskHighStatus'
      case 'Medium':
        return 'riskMediumStatus'
      case 'Low':
        return 'riskLowStatus'
      default:
        return ''
    }
  }
  return (
    <React.Fragment>
      {params?.value || params?.params?.value ? ( // to handle one utility function from Grid and side bar
        <WppPill
          className={statusColorIdentifier(params.value || params?.params?.value)}
          label={params?.value || params?.params?.value}
          value={params?.value || params?.params?.value}
        />
      ) : null}
    </React.Fragment>
  )
}

export default StatusIndicator
