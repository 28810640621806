// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g5OJR{align-items:center;display:flex;flex-direction:column;height:100vh;gap:2rem;background:#fff;justify-content:center}.J150J{color:#000}.mVZy7{--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lightgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}`, "",{"version":3,"sources":["webpack://./src/components/serviceDown/ServiceDown.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA,CACA,sBAAA,CAGF,OACE,UAAA,CAGF,OACE,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,iDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA","sourcesContent":[".serviceContainer {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  gap: 2rem;\n  background: white;\n  justify-content: center;\n}\n\n.text {\n  color: black;\n}\n\n.customBtnSecondary {\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lightgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceContainer": `g5OJR`,
	"text": `J150J`,
	"customBtnSecondary": `mVZy7`
};
export default ___CSS_LOADER_EXPORT___;
