import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Grid, Container } from '@mui/material'
import { WppBanner, WppProgressIndicator } from '@wppopen/components-library-react'
import { Chart, registerables } from 'chart.js'
import { useSelector } from 'react-redux'

import { buildVendorChart } from './charts'
import styles from './TPRMDashboard.module.scss'
import DashboardCards from '../../../components/dashboardCards/DashboardCards'
import { DoughnutChart } from '../../../components/doughnutChart/DoughnutChart'
import { convertToTitleCase } from '../../../helper/Helper'
import { StatusObject } from '../../../helper/interface'
import serviceURL from '../../../helper/serviceURL'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'

export const TPRMDashboard = () => {
  Chart.register(...registerables)
  const canvasRefVendor = useRef(null)
  const selectedSideOrg = useSelector((state: any) => state.selectedSideNavOrgRed.data)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [total, setTotal] = useState(0)
  const [active, setActive] = useState(0)
  const [archived, setArchived] = useState(0)
  const [pending, setPending] = useState(0)
  const [rejected, setRejected] = useState(0)
  const { axiosInstance } = useAxiosInterceptors()

  const fetchData = useCallback(() => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/report/tprm'
    setIsLoading(true)
    axiosInstance
      .get(apiUrl)
      .then(res => {
        setIsLoading(false)
        setIsToShowBanner(false)
        createVendorStatusChart(res.data)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }, [axiosInstance])

  useEffect(() => {
    fetchData()
  }, [selectedSideOrg, fetchData])

  const createVendorStatusChart = (response: StatusObject) => {
    const statusLabels: string[] = []
    const statusCount: number[] = []
    let total = 0
    let totalActive = 0
    let totalArchived = 0
    let totalPending = 0
    let totalRejected = 0
    if (response?.invByStatus) {
      for (const [key, values] of Object.entries(response.invByStatus)) {
        if (key === 'vendor') {
          for (const [key, value] of Object.entries<number>(values)) {
            statusLabels.push(convertToTitleCase(key))
            statusCount.push(value)
            total += value
            switch (key) {
              case 'active':
                totalActive += value
                break
              case 'archived':
                totalArchived += value
                break
              case 'pending':
                totalPending += value
                break
              case 'rejected':
                totalRejected += value
                break
            }
          }
        }
      }
      setActive(totalActive)
      setArchived(totalArchived)
      setPending(totalPending)
      setRejected(totalRejected)
    }
    setTotal(total)
    buildVendorChart(canvasRefVendor, statusLabels, statusCount)
  }

  return (
    <Container maxWidth="xl">
      {isLoading && <WppProgressIndicator variant="bar" className={styles.customLoader} />}
      <WppBanner id="banner" type="information" show={isToShowBanner}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
      <Grid container spacing={3} marginTop="5rem" justifyContent="center">
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards title="Active" count={active} color="info" icon="ant-design:apple-filled" bgcolor="white" />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Archived"
            count={archived}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards title="Pending" count={pending} color="info" icon="ant-design:apple-filled" bgcolor="white" />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Rejected"
            count={rejected}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Total Vendors"
            count={total}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <div className={styles.sectionDoughnut}>
            <DoughnutChart title="Vendor Inventory Status" chartRef={canvasRefVendor} />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TPRMDashboard
