
import { MicroAppCustomProps } from '@wppopen/core'
import { OsProvider } from '@wppopen/react'
import { Provider } from 'react-redux';

import { App } from 'app/App'

import store from '../redux/store';

export const Root = (props: MicroAppCustomProps) => (
      <Provider store={store}>
        <OsProvider {...props}>
          <App />
        </OsProvider>
      </Provider>
)
