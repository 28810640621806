import { useState, useEffect, ChangeEvent, useRef } from 'react'

import { Grid, Container } from '@mui/material'
import { TabsChangeEventDetail } from '@wppopen/components-library'
import {
  WppButton,
  WppSideModal,
  WppToast,
  WppProgressIndicator,
  WppBanner,
  WppActionButton,
  WppIconEdit,
  WppIconDone,
  WppModal,
  WppIconClose,
  WppSpinner,
  WppIconError,
  WppTab,
  WppTabs,
  WppSelect,
  WppListItem
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import DataGridOfflineComp from 'components/dataGrid/DataGridOfflineComp'
import FileUpload from 'components/fileUpload/FileUpload'
import Input from 'components/input/Input'
import TextArea from 'components/textArea/TextArea'
import CreateAssessment from 'containers/piaModule/assessmentList/createAssessment/CreateAssessment'
import RowDetails from 'containers/piaModule/assessmentList/rowDetails/RowDetails'

import CardDetails from './CardDetails'
import styles from './VendorDetails.module.scss'
import DataGrid from '../../../../components/dataGrid/DataGrid'
import VendorPdf from '../../../../components/pdf/vendorPdf'
import { USER_ROLE, VENDOR_GRID_HEADER_DETAILS, INVENTORY } from '../../../../helper/constants'
import serviceURL from '../../../../helper/serviceURL'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import { launchAssessDispatcher } from '../../../dmModule/processingActivityList/rowDetails/action'
import { selectedTypeDispatcher } from '../../../piaModule/assessmentList/createAssessment/step1/action'
import { fetchInitialData, setTriggerReload } from '../../../tprmModule/vendorsList/rowDetails/action'

const mapDispatchToProps = (dispatch: any) => ({
  selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  dispatchInitialData: (type: string, id: string, head: object) => dispatch(fetchInitialData(type, id, head)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})

const mapStateToProps = (state: any) => {
  return {
    userRoles: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context,
    vendorDetailsProps: state.vendorDetails,
    isTriggerReload: state?.triggerReload?.data
  }
}

const VendorDetails = ({
  open,
  onClose,
  clickedRowData,
  typeOfParentInventory,
  launchAssessDispatcher,
  selectedTypeDispatcher,
  userRoles,
  userContext,
  dispatchInitialData,
  vendorDetailsProps,
  isTriggerReload,
  setTriggerReloadDispatcher
}: any) => {
  const [vendorName, setVendorName] = useState(clickedRowData?.name)
  const [isEditEnable, setIsEditEnable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingVendorName, setIsLoadingVendorName] = useState(false)
  const [warningVendorNameExist, setWarningVendorNameExist] = useState(false)
  const [rowDetailsClicked, setRowDetailsClicked] = useState<any>({})
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showVendorNameToast, setShowVendorNameToast] = useState(false)
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [showDeleteErrorToast, setShowDeleteErrorToast] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false)
  const [clickedAssessmentInfo, setClickedAssessmentInfo] = useState({ id: '', name: '', legacy: false })
  const [currentTab, setCurrentTab] = useState('assessments')
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showUploadSuccessToast, setShowUploadSuccessToast] = useState(false)
  const [description, setDescription] = useState(vendorDetailsProps?.vendor?.description)
  const [isDescriptionEditEnable, setIsDescriptionEditEnable] = useState(false)
  const [showDescriptionSuccessToast, setShowDescriptionSuccessToast] = useState(false)
  const [industry, setIndustry] = useState(vendorDetailsProps?.vendor?.industry)
  const [isIndustryEditEnable, setIsIndustryEditEnable] = useState(false)
  const [showIndustrySuccessToast, setShowIndustrySuccessToast] = useState(false)
  const [isCountryEditEnable, setIsCountryEditEnable] = useState(false)
  const [country, setCountry] = useState(vendorDetailsProps?.vendor?.country)
  const [showLocationSuccessToast, setShowLocationSuccessToast] = useState(false)
  const countryRef = useRef<{id: number, name: string}[]>([])
  const industryRef = useRef<{id: number, name: string}[]>([])

  const isAllowedToEditName = true
  const {
    osApi: { getAccessToken }
  } = useOs()
  const { axiosInstance } = useAxiosInterceptors()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }

  const delayTime = 500

  useEffect(() => {
    fetchVendorById()
  }, [isTriggerReload])

  useEffect(() =>{
      getIndustry()
      getCountry()
  }, [])

  useEffect(() => {
    setDescription(vendorDetailsProps?.vendor?.description)
    setIndustry(vendorDetailsProps?.vendor?.industry)
    setCountry(vendorDetailsProps?.vendor?.country)
  }, [vendorDetailsProps?.vendor?.description, vendorDetailsProps?.vendor?.industry, vendorDetailsProps?.vendor?.country])

  const fetchVendorById = () => {
    setIsLoading(true)
    if (clickedRowData) {
      dispatchInitialData?.(typeOfParentInventory.name, clickedRowData.id, headers, clickedRowData.organizationId)
        .then((res: any) => {
          if (res) {
            setRowDetailsClicked(clickedRowData)
            setIsLoading(false)
          }
        })
        .catch((err: any) => {
          console.log(err)
          setIsToShowBanner(true)
          setIsLoading(false)
        })
    }
  }

  const handleLaunchAssessment = () => {
    selectedTypeDispatcher?.(typeOfParentInventory)
    launchAssessDispatcher?.({ openAssess: true, type: typeOfParentInventory })
    setIsModalOpen(true)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setWarningVendorNameExist(false)
      if (vendorName !== clickedRowData?.name) {
        checkVendorExists()
      }
    }, delayTime)
    return () => clearTimeout(delayDebounceFn)
  }, [vendorName])

  const checkVendorExists = () => {
    const apiUrl =
      serviceURL.pgpBaseAPI +
      '/api/inventory/' +
      INVENTORY.TYPE_VE +
      '/exists?name=' +
      encodeURIComponent(vendorName?.trim())
    setIsLoadingVendorName(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(response => {
        setIsLoadingVendorName(false)
        setWarningVendorNameExist(response?.data)
      })
      .catch(error => {
        setIsLoadingVendorName(false)
        setWarningVendorNameExist(false)
        console.log(error)
      })
  }

  const updateVendorName = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + INVENTORY.TYPE_VE + '/' + clickedRowData?.id
    const payload = {
      name: vendorName?.trim()
    }
    setShowSpinner(true)
    axiosInstance
      .put(apiUrl, payload, {
        headers: headers
      })
      .then(() => {
        setShowSpinner(false)
        setIsEditEnable(false)
        setTriggerReloadDispatcher(true)
        setShowVendorNameToast(true)
      })
      .catch(error => {
        setShowSpinner(false)
        setIsEditEnable(false)
        setVendorName(clickedRowData?.name)
        setShowVendorNameToast(false)
        console.log(error)
      })
      .finally(() => {
        setTriggerReloadDispatcher(false)
      })
  }

  const updateVendorDescription = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/VENDOR/' + clickedRowData?.id
    setIsLoading(true)
    const payload = {
      description: description?.trim()
    }
    axiosInstance
      .put(apiUrl, payload, {
        headers: headers
      })
      .then(() => {
        setIsDescriptionEditEnable(false)
        setTriggerReloadDispatcher(true)
        setShowDescriptionSuccessToast(true)
        setIsLoading(false)
      })
      .catch(error => {
        setIsDescriptionEditEnable(false)
        setDescription(vendorDetailsProps?.vendor?.description)
        setShowDescriptionSuccessToast(false)
        setIsLoading(false)
        console.log(error)
      })
      .finally(() => {
        setTriggerReloadDispatcher(false)
        setIsLoading(false)
      })
  }

  const updateIndustry = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/VENDOR/' + clickedRowData?.id
      setIsLoading(true)
      const payload = {
        industryId: industry?.id
      }
      axiosInstance
        .put(apiUrl, payload, {
          headers: headers
        })
        .then(() => {
          setTriggerReloadDispatcher(true)
          setIsIndustryEditEnable(false)
          setShowIndustrySuccessToast(true)
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsIndustryEditEnable(false)
          setIndustry(vendorDetailsProps?.vendor?.industry)
          setShowIndustrySuccessToast(true)
          setIsLoading(false)
        })
        .finally(() => {
          setTriggerReloadDispatcher(false)
          setIsLoading(false)
        })
  }

  const updateCountry = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/VENDOR/' + clickedRowData?.id
      setIsLoading(true)
      const payload = {
        countryId: country?.id
      }
      axiosInstance
        .put(apiUrl, payload, {
          headers: headers
        })
        .then(() => {
          setTriggerReloadDispatcher(true)
          setIsCountryEditEnable(false)
          setIsLoading(false)
          setShowLocationSuccessToast(true)
        })
        .catch(error => {
          console.log(error)
          setIsCountryEditEnable(false)
          setCountry(vendorDetailsProps?.vendor?.industry)
          setIsLoading(false)
          setShowLocationSuccessToast(false)
        })
        .finally(() => {
          setTriggerReloadDispatcher(false)
          setIsLoading(false)
        })
  }

  const updateClosedState = () => {
    setIsEditEnable(false)
    setTriggerReloadDispatcher(false)
    setVendorName(clickedRowData?.name)
  }

  const handleVendorNameUpdate = () => {
    setIsEditEnable(false)
    updateVendorName()
  }

  const handleDelete = () => {
    setIsLoading(true)
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + rowDetailsClicked?.id + '/delete'
    const payload = {
      remarks: deleteMessage || ''
    }
    axiosInstance
      .put(apiUrl, payload, {
        headers: headers
      })
      .then(() => {
        setIsLoading(false)
        setShowDeleteToast(true)
        setShowDeleteModal(false)
        setTriggerReloadDispatcher(true)
        setTimeout(() => {
          onClose()
        }, 1000)
      })
      .catch(error => {
        setIsLoading(false)
        setShowDeleteToast(false)
        setShowDeleteErrorToast(true)
        console.log(error)
      })
  }

  const isEligibleForLaunch = (permission: string[]) => {
    return userRoles?.groups?.some((item: any) => permission.includes(item.name))
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDeleteMessage(event.target.value)
  }

  const closeSideModal = () => {
    launchAssessDispatcher?.({})
    setIsModalOpen(false)
    onClose()
  }

  const onRowClicked = (assessment: any) => {
    setTriggerReloadDispatcher(false)
    setClickedAssessmentInfo(assessment)
    setIsAssessmentModalOpen(true)
  }

  const handleModalClose = () => {
    setIsAssessmentModalOpen(false)
  }

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  const hasRequiredPermission = (permission: string[]) => {
    const hasRequiredRole = userRoles?.groups?.some((item: any) => permission.includes(item.name))
    const isSelfCreated = clickedRowData?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim()
    return hasRequiredRole || isSelfCreated
  }

  const isEligibleForImportAssessment = () => {
    return userRoles?.groups.some((group: any) => group?.name === USER_ROLE.SAD)
  }

  const handleDescriptionModalClose = () => {
    setDescription(vendorDetailsProps?.vendor?.description)
    setIsDescriptionEditEnable(false)
  }

  const handleIndustryModalClose = () => {
    setIndustry(vendorDetailsProps?.vendor?.industry)
    setIsIndustryEditEnable(false)
  }

  const handleCountryModalClose = () => {
    setCountry(vendorDetailsProps?.vendor?.country)
    setIsCountryEditEnable(false)
  }

  const getIndustry = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/industries'
      axiosInstance
        .get(apiUrl, {
          headers: headers
        })
        .then((res) => {
          industryRef.current = res?.data
        })
        .catch(error => {
          console.log(error)
        })
  }

  const getCountry = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/countries'
      axiosInstance
        .get(apiUrl, {
          headers: headers
        })
        .then((res) => {
          countryRef.current = res?.data
        })
        .catch(error => {
          console.log(error)
        })
  }

  return (
    <>
      <WppSideModal open={open} size="2xl" onWppSideModalClose={onClose} className={styles.modalBox}>
        {isLoading ? (
          <div slot="body">
            <DataGridOfflineComp />
          </div>
        ) : (
          <>
            <div slot="header" className={styles.header}>
              <div className={styles.editName}>
                {!isEditEnable && clickedRowData && (
                  <h3 className={styles.vendName} data-testid="vendor-detail-vendor-name">
                    {vendorName}
                  </h3>
                )}
                {hasRequiredPermission([USER_ROLE.SAD, USER_ROLE.DPM]) && isAllowedToEditName && !isEditEnable && (
                  <span className={styles.icon}>
                    <WppIconEdit
                      data-testid="vendor-detail-edit-name"
                      className={styles.btn}
                      size="s"
                      onClick={() => {
                        setIsEditEnable(true)
                      }}
                    />
                  </span>
                )}
              </div>
              {isEditEnable && (
                <div className={styles.editNameEnabled}>
                  <div className={styles.vendorNameWarningSec}>
                    <Input
                      label=""
                      maxLength={70}
                      value={vendorName}
                      onChange={(event: any) => setVendorName(event.target.value)}
                    />
                    <div className={styles.errorMessage}>
                      {isLoadingVendorName && <WppSpinner size="s" color="black" />}
                      {warningVendorNameExist && (
                        <p className={styles.warningNameExist}>
                          <WppIconError data-testid="error-icon" />{' '}
                          <span data-testid="vendor-detail-name-error">Vendor name already exists.</span>
                        </p>
                      )}
                    </div>
                  </div>
                  {isEditEnable && (
                    <span className={styles.icon}>
                      {showSpinner && <WppSpinner size="s" color="black" />}
                      {!showSpinner && !warningVendorNameExist && (
                        <WppIconDone
                          className={styles.btn}
                          size="s"
                          onClick={handleVendorNameUpdate}
                          data-testid="vendor-detail-done"
                        />
                      )}
                      {!showSpinner && <WppIconClose className={styles.btn} size="s" onClick={updateClosedState} />}
                    </span>
                  )}
                </div>
              )}
              <div className={styles.buttonAction}>
                {isEligibleForImportAssessment() && (
                  <WppButton
                    className={styles.customBtnSecondary}
                    variant="secondary"
                    onClick={() => setShowUploadModal(true)}
                  >
                    Import Assessment
                  </WppButton>
                )}
                <VendorPdf
                  data={vendorDetailsProps?.vendor}
                  isLoading={isLoading}
                  apiEndpoint={
                    serviceURL.pgpBaseAPI + '/api/inventory/VENDOR/' + vendorDetailsProps?.vendor?.id + '/export-pdf'
                  }
                />
                <WppActionButton onClick={onClose} variant="secondary" slot="actions" data-testid="vendor-detail-close">
                  <WppIconClose className={styles.close} />
                </WppActionButton>
              </div>
              {showVendorNameToast && (
                <WppToast
                  className={styles.toast}
                  message="Vendor name updated successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowVendorNameToast(false)}
                />
              )}
              {showDeleteToast && (
                <WppToast
                  className={styles.toast}
                  message="Vendor deleted successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowDeleteToast(false)}
                  data-testid="vendor-delete-toast"
                />
              )}
              {showDeleteErrorToast && (
                <WppToast
                  className={styles.toast}
                  message="Vendor delete has errors"
                  type="error"
                  duration={2000}
                  onWppToastComplete={() => setShowDeleteErrorToast(false)}
                />
              )}
              {showUploadSuccessToast && (
                <WppToast
                  className={styles.toast}
                  message="Assessment imported successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowUploadSuccessToast(false)}
                />
              )}
              {showDescriptionSuccessToast && (
                <WppToast
                  className={styles.toast}
                  message="Description updated successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowDescriptionSuccessToast(false)}
                />
              )}
              {showIndustrySuccessToast && (
                <WppToast
                  className={styles.toast}
                  message="Industry updated successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowIndustrySuccessToast(false)}
                />
              )}
              {showLocationSuccessToast && (
                <WppToast
                  className={styles.toast}
                  message="Location updated successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowLocationSuccessToast(false)}
                />
              )}
            </div>
            <div slot="body" className={styles.sideModalBody}>
              <div className={styles.banner}>
                <WppBanner id="banner" type="information" show={isToShowBanner}>
                  Unable to fetch inventory. Please refresh or try after some time.
                </WppBanner>
              </div>
              {showToast && (
                <WppToast
                  className={styles.toast}
                  message="Inventory linked successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowToast(false)}
                />
              )}
              <Container maxWidth="xl">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                  {isLoading ? (
                    <WppProgressIndicator className={styles.customLoader} variant="bar" />
                  ) : (
                    <CardDetails
                      details={vendorDetailsProps?.vendor || {}}
                      hasPermission={hasRequiredPermission}
                      setIsDescriptionEditEnable={setIsDescriptionEditEnable}
                      setIsIndustryEditEnable={setIsIndustryEditEnable}
                      setIsCountryEditEnable={setIsCountryEditEnable}
                    />
                  )}

                  <Grid item xs={12} sm={12} md={12} paddingTop="2rem !important" className={styles.dataGridSection}>
                    <div className={styles.dataGrid}>
                      {isLoading ? (
                        <WppProgressIndicator className={styles.customLoader} variant="bar" />
                      ) : (
                        <>
                          <WppTabs className={styles.customTabs} value={currentTab} onWppChange={handleTabChange}>
                            <WppTab className={styles.customTab} value="assessments">
                              Assessments
                            </WppTab>
                            <WppTab className={styles.customTab} value="dataSubjectLocations">
                              Data Subject Locations
                            </WppTab>
                          </WppTabs>
                          {
                            {
                              assessments: (
                                <div className={styles.tabBox}>
                                  {vendorDetailsProps?.vendor?.id && (
                                    <DataGrid
                                      apiEndpoint={
                                        serviceURL.pgpBaseAPI +
                                        '/api/inventory/' +
                                        vendorDetailsProps?.vendor?.id +
                                        '/assessments'
                                      }
                                      onRowClickedHandler={onRowClicked}
                                      headerDetails={VENDOR_GRID_HEADER_DETAILS}
                                      isTriggerReload={isTriggerReload}
                                    />
                                  )}
                                </div>
                              ),
                              dataSubjectLocations: (
                                <div className={styles.tabBox}>
                                  <ul className={styles?.dataLocation}>
                                    {vendorDetailsProps?.vendor?.dataSubjectLocations?.map((item: string) => (
                                      <li key={item}>{item}</li>
                                    ))}
                                  </ul>
                                </div>
                              )
                            }[currentTab]
                          }
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <div slot="actions" className={styles.actionBtn}>
              <WppButton
                disabled={isLoading || !isEligibleForLaunch([USER_ROLE.DPM, USER_ROLE.SU])}
                className={styles.customBtnSecondary}
                variant="secondary"
                onClick={handleLaunchAssessment}
              >
                Launch Assessment
              </WppButton>
              {hasRequiredPermission([USER_ROLE.SAD, USER_ROLE.DPM]) && (
                <WppButton
                  className={styles.customBtnSecondary}
                  variant="secondary"
                  onClick={() => {
                    setShowDeleteModal(true)
                    setTriggerReloadDispatcher(false)
                  }}
                >
                  Delete Vendor Record
                </WppButton>
              )}
            </div>
          </>
        )}
      </WppSideModal>
      {showDeleteModal && (
        <WppModal open={showDeleteModal} size="s" onWppModalClose={onClose}>
          <div slot="header" className={styles.deleteHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span>Delete Vendor Record</span>
            </h3>
            <WppIconClose color="black" onClick={() => setShowDeleteModal(false)} className={styles.close} size="m" />
          </div>
          <div slot="body" className={styles.relaunchModalBody}>
            <span>
              All associated records with it will also be delete from system. If the vendor has already provided
              assessment responses, these will be lost.
            </span>
            <TextArea
              name="deleteMessage"
              placeholder="Reason for deletion..."
              className={styles.reviewContainerCommentItem}
              value={deleteMessage}
              onChange={e => handleChange(e)}
            />
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={handleDelete}
                disabled={!deleteMessage.trim().length}
                data-testid="vendor-detail-delete-vendor"
              >
                DELETE
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
      {showUploadModal && (
        <FileUpload
          open={showUploadModal}
          onClose={() => setShowUploadModal(false)}
          uploadTost={setShowUploadSuccessToast}
        />
      )}
      {isModalOpen && (
        <CreateAssessment
          open={isModalOpen}
          closeMainModal={() => setIsModalOpen(false)}
          closeSideModal={closeSideModal}
        />
      )}
      {isAssessmentModalOpen && (
        <RowDetails
          open={isAssessmentModalOpen}
          clickedRowData={clickedAssessmentInfo}
          closeSideModal={handleModalClose}
          onClose={handleModalClose}
        />
      )}
      {isDescriptionEditEnable && (
        <WppModal open={isDescriptionEditEnable} size="s" onWppModalClose={handleDescriptionModalClose}>
          <div slot="header" className={styles.deleteHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span data-testid="vendor-description-edit-heading">Update Vendor Description</span>
            </h3>
            <WppIconClose
              color="black"
              onClick={handleDescriptionModalClose}
              className={styles.close}
              size="m"
            />
          </div>
          <div slot="body" className={styles.relaunchModalBody}>
            <TextArea
              name="descriptionMessage"
              placeholder="Enter description"
              className={styles.reviewContainerCommentItem}
              value={description}
              onChange={event => setDescription(event?.target?.value)}
              data-testid="input-update-description"
            />
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={updateVendorDescription}
                data-testid="vendor-detail-update-description"
              >
                Update
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleDescriptionModalClose}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
      {isIndustryEditEnable && (
        <WppModal open={isIndustryEditEnable} size="s" onWppModalClose={handleIndustryModalClose}>
          <div slot="header" className={styles.deleteHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span data-testid="vendor-industry-edit-heading">Update Industry</span>
            </h3>
            <WppIconClose
              color="black"
              onClick={handleIndustryModalClose}
              className={styles.close}
              size="m"
            />
          </div>
          <div slot="body" className={styles.relaunchModalBody}>
            <WppSelect
              id="industry"
              onWppChange={event => setIndustry(event?.target?.value)}
              placeholder="Select Industry"
              value={industry}
              withSearch={true}
              size="s"
              data-testid="industry"
            >
              {industryRef?.current?.length ? (
                industryRef?.current?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item?.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={updateIndustry}
                data-testid="vendor-detail-update-description"
              >
                Update
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleIndustryModalClose}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
      {isCountryEditEnable && (
        <WppModal open={isCountryEditEnable} size="s" onWppModalClose={handleCountryModalClose}>
          <div slot="header" className={styles.deleteHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span data-testid="vendor-country-edit-heading">Update Location</span>
            </h3>
            <WppIconClose
              color="black"
              onClick={handleCountryModalClose}
              className={styles.close}
              size="m"
            />
          </div>
          <div slot="body" className={styles.relaunchModalBody}>
            <WppSelect
              id="country"
              onWppChange={event => setCountry(event?.target?.value)}
              placeholder="Select Country"
              value={country}
              withSearch={true}
              size="s"
              data-testid="country"
            >
              {countryRef?.current?.length ? (
                countryRef?.current?.map((item: { name: string }, idx: number) => (
                  <WppListItem key={idx} value={item}>
                    <p slot="label">{item?.name}</p>
                  </WppListItem>
                ))
              ) : (
                <WppListItem>
                  <p slot="label">No Data</p>
                </WppListItem>
              )}
            </WppSelect>
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={updateCountry}
                data-testid="vendor-detail-update-description"
              >
                Update
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleCountryModalClose}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails)
