import {
    finishedByDMPDispatcher,
    completedByTypeDispatcher,
    countryTypeDispatcher,
    dsarByTypeDispatcher,
    errorByTypeDispatcher,
    progressDMPByTypeDispatcher,
    portalTypeDispatcher,
    progressDSByTypeDispatcher,
    responseStatsDispatcher,
    stateTypeDispatcher,
    cancelledByTypeDispatcher,
    errorByDMPDispatcher,
    progressByDMPDispatcher,
} from './action';
export const mapStateToProps = (state: any) => {
    return {
        dsarStats: state.responseStatsReducer.data,
        dsarPortalType: state.portalTypeReducer.data,
        dsarCountryType: state.countryTypeReducer.data,
        dsarStateType: state.stateTypeReducer.data,
        dsarByType: state.dsarByTypeReducer.data,
        dsarFinishedByType: state.finishedByTypeReducer.data,
        dsarProgressDSByType: state.progressDSByTypeReducer.data,
        dsarProgressDMPByType: state.progressDMPByTypeReducer.data,
        dsarErrorByType: state.errorByTypeReducer.data,
        dsarDeniedByType: state.cancelledByTypeReducer.data,
        dsarFinishedByDMP: state.finishedByDMPReducer.data,
        dsarProgressByDMP: state.progressByDMPReducer.data,
        dsarErrorByDMP: state.errorByDMPReducer.data,
    };
};

export const mapDispatchToProps = (dispatch: any) => ({
    dsarByTypeDispatcher: (dsarNames: string[], dsarCount: string[], bgColors: string[]) =>
        dispatch(dsarByTypeDispatcher(dsarNames, dsarCount, bgColors)),
    responseStatsDispatcher: (data: any) => dispatch(responseStatsDispatcher(data)),
    portalTypeDispatcher: (portalTypeNames: string[], portalTypeCounts: string[], bgColors: string[]) =>
        dispatch(portalTypeDispatcher(portalTypeNames, portalTypeCounts, bgColors)),
    countryTypeDispatcher: (modifiedCountryList: any) =>
        dispatch(countryTypeDispatcher(modifiedCountryList)),
    stateTypeDispatcher: (modifiedStateList: any) =>
        dispatch(stateTypeDispatcher(modifiedStateList)),
    completedByTypeDispatcher: (completedDsarNames: string[], completedDsarCount: string[], bgColors: string[]) =>
        dispatch(completedByTypeDispatcher(completedDsarNames, completedDsarCount, bgColors)),
    progressDSByTypeDispatcher: (progressDsarNames: string[], progressDsarCount: string[], bgColors: string[]) =>
        dispatch(progressDSByTypeDispatcher(progressDsarNames, progressDsarCount, bgColors)),
    progressDMPByTypeDispatcher: (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) =>
        dispatch(progressDMPByTypeDispatcher(progressDMPNames, progressDMPCount, bgColors)),
    errorByTypeDispatcher: (errorDsarNames: string[], errorDsarCount: string[], bgColors: string[]) =>
        dispatch(errorByTypeDispatcher(errorDsarNames, errorDsarCount, bgColors)),
    cancelledByTypeDispatcher: (cancelledDsarNames: string[], cancelledDsarCount: string[], bgColors: string[]) =>
        dispatch(cancelledByTypeDispatcher(cancelledDsarNames, cancelledDsarCount, bgColors)),
    finishedByDMPDispatcher: (finishedDMPNames: string[], finishedDMPCount: string[], bgColors: string[]) =>
        dispatch(finishedByDMPDispatcher(finishedDMPNames, finishedDMPCount, bgColors)),
    progressByDMPDispatcher: (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) =>
        dispatch(progressByDMPDispatcher(progressDMPNames, progressDMPCount, bgColors)),
    errorByDMPDispatcher: (errorDMPNames: string[], errorDMPCount: string[], bgColors: string[]) =>
        dispatch(errorByDMPDispatcher(errorDMPNames, errorDMPCount, bgColors)),
});

