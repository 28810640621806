import {
    FINISHED_BY_DMP,
    COMPLETED_TYPE,
    COUNTRY_TYPE,
    CANCELLED_TYPE,
    DSAR_BY_TYPE,
    ERROR_BY_DMP,
    ERROR_TYPE,
    PROGRESS_DMP_TYPE,
    PORTAL_TYPE,
    PROGRESS_DS_TYPE,
    RESPONSE_STATS,
    STATE_TYPE,
    PROGRESS_BY_DMP,
} from './types';

export const responseStats = (response: any) => ({
    type: RESPONSE_STATS,
    payload: {
        response,
    },
});

export const responseStatsDispatcher = (response: any) => (dispatch: any) => {
    dispatch(responseStats(response));
};

export const portalType = (portalTypeNames: string[], portalTypeCounts: string[], bgColors: string[]) => ({
    type: PORTAL_TYPE,
    payload: {
        portalTypeNames,
        portalTypeCounts,
        bgColors,
    },
});

export const portalTypeDispatcher = (portalTypeNames: string[], portalTypeCounts: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(portalType(portalTypeNames, portalTypeCounts, bgColors));
};

export const countryType = (modifiedCountryList: any) => ({
    type: COUNTRY_TYPE,
    payload: {
        modifiedCountryList
    },
});

export const countryTypeDispatcher = (modifiedCountryList: any) => (dispatch: any) => {
    dispatch(countryType(modifiedCountryList));
};

export const stateType = (modifiedStateList: any) => ({
    type: STATE_TYPE,
    payload: {
        modifiedStateList
    },
});

export const stateTypeDispatcher = (modifiedStateList: any) => (dispatch: any) => {
    dispatch(stateType(modifiedStateList));
};

export const dsarByType = (dsarNames: string[], dsarCount: string[], bgColors: string[]) => ({
    type: DSAR_BY_TYPE,
    payload: {
        dsarNames,
        dsarCount,
        bgColors,
    },
});

export const dsarByTypeDispatcher = (dsarNames: string[], dsarCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(dsarByType(dsarNames, dsarCount, bgColors));
};

export const completedByType = (completedDsarNames: string[], completedDsarCount: string[], bgColors: string[]) => ({
    type: COMPLETED_TYPE,
    payload: {
        completedDsarNames,
        completedDsarCount,
        bgColors,
    },
});

export const completedByTypeDispatcher = (completedDsarNames: string[], completedDsarCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(completedByType(completedDsarNames, completedDsarCount, bgColors));
};

export const progressDSByType = (progressDsarNames: string[], progressDsarCount: string[], bgColors: string[]) => ({
    type: PROGRESS_DS_TYPE,
    payload: {
        progressDsarNames,
        progressDsarCount,
        bgColors,
    },
});

export const progressDSByTypeDispatcher = (progressDsarNames: string[], progressDsarCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(progressDSByType(progressDsarNames, progressDsarCount, bgColors));
};

export const progressDMPByType = (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) => ({
    type: PROGRESS_DMP_TYPE,
    payload: {
        progressDMPNames,
        progressDMPCount,
        bgColors,
    },
});

export const progressDMPByTypeDispatcher = (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(progressDMPByType(progressDMPNames, progressDMPCount, bgColors));
};

export const errorByType = (errorDsarNames: string[], errorDsarCount: string[], bgColors: string[]) => ({
    type: ERROR_TYPE,
    payload: {
        errorDsarNames,
        errorDsarCount,
        bgColors,
    },
});

export const errorByTypeDispatcher = (errorDsarNames: string[], errorDsarCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(errorByType(errorDsarNames, errorDsarCount, bgColors));
};

export const cancelledByType = (cancelledDsarNames: string[], cancelledDsarCount: string[], bgColors: string[]) => ({
    type: CANCELLED_TYPE,
    payload: {
        cancelledDsarNames,
        cancelledDsarCount,
        bgColors,
    },
});

export const cancelledByTypeDispatcher = (cancelledDsarNames: string[], cancelledDsarCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(cancelledByType(cancelledDsarNames, cancelledDsarCount, bgColors));
};

export const completedByDMP = (finishedDMPNames: string[], finishedDMPCount: string[], bgColors: string[]) => ({
    type: FINISHED_BY_DMP,
    payload: {
        finishedDMPNames,
        finishedDMPCount,
        bgColors,
    },
});

export const finishedByDMPDispatcher = (finishedDMPNames: string[], finishedDMPCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(completedByDMP(finishedDMPNames, finishedDMPCount, bgColors));
};

export const progressByDMP = (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) => ({
    type: PROGRESS_BY_DMP,
    payload: {
        progressDMPNames,
        progressDMPCount,
        bgColors,
    },
});

export const progressByDMPDispatcher = (progressDMPNames: string[], progressDMPCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(progressByDMP(progressDMPNames, progressDMPCount, bgColors));
};

export const errorByDMP = (errorDMPNames: string[], errorDMPCount: string[], bgColors: string[]) => ({
    type: ERROR_BY_DMP,
    payload: {
        errorDMPNames,
        errorDMPCount,
        bgColors,
    },
});

export const errorByDMPDispatcher = (errorDMPNames: string[], errorDMPCount: string[], bgColors: string[]) => (dispatch: any) => {
    dispatch(errorByDMP(errorDMPNames, errorDMPCount, bgColors));
};
