import {BASE_64_URL, GET_ORG_REQUEST, GET_ORG_SUCCESS, GET_ORG_FAILURE, GET_ACCESS_TOKEN, SET_USER_OS_CONTEXT} from './types';

const initialState = {
    data: '',
};
export function storeBase64EmailReducer(state = initialState, action: any) {
    if (action.type === BASE_64_URL) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

const initialUserState = {
    data: null,
    context: null,
    loading: false,
    error: null,
};


export function getOrgByUserRed(state = initialUserState, action: any) {
    switch (action.type) {
        case GET_ORG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ORG_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_ORG_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SET_USER_OS_CONTEXT:
            return {
                ...state,
                context : action.payload
            };
        default:
            return state;
    }
}

export function storeAccessTokenReducer(state = {data: ''}, action: any) {
    if (action.type === GET_ACCESS_TOKEN) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}