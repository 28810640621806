import React, { memo, useEffect, useState } from 'react'

import { WppTextareaInput } from '@wppopen/components-library-react'

import styles from './QueAnsAssessment.module.scss'

interface TextProps {
  response: string
}

const Text = memo((props: TextProps) => {
  const [textInvVal, setTextInvVal] = useState('')

  const initialiseTextInv = (response: string) => {
    if (response.length) {
      setTextInvVal(response)
    }
  }

  useEffect(() => {
    initialiseTextInv(props?.response)
  }, [props?.response])

  return (
    <WppTextareaInput
      aria-readonly={true}
      disabled
      className={styles.disableTextArea}
      name="text-response"
      value={textInvVal}
    />
  )
})

export default Text
