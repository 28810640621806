import { ChangeEvent, useState } from 'react'

import {
  WppBanner,
  WppButton,
  WppListItem,
  WppSelect,
  WppTag,
} from '@wppopen/components-library-react'
import { useDispatch } from 'react-redux'

import TextArea from 'components/textArea/TextArea'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'
import { SELECT_ASSESSMENT_REVIEW_OUTCOME } from 'helper/constants'
import serviceURL from 'helper/serviceURL'
import { validate } from 'helper/validate'

import styles from './QueAnsAssessment.module.scss'
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'

interface ReviewProps {
  dpmReviewComment: string,
  assessment: {
    assessmentId: string
    details: object
  }
  setDpmReviewComment: (value: string) => void
  selectReviewOutcome: {
    name: string
  }
  setSelectReviewOutcome: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  }>>
  showReviewOutcomeSaveSpinnner: boolean
  setShowReviewOutcomeSaveSpinnner: (value: boolean) => void
  showOutcomeBanner: boolean
  setShowOutcomeBanner: (value: boolean) => void
  showApproveToast: (value: boolean) => void
  closeSideModal: () => void
  riskStatusOpen: string[]
}

const isAttachmentMandatory = (configuration: string | null = null) => {
  try {
    const config = configuration ? JSON.parse(configuration) : null;
    return (
      config?.conditional?.requiredAttachment === true || !config?.conditional?.hasOwnProperty('requiredAttachment')
    )
  } catch (error) {
    console.log('error', error)
    return false
  }
}

const isAttachmentMandatoryConditional = (configuration: string | null = null, responseValue = '') => {
  try {
    const config = configuration ? JSON.parse(configuration) : null;
    return config?.conditional?.showAttachment.length && config?.conditional?.showAttachment.includes(responseValue)
  } catch (error) {
    console.log('error', error)
    return false
  }
}

const getValidSequences = (details: any) => {
  return details.flatMap((detail: { questionAnswers: any[] }) =>
    detail.questionAnswers
  // eslint-disable-next-line array-callback-return
      .filter(({ question, attachments, response }: any) => {
        const isTextOrConditional =
          question.questionType === 'TEXT' || question.questionType === 'SINGLE_SELECT_TEXT_CONDITIONAL'
        const isAttachmentAvaialble = question.attachment
        if (isTextOrConditional && isAttachmentAvaialble) {
          const isAttachmentFound = !attachments || !attachments.length
          if (question.questionType === 'TEXT') {
            return isAttachmentAvaialble && isAttachmentMandatory(question?.configuration || null) && isAttachmentFound
          } else if (question.questionType === 'SINGLE_SELECT_TEXT_CONDITIONAL') {
            const responseValue = response.responseValue
            return (
              isAttachmentAvaialble &&
              isAttachmentMandatoryConditional(question?.configuration || null, responseValue) &&
              isAttachmentFound
            )
          }
        }
      })
      .map(({ question }) => question.sequence)
  )
}

const Review = ({
  dpmReviewComment,
  assessment,
  setDpmReviewComment,
  selectReviewOutcome,
  setSelectReviewOutcome,
  showReviewOutcomeSaveSpinnner,
  setShowReviewOutcomeSaveSpinnner, 
  showOutcomeBanner,
  setShowOutcomeBanner,
  showApproveToast,
  closeSideModal,
  riskStatusOpen,
}: ReviewProps) => {
  const [hasMandatoryAttachment, setMandatoryAttachment] = useState([])
  const { axiosInstance } = useAxiosInterceptors()
  const dispatch = useDispatch()
  const saveReviewOutcomeHandler = (id: string) => {
    let validationList: any[]
    let validateFields: object
    let attachmentRequred: string[] = []
    validateFields = {
      selectReviewOutcome: selectReviewOutcome.name
    }
    if (selectReviewOutcome.name === SELECT_ASSESSMENT_REVIEW_OUTCOME[0].name) {
      const getQuestionsHasMandatoryAttachments = getValidSequences(assessment.details)
      attachmentRequred = getQuestionsHasMandatoryAttachments
      setMandatoryAttachment(getQuestionsHasMandatoryAttachments)
    }
    validationList = validate(validateFields)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found && !attachmentRequred?.length) {
      const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/assessment/' + id + '/review'
      setShowReviewOutcomeSaveSpinnner(true)
      const payload = {
        assessmentReviewOutcome: selectReviewOutcome.name,
        comments: dpmReviewComment
      }
      axiosInstance
        .put(apiUrl, payload, {
          headers: {
            accept: ' */*',
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          setShowReviewOutcomeSaveSpinnner(false)
          setShowOutcomeBanner(false)
          if (showApproveToast) {
            showApproveToast(true)
          }
          dispatch(setTriggerReload(true))
          closeSideModal?.()
        })
        .catch(() => {
          setShowReviewOutcomeSaveSpinnner(false)
          setShowOutcomeBanner(true)
        })
    }
  }

  const handleDPMReviewComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDpmReviewComment?.(e.target.value)
  }

  return (
      <div key={`dpm-container-id-${assessment.assessmentId}`} className={styles.dmActions}>
        <p>Reviewer comments</p>
        <TextArea
          className={styles.dpmReviewContainerCommentItem}
          value={dpmReviewComment}
          placeholder="Enter Comment"
          onChange={e => handleDPMReviewComment(e)}
        />
        {riskStatusOpen?.length > 0 && (
          <WppTag
            label="Please close all risks before approving the assessment!!"
            variant="warning"
            maxLabelLength={200}
            className={styles.tagWarning}
          />
        )}
        {hasMandatoryAttachment && hasMandatoryAttachment.length > 0 && (
          <p className={styles.attachmentError}>
            Please provide attachments to&nbsp;
            {hasMandatoryAttachment.map((i, index) => (
              <span key={i}>
                {i}
                {index < hasMandatoryAttachment.length - 1 && ', '}
              </span>
            ))}{' '}
            as these questions are mandatory attachment questions.
          </p>
        )}
        <div className={styles.dpmHeader} key="dpm-text-header">
          Please choose assessment review outcome from below dropdown menu.
        </div>

        <div className={styles.thirdRow} key="dpm-thirdRow">
          <div className={styles.firstColDpm} key="firstColDpm">
            <WppSelect
              key="dpm-select"
              placeholder="Select Review Outcome"
              className={styles.selectReview}
              value={selectReviewOutcome.name}
              size="s"
              onWppChange={e => setSelectReviewOutcome(e.target.value)}
            >
              {SELECT_ASSESSMENT_REVIEW_OUTCOME.slice(0, 1)
                .concat(SELECT_ASSESSMENT_REVIEW_OUTCOME.slice(2))
                .map((item) => {
                  return (
                    <WppListItem
                      key={item?.name}
                      value={item}
                      disabled={riskStatusOpen?.length > 0 ? item.name === 'APPROVED' : false}
                    >
                      <p slot="label" key="label-dpm">
                        {item.name}
                      </p>
                    </WppListItem>
                  )
                })}
            </WppSelect>
            <WppButton
              key="review-save"
              variant="secondary"
              size="s"
              className={styles.saveReviewBtn}
              loading={showReviewOutcomeSaveSpinnner}
              onClick={() => saveReviewOutcomeHandler(assessment.assessmentId)}
              disabled={!selectReviewOutcome.name}
            >
              Submit{' '}
            </WppButton>
            {showOutcomeBanner && (
              <WppBanner id="banner" type="information" show={showOutcomeBanner} className={styles.outcomeBanner}>
                Unable to save review outcome information. Please refresh or try after some time.
              </WppBanner>
            )}
          </div>
        </div>
      </div>
  )
}

export default Review
