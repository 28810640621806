import React from 'react'

import { WppTag } from '@wppopen/components-library-react'

const TagStatusVariantIndicator = (params: any) => {
  const variant = (stat: string) => {
    switch (stat.toLowerCase()) {
      case 'pending':
        return 'warning'
      case 'active':
        return 'positive'
      case 'rejected':
        return 'negative'
      case 'archived':
        return 'neutral'
      default:
        return 'neutral'
    }
  }

  return (
    <React.Fragment>
      {params?.value || params?.params?.value ? (
        <WppTag
          className={params?.className}
          variant={variant(params.value || params?.params?.value)}
          label={params?.value || params?.params?.value}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TagStatusVariantIndicator
