// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._97G1{display:flex;width:100%;margin-top:3rem;justify-content:space-evenly;overflow-y:hidden}.sVb0F{margin:2rem}.VXhnj{margin:5rem 2rem 2rem;--wpp-button-secondary-text-color: black;--wpp-button-secondary-border-color: black;--wpp-button-secondary-bg-color-hover: lightgray;--wpp-button-secondary-bg-color-active: lighgray;--wpp-button-secondary-border-color-active: black;--wpp-button-secondary-text-color-active: black;--wpp-button-secondary-border-color-disabled: darkgray;--wpp-button-secondary-text-color-disabled: darkgrey}.xHi4A{color:#000}.zQStF{--wpp-card-choosable-selected-border-color-disabled: lightgray;--wpp-card-choosable-selected-border-color-active: lightgray}`, "",{"version":3,"sources":["webpack://./src/containers/dmModule/reports/Reports.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,UAAA,CACA,eAAA,CACA,4BAAA,CACA,iBAAA,CAGF,OACE,WAAA,CAGF,OACE,qBAAA,CACA,wCAAA,CACA,0CAAA,CACA,gDAAA,CACA,gDAAA,CACA,iDAAA,CACA,+CAAA,CACA,sDAAA,CACA,oDAAA,CAGF,OACE,UAAA,CAGF,OACE,8DAAA,CACA,4DAAA","sourcesContent":[".reports {\n  display: flex;\n  width: 100%;\n  margin-top: 3rem;\n  justify-content: space-evenly;\n  overflow-y: hidden;\n}\n\n.card {\n  margin: 2rem;\n}\n\n.customBtnSecondary {\n  margin: 5rem 2rem 2rem;\n  --wpp-button-secondary-text-color: black;\n  --wpp-button-secondary-border-color: black;\n  --wpp-button-secondary-bg-color-hover: lightgray;\n  --wpp-button-secondary-bg-color-active: lighgray;\n  --wpp-button-secondary-border-color-active: black;\n  --wpp-button-secondary-text-color-active: black;\n  --wpp-button-secondary-border-color-disabled: darkgray;\n  --wpp-button-secondary-text-color-disabled: darkgrey;\n}\n\n.customIcon {\n  color: black;\n}\n\n.customCard {\n  --wpp-card-choosable-selected-border-color-disabled: lightgray;\n  --wpp-card-choosable-selected-border-color-active: lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reports": `_97G1`,
	"card": `sVb0F`,
	"customBtnSecondary": `VXhnj`,
	"customIcon": `xHi4A`,
	"customCard": `zQStF`
};
export default ___CSS_LOADER_EXPORT___;
