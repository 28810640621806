import React, { useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import {
  WppButton,
  WppIconAdd,
  WppModal,
  WppIconClose,
  WppLabel,
  WppSelect,
  WppListItem,
  WppTypography
} from '@wppopen/components-library-react'
import { useSelector, useDispatch } from 'react-redux'

import DataGrid from 'components/dataGrid/DataGrid'
import Input from 'components/input/Input'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'
import { DOMAIN_NETWORK_GRID_HEADER_DETAILS } from 'helper/constants'
import serviceURL from 'helper/serviceURL'
import useAxiosInterceptors from 'hooks/useAxiosInterceptors'
import { RootState } from 'redux/rootReducer'

import styles from './DomainNetwork.module.scss'

const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/email-domain`

function DomainNetwork() {
  const isTriggerReload = useSelector((state: RootState) => state?.triggerReload?.data)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [domainName, setDomainName] = useState({ name: '', id: 0 })
  const [networkData, setNetworkData] = useState<{ name: string; code: string }[]>([])
  const [selectedNetworkData, setSelectedNetworkData] = useState<{ name: string; id: number; code: string }>({
    name: '',
    id: 0,
    code: ''
  })
  const [error, setError] = useState('')
  const { axiosInstance } = useAxiosInterceptors()
  const dispatch = useDispatch()

  const addMapping = (isEdit: boolean) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/email-domain'
    setIsLoading(true)

    const payload = {
      name: domainName?.name,
      ...(isEdit ? { id: domainName?.id } : {}),
      organization: {
        id: selectedNetworkData?.id
      }
    }
    const method = isEdit ? 'put' : 'post'
    axiosInstance[method](apiUrl, payload)
      .then(() => {
        setIsLoading(false)
        setIsModalOpen(false)
        setEditMode(false)
        setSelectedNetworkData({ name: '', id: 0, code: '' })
        setDomainName({ name: '', id: 0 })
        dispatch(setTriggerReload(true))
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setError(error?.response?.data?.message)
      })
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setEditMode(false)
    setSelectedNetworkData({ name: '', id: 0, code: '' })
    setDomainName({ name: '', id: 0 })
    setError('')
  }

  const onRowClicked = (data: {
    name: string
    id: number
    organization: { name: string; id: number; code: string }
  }) => {
    setSelectedNetworkData(data?.organization)
    setDomainName({ name: data?.name, id: data?.id })
    setEditMode(true)
    setIsModalOpen(true)
  }

  useEffect(() => {
    fetchNetworkData()
  }, [])

  const fetchNetworkData = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations'
    axiosInstance
      .get(apiUrl)
      .then(res => {
        setNetworkData(res?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Container maxWidth="xl" className={styles.vContainer}>
      <Grid
        container
        spacing={{ xs: 2, md: 3, sm: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        className={styles.vendorContainer}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="flex-start"
          alignSelf="flex-start"
          className={styles.createVendorButton}
        >
          <WppButton
            className={styles.customBtnSecondary}
            variant="secondary"
            onClick={() => setIsModalOpen(true)}
            size="s"
            data-testid="mapping-create-button"
          >
            <WppIconAdd slot="icon-start" className={styles.customIcon} />
            Create Mapping
          </WppButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '3rem', marginBottom: '10rem' }}>
          <DataGrid
            apiEndpoint={baseApiUrl + '/search'}
            headerDetails={DOMAIN_NETWORK_GRID_HEADER_DETAILS}
            apiMethod="POST"
            pageSize={25}
            onRowClickedHandler={onRowClicked}
            isClearFilters={true}
            isTriggerReload={isTriggerReload}
          />
        </Grid>
      </Grid>
      {isModalOpen && (
        <WppModal open={isModalOpen} size="m" onWppModalClose={handleCloseModal}>
          <div slot="header" className={styles.mappingHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span data-testid="domain-heading"> {editMode ? 'Edit Mapping' : 'Add Mapping'}</span>
            </h3>
            <WppIconClose color="black" onClick={handleCloseModal} className={styles.close} size="m" />
          </div>
          <div slot="body">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              rowSpacing={2}
              className={styles.domainModalBody}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Input
                  label="Domain"
                  id="agencyName"
                  placeholder="Enter Domain Name"
                  value={domainName?.name}
                  onChange={e => {
                    setDomainName({ ...domainName, name: e.target.value?.trim() })
                    setError('')
                  }}
                  maxLength={40}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Network' }}
                  htmlFor="network"
                  typography="s-strong"
                />
                <WppSelect
                  id="network"
                  onWppChange={event => {
                    setSelectedNetworkData(event.target.value)
                    setError('')
                  }}
                  placeholder="Select Network"
                  value={selectedNetworkData}
                  withSearch={true}
                  size="s"
                  className={styles.respondDays}
                  data-testid="network"
                >
                  {networkData?.map(network => (
                    <WppListItem key={network?.name} value={network}>
                      <p slot="label">{network?.name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </Grid>
              {error && (
                <WppTypography type="l-strong" className={styles.error}>
                  {error}
                </WppTypography>
              )}
            </Grid>
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={isLoading}
                onClick={() => addMapping(editMode)}
                data-testid="domain-network-add"
                disabled={!selectedNetworkData?.name || !domainName?.name}
              >
                {editMode ? 'Update' : 'Add'}
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleCloseModal}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      )}
    </Container>
  )
}

export default DomainNetwork
