import * as echarts from 'echarts'
export const buildAssessmentChart = (
  canvasRefAssessment: any,
  statusCountVE: any
) => {
  const option = {
    legend: {},
    tooltip: {},
    dataset: {
      source: [
        ['Status', 'Approved', 'Expired', 'Waiting', 'Rejected', 'Review', 'Risky', 'Created'],
        ['Assessments', ...statusCountVE]
      ]
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    color: ['#E0FAEA', '#C2F5D4', '#84EBAA', '#47E17F', '#1FBC59', '#15803D', '#538766']
  }
  const existingChart = echarts.getInstanceByDom(canvasRefAssessment)
  if (existingChart) {
    existingChart.dispose()
  }
  const myChart = echarts.init(canvasRefAssessment.current)
  option && myChart.setOption(option)
}
