import {SELECTED_TEMPLATE, GET_TEMPLATES} from './types';

const initialState = {
    data: ''
};

export function caSelectedTemplateReducer(state = initialState, action: any) {
    if (action.type === SELECTED_TEMPLATE) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}

export function caGetTemplateReducer(state = initialState, action: any) {
    if (action.type === GET_TEMPLATES) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}