import React, { useEffect, useState } from 'react'

import { Grid, Container, TextField, styled, Autocomplete, Box } from '@mui/material'
import {
  WppButton,
  WppBanner,
  WppSpinner,
  WppIconError,
  WppModal,
  WppIconClose
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import TextFieldCustom from 'components/textField/TextField'

import styles from './CreateVendors.module.scss'
import { INVENTORY } from '../../../../helper/constants'
import serviceURL from '../../../../helper/serviceURL'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import { CreateVendorsProps, ValidationError } from '../interface'
import { setCreateVendorData, getInitialData } from '../rowDetails/action'

interface AutoCompleteOption { id: number; name: string };

const StyledAutocomplete = styled(Autocomplete<AutoCompleteOption>)(() => ({
  '& .MuiInputLabel-root': {
    fontSize: '1.4rem',
    background: '#fff'
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px'
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    fontSize: '1.2rem'
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'auto',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  }
}))

const CreateVendors = ({ open, onClose, showToast }: CreateVendorsProps) => {
  const [isLoadingVendorName, setIsLoadingVendorName] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [vendorName, setVendorName] = useState('')
  const [vendorDesc, setVendorDesc] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showSpinner, setShowSpinner] = useState(false)
  const [warningVendorNameExist, setWarningVendorNameExist] = useState(false)
  const { axiosInstance } = useAxiosInterceptors()
  const navigate = useNavigate()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const [countryList, setCountryList] = useState<Array<{ id: number; name: string }>>([])
  const [industryList, setIndustryList] = useState<Array<{ id: number; name: string }>>([])
  const [selectedCountry, setSelectedCountry] = useState({ id: 0, name: '' })
  const [selectedIndustry, setSelectedIndustry] = useState({ id: 0, name: '' })
  const [countryInputValue, setCountryInputValue] = useState('')
  const [industryInputValue, setIndustryInputValue] = useState('')
  const dispatch = useDispatch()

  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const delayTime = 500

  useEffect(() => {
    let delayTimer: any = null
    if (vendorName) {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        fetchInvName()
      }, delayTime)
    } else {
      setIsLoadingVendorName(false)
      setWarningVendorNameExist(false)
    }
    return () => {
      clearTimeout(delayTimer)
    }
  }, [vendorName])

  const fetchInvName = () => {
    const apiUrl =
      serviceURL.pgpBaseAPI +
      '/api/inventory/' +
      INVENTORY.TYPE_VE +
      '/exists?name=' +
      encodeURIComponent(vendorName?.trim())
    setIsLoadingVendorName(true)
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(response => {
        setIsLoadingVendorName(false)
        setWarningVendorNameExist(response?.data)
      })
      .catch(error => {
        setIsLoadingVendorName(false)
        setWarningVendorNameExist(false)
        console.log(error)
      })
  }

  const fetchCountry = async () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/countries'
    try {
      const response = await axiosInstance.get(apiUrl, {
        headers: headers
      })
      setCountryList(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchIndustry = async () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/industries'
    try {
      const response = await axiosInstance.get(apiUrl, {
        headers: headers
      })
      setIndustryList(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCountry()
    fetchIndustry()
  }, [])

  const createVendor = () => {
    let apiUrl: string
    const postData = {
      name: vendorName?.trim(),
      countryId: selectedCountry?.id,
      industryId: selectedIndustry?.id,
      description: vendorDesc
    }
    apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + INVENTORY.TYPE_VE + '/v1'
    setShowSpinner(true)
    axiosInstance
      .post(apiUrl, postData, {
        headers: headers
      })
      .then(res => {
        setIsToShowBanner(false)
        if (showToast) {
          showToast(true)
        }
        dispatch(setCreateVendorData(res?.data))
        dispatch(getInitialData({vendorData: {}, assessment: {}}))
        navigate(`/vrm/vendors-list/${res?.data?.id}`)
        onClose()
      })
      .catch(error => {
        setShowSpinner(false)
        setIsToShowBanner(true)
        console.log(error)
      })
  }

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: any) => item.name !== fieldName)
  }

  const handleVendorName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingVendorName(true)
    setWarningVendorNameExist(false)
    setVendorName(e.target.value)
    setValidationErrors(updateValidationList('name'))
  }

  const handleVendorDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVendorDesc(e.target.value)
  }

  const handleCountryName = (_event: React.SyntheticEvent, newValue: { id: number; name: string }) => {
    setSelectedCountry(newValue)
  }

  const handleIndustryName = (_event: React.SyntheticEvent, newValue: { id: number; name: string }) => {
    setSelectedIndustry(newValue)
  }

  const closeSideModal = () => {
    onClose()
  }

  return (
      <WppModal open={open} size="m" className={styles.modalContainer} onWppModalClose={closeSideModal}>
        <div slot="header" className={styles.modalHeader}>
          <h3 className={styles.vendorLabel} data-testid='create-vendor-modal'>Create Vendor</h3>
          <WppIconClose color="black" onClick={closeSideModal} className={styles.close} size="m" data-testid='create-vendor-close-button' />
        </div>
        <div className={styles.vInventoryBody} slot="body">
          <div className={styles.vLoadingArea}>
            <WppBanner id="banner" type="information" className={styles.banner} show={isToShowBanner} closable>
            Unable to create vendor. Please refresh or try after some time.
            </WppBanner>
          </div>
          <Container maxWidth="xl">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={4}>
              <Grid item xs={12} sm={4} md={4} className={styles.vendorName}>
                <TextFieldCustom
                  name="vendorName"
                  label="Vendor Name"
                  fullWidth
                  onChange={e => handleVendorName(e)}
                  maxLength={70}
                  value={vendorName}
                  data-testid='create-vendor-name-input'
                />
                <div className={styles.vWarningSec}>
                  {isLoadingVendorName && <WppSpinner size="s" color="black" data-testid='spinner' />}
                  {warningVendorNameExist && (
                    <p className={styles.warningNameExist}>
                      <WppIconError data-testid="error-icon" /> <span data-testid="error-text">Vendor name already exists.</span>
                    </p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <StyledAutocomplete
                  id="v-country"
                  options={countryList}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleCountryName(event, newValue); 
                    }
                  }}
                  inputValue={countryInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setCountryInputValue(newInputValue)
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{ fontSize: '1.2rem' }}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Country (Optional)"
                      name="country"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <StyledAutocomplete
                  id="v-industry"
                  options={industryList}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleIndustryName(event, newValue); 
                    }
                  }}
                  inputValue={industryInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setIndustryInputValue(newInputValue)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Box component="li" {...props} sx={{ fontSize: '1.2rem' }} >
                        {option.name}
                      </Box>
                    )
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Industry (Optional)"
                      name="industry"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCustom
                  name="v-description"
                  label="Description (Optional)"
                  multiline
                  fullWidth
                  rows={4}
                  onChange={(e) => handleVendorDescription(e)}
                  maxLength={2048}
                  value={vendorDesc}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.btnSection} slot="actions">
          <WppButton
            className={styles.customBtnSecondary}
            id="create_vendor"
            disabled={isLoadingVendorName || warningVendorNameExist || !vendorName}
            variant="secondary"
            loading={showSpinner}
            onClick={createVendor}
            data-testid='create-vendor-button'
          >
            Create Vendor
          </WppButton>
        </div>
      </WppModal>
  )
}

export default CreateVendors
