import React, { useEffect, useState } from 'react'

import {
  WppBanner,
  WppCard,
  WppDivider,
  WppIconApp,
  WppIconLockOff,
  WppIconLockOn,
  WppProgressIndicator,
  WppTag
} from '@wppopen/components-library-react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cardList } from './CardList'
import styles from './LandingPage.module.scss'
import WelcomeMessage from './WelcomeMessage'

export const LandingPage = () => {
  const userRoles = useSelector((state: any) => state.getOrgByUserRed)
  const navigate = useNavigate()
  const [cardMenu, setCardMenu] = useState<any>()
 
  const handleCard = (path: string) => {
    navigate(path)
  }

  useEffect(() => {
    if (userRoles.data) {
      setCardMenu(cardList(userRoles.data))
    }
  }, [userRoles])

  if (userRoles.loading) {
    return <WppProgressIndicator className={styles.customLoader} variant="bar" />
  } else if (userRoles.data) {
    return (
        <div className={styles.container}>
          <WelcomeMessage userData={userRoles?.data} agency={userRoles?.context?.agency} />
          <div className={styles.cardSection}>
            {cardMenu?.map((card: any) => (
                <div
                  key={`card-sec-${card.name}_${card.description}`}
                  className={`${
                    userRoles?.data?.organizationId === -1 || card.disable
                      ? styles.notAuthorizeCard + ' ' + styles.cardSectionChild
                      : styles.cardSectionChild
                  }`}
                >
                  <WppCard
                    key={`card-${card.name}_${card.description}`}
                    className={styles.cardStyle}
                    size="2xl"
                    disabled={userRoles?.data?.organizationId === -1 || card.disable}
                    interactive={!card.disable}
                    onWppClick={() => handleCard(card.navigation)}
                  >
                    <div key={`action-${card.name}_${card.description}`} className={styles.authorize}>
                      <div className={styles.apps}>
                        <WppTag label={`${card.apps} Apps`} categoricalColorIndex={8}>
                          <WppIconApp slot="icon-start" />
                        </WppTag>
                      </div>
                      <div className={styles.authorizeLabel}>
                        {userRoles?.data?.organizationId === -1 || card.disable ? (
                          <WppTag label="Not Authorised" variant="negative">
                            <WppIconLockOff slot="icon-start" />
                          </WppTag>
                        ) : (
                          <WppTag label="Authorised" variant="positive">
                            <WppIconLockOn slot="icon-start" />
                          </WppTag>
                        )}
                      </div>
                    </div>
                    <div className={styles.header}>
                      {card.name}
                    </div>
                    <WppDivider />
                    <div className={styles.description}>
                      {card.description}
                    </div>
                  </WppCard>
                </div>
              ))}
          </div>
        </div>
    )
  } else if (userRoles.error) {
    return (
      <WppBanner id="banner" type="information" show={true}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
    )
  }
  return null
}

export default LandingPage
