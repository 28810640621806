import React, { memo, useLayoutEffect, useState } from 'react'

import { WppPill, WppPillGroup } from '@wppopen/components-library-react'

import styles from './QueAnsAssessment.module.scss'

interface Option {
  name?: string
}

interface MultiSelectProps {
  question: { questionType: string; id: string }
  response: string
}

const MultiSelect = memo((props: MultiSelectProps) => {
  const [selectedMultiSelect, setSelectedMultiSelect] = useState<Option[]>([])

  const initialiseMultiSelect = (response: string) => {
    if (response) {
      let result =
        response?.split('|')?.map((item: string) => {
          return { name: item.trim() }
        }) || []
      setSelectedMultiSelect(result)
    }
  }

  useLayoutEffect(() => {
    initialiseMultiSelect(props.response)
  }, [props.response])

  return (
    <WppPillGroup className={styles.wppPillGroupDisabled} type="display">
      {selectedMultiSelect.map((option: Option, idx: number) => (
        <div className={styles.options} key={`${props.question?.id}-${idx}`} title={option.name}>
          <WppPill className={styles.wppPill} title={option.name} label={option.name} disabled value={option?.name} />
        </div>
      ))}
    </WppPillGroup>
  )
})

export default MultiSelect
