import React, { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress
} from '@mui/material'
import { WppIconDownload, WppSpinner } from '@wppopen/components-library-react'
import axios from 'axios'

import { getLocalDate } from 'helper/Helper'
import { serviceURL } from 'helper/serviceURL'

import styles from './bulkRequestUpload.module.scss'
import appConfig from '../../../app.config'

const headers = {
  Authorization: 'Basic ' + appConfig.DPS_TOKEN
}

const BulkAuditTable = ({ rows, isLoading }: any) => {
  const [isDownloading, setDownloading] = useState(false)
  const handleDownload = (id: any) => {
    try {
      setDownloading(true)
      const apiUrl = serviceURL.dpsBaseAPI + `/dps/admin/dsar/bulk-download/${id}`
      axios
        .get(apiUrl, {
          headers: headers,
          responseType: 'blob'
        })
        .then(response => {
          const contentDisposition = response.headers['content-disposition']
          let filename = 'default-filename'

          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="([^"]+)"/)
            if (filenameMatch && filenameMatch[1]) {
              filename = filenameMatch[1]
            }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()

          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error('Download failed:', error)
        })
    } catch (e) {
      console.log('error', e)
    } finally {
      setDownloading(false)
    }
  }
  return (
    <TableContainer component={Paper} className={styles.bulkRequestDetails}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Upload Datetime</TableCell>
            <TableCell>Uploader</TableCell>
            <TableCell>Success</TableCell>
            <TableCell>Fail</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Results</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          ) : rows && rows.length ? (
            rows.map(
              (row: {
                id: React.Key | null | undefined
                createdAt: any
                createdBy: string
                success: string
                failed: string
                status: string
              }) => (
                <TableRow key={row.id}>
                  <TableCell>{row.createdAt ? getLocalDate(row.createdAt, true) : ''}</TableCell>
                  <TableCell>{row.createdBy}</TableCell>
                  <TableCell>{row.success}</TableCell>
                  <TableCell>{row.failed}</TableCell>
                  <TableCell
                    className={row.status === 'Completed' ? styles.completedStatus : styles.inCompletedStatus}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell>
                    {isDownloading ? (
                      <WppSpinner size="s" />
                    ) : row?.status === 'Completed' ? (
                      <IconButton color="primary" onClick={() => handleDownload(row.id)}>
                        <WppIconDownload slot="icon-start" className={styles.customIcon} />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow>
              <TableCell colSpan={6}>No Data</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BulkAuditTable
