import React, { ChangeEvent } from 'react'

import { WppTypography, WppLabel, WppIconError } from '@wppopen/components-library-react'

import styles from './Input.module.scss'

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  className?: string
  value: string | undefined
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  placeholder?: string
  disabled?: boolean
  name?: string
  label: string
  message?: string
}
const Input = ({
  className,
  value = '',
  onChange,
  maxLength = 2048,
  placeholder,
  disabled,
  label,
  message,
  ...rest
}: InputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.length <= maxLength) {
      onChange(event)
    }
  }

  return (
    <div className={className}>
      <WppLabel className={styles.label} config={{ text: label }} typography="s-strong" />
      <input
        className={message || value?.length === maxLength ? styles.errorInput : styles.input}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
      <div className={styles.charactersLimit}>
        <div className={styles.errorBox}>
          {message && (
            <>
              <WppIconError />
              <WppTypography className={styles.error} type="xs-strong">
                {message}
              </WppTypography>
            </>
          )}
        </div>
        <div className={styles.charBox}>
          <WppTypography className={value?.length === maxLength ? styles.error : ''} type="xs-strong">
            {value?.length ?? 0}/{maxLength}
          </WppTypography>
        </div>
      </div>
    </div>
  )
}

export default Input
