import { CREATE_INVENTORY } from './types'

export const createInventory = (obj: object) => ({
  type: CREATE_INVENTORY,
  payload: obj
})

export const createInventoryDispatcher = (obj: object) => (dispatch: any) => {
  dispatch(createInventory(obj))
}
