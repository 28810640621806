import {GET_ASSESS_BY_ID, GET_INV_BY_ASSESS_ID, CLEAR_SELECTED_ROW_DATA, UPDATE_ROW_DATA} from './types';

const initialState = {
    data: {}
};

export function assessmentRowDetailsRed(state = initialState, action: any) {
    if (action.type === GET_ASSESS_BY_ID) {
        return {
            ...state,
            data: action.payload,
        };
    } else if(action.type === CLEAR_SELECTED_ROW_DATA){
        return {
            data: action.payload,
        };
    }  else if(action.type === UPDATE_ROW_DATA){
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}
export function inventoryDetailsViaAssessRed(state = initialState, action: any) {
    if (action.type === GET_INV_BY_ASSESS_ID) {
        return {
            ...state,
            data: action.payload,
        };
    } else {
        return state;
    }
}