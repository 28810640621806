import React, { useCallback, useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import { WppToast } from '@wppopen/components-library-react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'

import styles from './AssessmentList.module.scss'
import { AssessmentListProps } from './interface'
import RowDetails from './rowDetails/RowDetails'
import DataGrid from '../../../components/dataGrid/DataGrid'
import { ASSES_GRID_HEADER_DETAILS, USER_ROLE, NETWORK } from '../../../helper/constants'
import isObjectEmpty from '../../../helper/Helper'
import serviceURL from '../../../helper/serviceURL'
import { launchAssessDispatcher } from '../../dmModule/processingActivityList/rowDetails/action'

const mapStateToProps = (state: any) => {
  return {
    userData: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context,
    assessRowDetails: state.assessmentRowDetailsRed.data,
    isTriggerReload: state?.triggerReload?.data,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value)),
})

const AssessmentList = React.memo(
  ({ launchAssessDispatcher, userData, assessRowDetails, isTriggerReload, setTriggerReloadDispatcher }: AssessmentListProps) => {
    const initialRowData = {
      id: '',
      name: '',
      description: '',
      inventoryId: '',
      inventoryType: '',
      categories: '',
      status: true
    }
    const [showApproveToast, setShowApproveToast] = useState(false)
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData)
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false)
    let { assessmentId } = useParams()
    const navigate = useNavigate()
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/assessments`

    useEffect(() => {
      if (assessmentId) {
        setClickedRowData({ id: assessmentId, name: assessRowDetails?.name })
        setOpenClickedRowModal(true)
      }
    }, [assessmentId, assessRowDetails?.name])

    const getClickedRow = useCallback((data: object): void => {
      if (!isObjectEmpty(data)) {
        setClickedRowData(data)
        setOpenClickedRowModal(true)
        setTriggerReloadDispatcher(false)
      }
    }, [])

    const closeSideModal = () => {
      launchAssessDispatcher && launchAssessDispatcher({})
      setOpenClickedRowModal(false)
    }

    const getShowApproveToast = (val: any) => {
      setShowApproveToast(val)
    }

    const handleModalClose = () => {
      setOpenClickedRowModal(false)
      navigate('/vrm/assessments-list')
      setTriggerReloadDispatcher(false);
    }

    const generateHeader = () => {
      const isAdmin = userData?.groups.some(group => group?.name === USER_ROLE.SAD);
      if(isAdmin){
        return ASSES_GRID_HEADER_DETAILS
      } else {
        const ASSESSMENT_GRID_HEADER_DETAILS = ASSES_GRID_HEADER_DETAILS?.map((item) => {
          if(item?.headerName === NETWORK){
            item.floatingFilter = false
            return item;
          } else {
            return item;
          }
        })
        return ASSESSMENT_GRID_HEADER_DETAILS
      }
    }

    return (
      <div className={styles.container}>
        <Container maxWidth="xl">
          <div className={styles.loadingArea}>
            {showApproveToast && (
              <WppToast className={styles.toast} message="Assessment reviewed successfully" type="success" />
            )}
          </div>
          <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ paddingTop: '12rem', marginBottom: '5rem' }}
              className="dataGridAssesmnet"
            >
              <DataGrid
                apiEndpoint={baseApiUrl + '/search'}
                headerDetails={generateHeader()}
                apiMethod="POST"
                pageSize={25}
                onRowClickedHandler={getClickedRow}
                isClearFilters={true}
                isTriggerReload={isTriggerReload}
              />
            </Grid>
          </Grid>
          {openClickedRowModal && (
            <RowDetails
              open={openClickedRowModal}
              clickedRowData={clickedRowData}
              showApproveToast={getShowApproveToast}
              closeSideModal={closeSideModal}
              onClose={handleModalClose}
            />
          )}
        </Container>
      </div>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList)