import React, { useState, useEffect, ChangeEvent } from 'react'

import { Grid, Container } from '@mui/material'
import {
  WppListItem,
  WppSelect,
  WppButton,
  WppBanner,
  WppLabel,
  WppToast,
  WppInput
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import EmailRespondent from 'components/emailRespondent/EmailRespondent'
import TextArea from 'components/textArea/TextArea'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'

import { selectedDaysDispatcher, assessNameDispatcher, respondentNoteDispatcher } from './action'
import { ValidationError, Step3Props, RespondentValidationError } from './interface'
import styles from './Step3.module.scss'
import { serviceURL } from '../../../../../helper/serviceURL'
import { validate } from '../../../../../helper/validate'
import useAxiosInterceptors from '../../../../../hooks/useAxiosInterceptors'

const mapStateToProps = (state: any) => {
  return {
    assessmentName: state.caAssessmentName.data,
    selectedType: state.caSelectedTypeReducer.data,
    selectedInv: state.caSelectedInvReducer.data,
    selectedMarket: state.caSelectedMarketReducer.data,
    selectedAgency: state.caSelectedAgencyReducer.data,
    selectedTemplate: state.caSelectedTemplateReducer.data,
    templateList: state.caGetTemplateReducer.data,
    invList: state.caInvListReducer.data,
    respondentList: state.caGetRespondentReducer.data,
    selectedDays: state.caSelectedDaysReducer.data,
    respondentNote: state.caRespondentNoteReducer.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    userData: state.getOrgByUserRed.data,
    typeOfService: state.caTypeOfServiceReducer.data,
    vendorDetails: state.vendorDetails.vendor
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  assessNameDispatcher: (name: string) => dispatch(assessNameDispatcher(name)),
  selectedDaysDispatcher: (days: number) => dispatch(selectedDaysDispatcher(days)),
  respondentNoteDispatcher: (note: string) => dispatch(respondentNoteDispatcher(note)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})

const Step3 = ({
  closeSideModal,
  selectedSideOrg,
  selectedDaysDispatcher,
  respondentNoteDispatcher,
  selectedDays,
  respondentNote,
  selectedType,
  selectedInv,
  selectedTemplate,
  assessNameDispatcher,
  selectedMarket,
  selectedAgency,
  userData,
  typeOfService,
  vendorDetails,
  assessmentName,
  setTriggerReloadDispatcher
}: Step3Props) => {
  const daysOptions = Array.from({ length: 60 }, (_, index) => index + 1)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showSpinner, setShowSpinner] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [assessmentToast, setAssessmentToast] = useState(false)
  const [bannerMsg, setBannerMsg] = useState('')
  const [respondentValidationErrors, setRespondentValidationErrors] = useState<RespondentValidationError[]>([])
  const [respondentEmail, setRespondentEmail] = useState<{ id: number; fullname: string; email: string }[]>([
    { id: 1, fullname: '', email: '' }
  ])
  const { axiosInstance } = useAxiosInterceptors()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }

  useEffect(() => {
    assessNameDispatcher &&
      assessNameDispatcher(
        `${vendorDetails?.name?.trim()} | ${typeOfService?.serviceType} | ${
          selectedMarket?.name
        } | ${new Date()?.getFullYear()}`
      )
  }, [])

  const validateRespondents = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const errors: ValidationError[] = []
    respondentEmail.forEach(respondent => {
      if (!respondent.fullname) {
        errors.push({ name: `fullname-${respondent.id}`, error: 'Name is required' })
      }
      if (respondent?.fullname?.length > 50) {
        errors.push({ name: `fullname-${respondent.id}`, error: 'Name limit is 50 character.' })
      }
      if (!respondent.email) {
        errors.push({ name: `email-${respondent.id}`, error: 'Email is required' })
      }
      if (respondent?.email?.toLowerCase().trim() === userData?.userEmail?.toLowerCase().trim()) {
        errors.push({ name: `email-${respondent.id}`, error: 'Please use email other than your login email.' })
      }
      if (!emailRegex.test(respondent?.email)) {
        errors.push({ name: `email-${respondent.id}`, error: 'Enter a valid email.' })
      }
      if (respondent?.email?.length > 50) {
        errors.push({ name: `email-${respondent.id}`, error: 'Email limit is 50 character..' })
      }
    })
    setRespondentValidationErrors(errors)
    return errors.length === 0
  }

  const launchAssessment = () => {
    let validationList: any[]
    let validateFields: object
    validateFields = {
      nameOfAssessment: assessmentName,
      selectedDays: selectedDays
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const hasError = validateRespondents()
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found && hasError) {
      const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/assessment/create/'
      setShowSpinner(true)
      let postData = {}
      if (selectedType && selectedTemplate && selectedInv && selectedSideOrg) {
        postData = {
          name: assessmentName,
          description: respondentNote,
          inventoryId: vendorDetails?.id,
          inventoryTypeId: selectedType.id,
          assessmentTemplateId: selectedTemplate.id,
          agencyId: selectedAgency?.id,
          serviceTypeId: typeOfService?.id,
          daysToRespond: selectedDays,
          respondents: respondentEmail,
          marketId: selectedMarket?.id
        }
      }
      axiosInstance
        .post(apiUrl, postData, {
          headers: headers
        })
        .then(() => {
          setShowSpinner(false)
          setIsToShowBanner(false)
          setBannerMsg('')
          setAssessmentToast(true)
          setTriggerReloadDispatcher?.(true)
          setTimeout(() => {
            closeSideModal && closeSideModal()
          }, 1200)
        })
        .catch(error => {
          setShowSpinner(false)
          setIsToShowBanner(true)
          setBannerMsg(error.response.data.message)
          console.log(error)
        })
    }
  }

  const handleNoteForRespondent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    respondentNoteDispatcher && respondentNoteDispatcher(e.target.value)
  }

  return (
    <>
      <div className={styles.loadingArea}>
        <WppBanner type="information" closable={true} show={isToShowBanner} className={styles.banner}>
          {bannerMsg}
        </WppBanner>
      </div>
      <Container maxWidth="xl">
        {assessmentToast && (
          <WppToast className={styles.assessmentToast} message="Assessment created successfully" type="success" />
        )}
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={5}>
          <Grid item xs={12} sm={5} md={5} className={styles.pRelative}>
            <WppLabel className={styles.label} config={{ text: 'Assessment Name' }} typography="s-strong" />
            <WppInput id="assessment-name" size="s" value={assessmentName} disabled />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <WppLabel
              className={styles.label}
              config={{ text: 'Number of Response Days' }}
              htmlFor="response-days"
              typography="s-strong"
            />
            <WppSelect
              id="response-days"
              onWppChange={e => selectedDaysDispatcher && selectedDaysDispatcher(e.target.value)}
              placeholder="Number Of Days To Respond"
              value={selectedDays}
              withSearch={true}
              size="s"
              message={
                (validationErrors && validationErrors.find((item: any) => item.name === 'selectedDays')?.error) || ''
              }
              messageType={
                validationErrors && validationErrors.find((item: any) => item.name === 'selectedDays')?.error
                  ? 'error'
                  : undefined
              }
            >
              {daysOptions.map(days => (
                <WppListItem key={days} value={days}>
                  <p slot="label">{days}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </Grid>

          <EmailRespondent
            value={respondentEmail}
            setValue={setRespondentEmail}
            validationErrors={respondentValidationErrors}
          />

          <Grid item xs={12} sm={12} md={12}>
            <WppLabel
              className={styles.label}
              config={{ text: 'Note For Respondent' }}
              htmlFor="description"
              typography="s-strong"
            />
            <TextArea
              id="description"
              placeholder="Enter Note For Respondant"
              value={respondentNote}
              onChange={e => handleNoteForRespondent(e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="right">
            <WppButton
              className={styles.customBtnSecondary}
              variant="secondary"
              loading={showSpinner}
              onClick={launchAssessment}
            >
              Launch Assessment
            </WppButton>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3)
