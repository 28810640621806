import appConfig from '../../app.config'
import { TEXT, USER_ROLE, PROD } from '../../helper/constants'

const isUserAuthorized = (arr1: any, arr2: any) => {
  if (arr1) {
    const isUserAuth = arr1?.groups?.some((item: any) => arr2.includes(item.name))
    return !isUserAuth
  }
  return true
}
export const cardList = (roles: any) => {
  if (appConfig.NPROD === PROD) {
    return [
      {
        name: 'Vendor Risk Management',
        description: TEXT.TPRM_CARD,
        navigation: '/vrm/vendors-list',
        disable: isUserAuthorized(roles, [
          USER_ROLE.SYS,
          USER_ROLE.SAD,
          USER_ROLE.DPM,
          USER_ROLE.SU
        ])
      },
      {
        name: 'Admin Portal',
        description: TEXT.ADMIN_CARD,
        navigation: '/admin/agencies-network',
        disable: isUserAuthorized(roles, [USER_ROLE.SYS]),
      },
      {
        name: 'Consumer Preference Portal',
        description: TEXT.CPP_CARD,
        navigation: '/cpp/cpp-dashboard',
        disable: isUserAuthorized(roles, [USER_ROLE.CPPA]),
      }
    ]
  } else {
    return [
      {
        name: 'Vendor Risk Management',
        description: TEXT.TPRM_CARD,
        navigation: '/vrm/vendors-list',
        disable: isUserAuthorized(roles, [
          USER_ROLE.SYS,
          USER_ROLE.SAD,
          USER_ROLE.DPM,
          USER_ROLE.SU
        ])
      },
      {
        name: 'Data Mapping',
        description: TEXT.DM_CARD,
        navigation: '/dm/dm-dashboard',
        disable: isUserAuthorized(roles, [USER_ROLE.DPM]),
      },
      {
        name: 'Admin Portal',
        description: TEXT.ADMIN_CARD,
        navigation: '/admin/agencies-network',
        disable: isUserAuthorized(roles, [USER_ROLE.SYS]),
      },
      {
        name: 'Consumer Preference Portal',
        description: TEXT.CPP_CARD,
        navigation: '/cpp/cpp-dashboard',
        disable: isUserAuthorized(roles, [USER_ROLE.CPPA]),
      }
    ]
  }
}
