import React, { useEffect, useState } from 'react'

import { WppBanner, WppCard, WppDivider, WppProgressIndicator } from '@wppopen/components-library-react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cardList } from './CardList'
import styles from './LandingPage.module.scss'
import WelcomeMessage from './WelcomeMessage'

export const LandingPage = () => {
  const userRoles = useSelector((state: any) => state.getOrgByUserRed)
  const navigate = useNavigate()
  const [cardMenu, setCardMenu] = useState<any>()

  const handleCard = (path: string) => {
    navigate(path)
  }

  useEffect(() => {
    if (userRoles.data) {
      setCardMenu(cardList(userRoles.data))
    }
  }, [userRoles])

  if (userRoles.loading) {
    return <WppProgressIndicator className={styles.customLoader} variant="bar" />
  } else if (userRoles.data) {
    return (
      <div className={styles.container}>
        <WelcomeMessage userData={userRoles?.data} agency={userRoles?.context?.agency} />
        <div className={styles.cardSection}>
          {cardMenu?.map((card: { name: string; description: string; disable: boolean; navigation: string }) => {
            return (
              (userRoles?.data?.organizationId === -1 || !card.disable) && (
                <div key={`card-sec-${card.name}_${card.description}`} className={styles.cardSectionChild}>
                  <WppCard
                    key={`card-${card.name}_${card.description}`}
                    className={styles.cardStyle}
                    size="2xl"
                    interactive={true}
                    onWppClick={() => handleCard(card.navigation)}
                  >
                    <div className={styles.header}>{card.name}</div>
                    <WppDivider />
                    <div className={styles.description}>{card.description}</div>
                  </WppCard>
                </div>
              )
            )
          })}
        </div>
      </div>
    )
  } else if (userRoles.error) {
    return (
      <WppBanner id="banner" type="information" show={true}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
    )
  }
  return null
}

export default LandingPage
