import React from 'react'

import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, styled } from '@mui/material'
import { WppTypography } from '@wppopen/components-library-react'

import styles from './TextField.module.scss'

export interface TextInputFieldProps {
  maxLength: number
  value: string
  label: string
  name?: string
  rows?: number
  fullWidth?: boolean
  multiline?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const StyledTextField = styled(MuiTextField)<MuiTextFieldProps>(() => ({
  '& .MuiInputLabel-root': {
    fontSize: '1.4rem',
    background: '#fff'
  },
  '& .MuiInputBase-input': {
    fontSize: '1.2rem',
    lineHeight: '1.4375em',
    scrollbarColor: '#ccc transparent',
    scrollbarWidth: 'thin'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px'
  }
}))

export default function TextField(props: Readonly<TextInputFieldProps>) {
  const { label, maxLength, value, onChange, ...rest } = props
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.length <= maxLength) {
      onChange(event)
    }
  }
  return (
    <div>
      <StyledTextField
        inputProps={{ maxLength: maxLength}}
        label={label}
        value={value}
        fullWidth
        onChange={handleChange}
        {...rest}
      />
      <div className={styles.charactersLimit}>
        <WppTypography className={value?.length === maxLength ? styles.error : ''} type="xs-strong">
          {value.length}/{maxLength}
        </WppTypography>
      </div>
    </div>
  )
}
