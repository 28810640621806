import React, { ChangeEvent } from 'react'

import { WppTypography } from '@wppopen/components-library-react'

import styles from './TextArea.module.scss'

interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  className?: string
  value: string | undefined
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  maxLength?: number
  placeholder?: string
  disabled?: boolean
  name?: string
}
const TextArea = ({ className, value = '', onChange, maxLength = 2048, placeholder, disabled, ...rest }: TextAreaProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event?.target?.value.length <= maxLength) {
      onChange(event)
    }
  }

  return (
    <div className={className}>
      <textarea
        className={value?.length === maxLength ? styles.textAreaError : styles.textArea}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
      <div className={styles.charactersLimit}>
        <WppTypography className={value?.length === maxLength ? styles.error : ''} type="xs-strong">
          {value?.length ?? 0}/{maxLength}
        </WppTypography>
      </div>
    </div>
  )
}

export default TextArea
