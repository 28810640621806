import axios from 'axios'

import { SELECTED_TEMPLATE, GET_TEMPLATES } from './types'
import { serviceURL } from '../../../../../helper/serviceURL'

export const selectedTemplate = (template: object) => ({
  type: SELECTED_TEMPLATE,
  payload: template
})

export const selectedTemplateDispatcher = (template: object) => (dispatch: any) => {
  dispatch(selectedTemplate(template))
}

export const getTemplates = (templateList: any) => ({
  type: GET_TEMPLATES,
  payload: templateList
})

export const getTemplatesDispatcher = (head: object, type: { name: string }) => (dispatch: any) => {
  const apiUrl =
    type.name === ''
      ? serviceURL.pgpBaseAPI + '/api/templates'
      : serviceURL.pgpBaseAPI + '/api/templates?type=' + type.name
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl, {
        headers: head
      })
      .then((res: any) => {
        dispatch(getTemplates(res.data))
        resolve(res)
      })
      .catch((error: any) => reject(error))
  })
}
