import * as React from 'react';

import styles from './LegendTable.module.scss';

interface LegendTableProps {
  id?: string;
  rows: number;
  columns: number;
  headers: Array<string>;
  values: Array<any>;
}
const LegendTable = ({ id, rows, columns, headers, values }: LegendTableProps) => {
  const rowNumbers = [...Array(rows).keys()];
  const columnNumbers = [...Array(columns).keys()];

  return (
    <table className={styles.donutTh} id={id}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowNumbers.map((rowNumber) => (
          <tr key={rowNumber}>
            {columnNumbers.map((columnNumber) => {
              const index = rowNumber * columns + columnNumber;
              const value = index < values.length ? values[index] : '';
              return (
                <td title={value} key={columnNumber}>
                  {value}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LegendTable;
