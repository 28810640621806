export const SELECTED_TYPE = 'SELECTED_TYPE';
export const SELECTED_INVENTORY = 'SELECTED_INVENTORY';
export const SELECTED_REGION = 'SELECTED_REGION';
export const SELECTED_MARKET = 'SELECTED_MARKET';
export const SELECTED_AGENCY = 'SELECTED_AGENCY';
export const GET_INV_LIST = 'GET_INV_LIST';
export const GET_REG_LIST = 'GET_REG_LIST';
export const GET_MARKET_LIST = 'GET_MARKET_LIST';
export const GET_AGENCY_LIST = 'GET_AGENCY_LIST';
export const GET_SERVICE_TYPE_LIST = 'GET_SERVICE_TYPE_LIST';
export const SELECTED_TYPE_OF_SERVICE = 'SELECTED_TYPE_OF_SERVICE';
