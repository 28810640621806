import React, { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { AutocompleteDefaultOption } from '@wppopen/components-library'
import { WppAutocompleteCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppModal,
  WppButton,
  WppListItem,
  WppInput,
  WppSelect,
  WppLabel,
  WppTypography,
  WppDivider,
  WppAutocomplete,
  WppPillGroup,
  WppPill
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { debounce, map,  } from 'lodash'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'

import styles from './CreateProcessingActivity.module.scss'
import { USER_ROLE } from '../../../../helper/constants'
import serviceURL from '../../../../helper/serviceURL'
import { validate } from '../../../../helper/validate'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import { CreateProcessingActivityProps, ValidationError, DropdownProps } from '../interface'

const mapStateToProps = (state: any) => {
  return {
    userContext: state.getOrgByUserRed.context,
    userData: state.getOrgByUserRed.data
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})

const CreateProcessingActivity = ({
  open,
  onClose,
  userContext,
  userData,
  paData,
  launchPA = false,
  setTriggerReloadDispatcher,
}: CreateProcessingActivityProps) => {
  const initialState = { id: 0, name: '' }
  const [processingActivityName, setProcessingActivityName] = useState('')
  const [selectedReg, setSelectedReg] = useState(initialState)
  const [selectedMarket, setSelectedMarket] = useState(initialState)
  const [selectedAgency, setSelectedAgency] = useState(initialState)
  const [regList, setRegList] = useState<DropdownProps[]>([])
  const [marketList, setMarketList] = useState<DropdownProps[]>([])
  const [agencyList, setAgencyList] = useState<DropdownProps[]>([])
  const [respondentEmail, setRespondentEmail] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>()
  const [showSpinner, setShowSpinner] = useState(false)
  const [entityList, setEntityList] = useState<{id: number, label: string}[]>([])
  const [clientsValue, setClientsValue] = useState<AutocompleteDefaultOption[]>([])
  const [suppliersValue, setSuppliersValue] = useState<AutocompleteDefaultOption[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const {
    osApi: { getAccessToken }
  } = useOs()
  const navigate = useNavigate()
  const { axiosInstance } = useAxiosInterceptors()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }

  const isSiteAdmin = userData?.groups.some((group: any) => group?.name === USER_ROLE.SAD)

  useEffect(() => {
    fetchRegionList()
  }, [])

  useEffect(() => {
    if(paData){
      setProcessingActivityName(paData?.name)
      setRespondentEmail(paData?.businessProcessOwner)
      setSelectedReg(paData?.agency?.market?.region)
      setSelectedMarket(paData?.agency?.market)
      setSelectedAgency(paData?.agency)
      setClientsValue(paData?.externalLegalEntities?.client)
      setSuppliersValue(paData?.externalLegalEntities?.supplier)
    }
  }, [paData])

  useEffect(() => {
    if (selectedReg?.name !== '') {
      fetchMarketList()
    }
  }, [selectedReg])

  useEffect(() => {
    if (selectedMarket?.name !== '') {
      fetchAgencyList()
    }
  }, [selectedMarket])

  const fetchRegionList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/regions'
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setRegList(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchMarketList = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/regions/' + selectedReg?.id + '/markets'
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setMarketList(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchAgencyList = () => {
    const apiUrl =
      serviceURL.pgpBaseAPI +
      '/api/organizations/markets/' +
      userContext?.id +
      '/' +
      selectedMarket?.id +
      '/agencies?isAdmin=' +
      isSiteAdmin
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setAgencyList(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchEntityList = (event: WppAutocompleteCustomEvent<string>) => {
    setIsLoading(true)
    const value = event?.detail ?? ''
    const apiUrl =
      serviceURL.pgpBaseAPI +
      `/api/external/legal-entity?name=${value}`
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setEntityList(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const debouncedFetchEntityList = debounce(fetchEntityList, 300)

  const createProcessingActivity = () => {
    let validationList: any[]
    let apiUrl;
    if(launchPA){
       apiUrl = serviceURL.pgpBaseAPI + `/api/pa/${paData?.id}/launchAssessment`
    } else {
       apiUrl = serviceURL.pgpBaseAPI + '/api/pa'
    }
    const suppliersIds = map(suppliersValue, 'id')
    const clientsIds = map(clientsValue, 'id')
    const payload = {
      name: processingActivityName,
      agencyId: selectedAgency.id,
      respondentEmail: respondentEmail,
      externalEntities: {
        supplier: suppliersIds,
        client: clientsIds,
      }
    }

    let validateFields: object
    validateFields = {
      name: processingActivityName,
      emailOfRespondent: respondentEmail,
      regionId: selectedReg?.name,
      marketId: selectedMarket?.name,
      agencyId: selectedAgency?.name
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found) {
      setShowSpinner(true)
      axiosInstance
        .post(apiUrl, payload, {
          headers: headers
        })
        .then(res => {
          setShowSpinner(false)
          if(!launchPA){
            navigate(`/dm/processing-activity/${res?.data?.id}`)
          }
          setTriggerReloadDispatcher?.(true)
          onClose()
        })
        .catch(() => {
          setShowSpinner(false)
          setError(true)
        })
        .finally(() => {
          setTriggerReloadDispatcher?.(false)
        })
    }
  }

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: { name: string }) => item.name !== fieldName)
  }

  const handleProcessingActivityName = (e: any) => {
    setProcessingActivityName(e.target.value)
    setValidationErrors(updateValidationList('name'))
  }

  const handleRespondentEmail = (e: any) => {
    setRespondentEmail(e.target.value)
    setValidationErrors(updateValidationList('emailOfRespondent'))
  }

  const handleRegion = (e: any) => {
    setSelectedReg(e.target.value)
    setSelectedMarket(initialState)
    setSelectedAgency(initialState)
    setValidationErrors(updateValidationList('regionId'))
  }

  const handleMarket = (e: any) => {
    setSelectedMarket(e.target.value)
    setSelectedAgency(initialState)
    setValidationErrors(updateValidationList('marketId'))
  }

  const handleAgency = (e: any) => {
    setSelectedAgency(e.target.value)
    setValidationErrors(updateValidationList('agencyId'))
  }

  return (
    <WppModal open={open} size="m" onWppModalClose={onClose} className={styles.processBox}>
      <h3 slot="header">{launchPA ? 'Launch Processing Activity' : 'Create Processing Activity'}</h3>
      <div className={styles.inventoryBody} slot="body">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={4} className={styles.paModal}>
            <Grid item xs={12} sm={6} md={6}>
              <WppLabel
                className={styles.label}
                config={{ text: 'Activity Name' }}
                htmlFor="activity-name"
                typography="s-strong"
              />
              <WppInput
                id="activity-name"
                placeholder="Enter activity name"
                size="s"
                message={validationErrors?.find((item: any) => item.name === 'name')?.error || ''}
                messageType={validationErrors?.find((item: any) => item.name === 'name')?.error ? 'error' : undefined}
                value={processingActivityName}
                onWppChange={handleProcessingActivityName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <WppLabel
                className={styles.label}
                config={{ text: 'Respondent/Owner Email Address' }}
                htmlFor="respondent-email"
                typography="s-strong"
              />
              <WppInput
                id="respondent-email"
                placeholder="Enter respondent email"
                size="s"
                message={validationErrors?.find((item: any) => item.name === 'emailOfRespondent')?.error || ''}
                messageType={
                  validationErrors?.find((item: any) => item.name === 'emailOfRespondent')?.error ? 'error' : undefined
                }
                value={respondentEmail}
                onWppChange={handleRespondentEmail}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <WppDivider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '10px' }}>
              <WppTypography type="l-strong">WPP Entity</WppTypography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
              <WppLabel className={styles.label} config={{ text: 'Region' }} htmlFor="region" typography="s-strong" />
              <WppSelect
                id="region"
                onWppChange={handleRegion}
                placeholder="Select Region"
                value={regList?.find(region => region?.id === selectedReg?.id) || null}
                withSearch={true}
                size="s"
                message={validationErrors?.find((item: any) => item.name === 'regionId')?.error || ''}
                messageType={
                  validationErrors?.find((item: any) => item.name === 'regionId')?.error ? 'error' : undefined
                }
              >
                {regList?.length ? (
                  regList?.map((item: { name: string }, idx: number) => (
                    <WppListItem key={idx} value={item}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  ))
                ) : (
                  <WppListItem>
                    <p slot="label">No Data</p>
                  </WppListItem>
                )}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
              <WppLabel className={styles.label} config={{ text: 'Market' }} htmlFor="market" typography="s-strong" />
              <WppSelect
                id="market"
                onWppChange={handleMarket}
                placeholder="Select Market"
                disabled={selectedReg?.name === ''}
                value={marketList?.find(market => market?.id === selectedMarket?.id) || null}
                withSearch={true}
                size="s"
                message={validationErrors?.find((item: any) => item.name === 'marketId')?.error || ''}
                messageType={
                  validationErrors?.find((item: any) => item.name === 'marketId')?.error ? 'error' : undefined
                }
              >
                {marketList?.length ? (
                  marketList?.map((item: { name: string }, idx: number) => (
                    <WppListItem key={idx} value={item}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  ))
                ) : (
                  <WppListItem>
                    <p slot="label">No Data</p>
                  </WppListItem>
                )}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
              <WppLabel className={styles.label} config={{ text: 'Agency' }} htmlFor="agency" typography="s-strong" />
              <WppSelect
                id="agency"
                onWppChange={handleAgency}
                placeholder="Select Agency"
                disabled={selectedMarket?.name === ''}
                value={agencyList?.find(agency => agency?.id === selectedAgency?.id) || null}
                withSearch={true}
                size="s"
                message={validationErrors?.find((item: any) => item.name === 'agencyId')?.error || ''}
                messageType={
                  validationErrors?.find((item: any) => item.name === 'agencyId')?.error ? 'error' : undefined
                }
              >
                {agencyList?.length ? (
                  agencyList?.map((item: { name: string }, idx: number) => (
                    <WppListItem key={idx} value={item}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  ))
                ) : (
                  <WppListItem>
                    <p slot="label">No Data</p>
                  </WppListItem>
                )}
              </WppSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <WppDivider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '10px' }}>
              <WppTypography type="l-strong">External Entities</WppTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
              <WppAutocomplete
                name="client"
                labelConfig={{
                  text: 'Clients',
                  locales: {
                    optional: 'Optional',
                  },
                }}
                placeholder="Search clients"
                value={clientsValue}
                onWppSearchValueChange={debouncedFetchEntityList}
                onWppChange={e => setClientsValue(e.detail.value as AutocompleteDefaultOption[])}
                data-testid="client-autocomplete"
                multiple
                type='regular'
                size='s'
                loading={isLoading}
                className={styles.autocomplete}
              >
                {entityList?.map(option => (
                  <WppListItem key={option.id} value={option} label={option.label}>
                    <p slot="label">{option.label}</p>
                  </WppListItem>
                ))}
              </WppAutocomplete>
              <WppPillGroup type='display' className={styles.groupBox}>
                {clientsValue?.map((option, idx) => (
                  <div className={styles.options} key={idx} title={option.label}>
                    <WppPill
                      className={styles.wppPill}
                      title={option.label}
                      label={option.label}
                      value={option?.label}
                      removable={true}
                      onWppClose={() => setClientsValue(clientsValue.filter(i => i.id !== option.id))}
                    />
                  </div>
                ))}
              </WppPillGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
              <WppAutocomplete
                name="supplier"
                labelConfig={{
                  text: 'Suppliers',
                  locales: {
                    optional: 'Optional',
                  },
                }}
                placeholder="Search suppliers"
                value={suppliersValue}
                onWppSearchValueChange={debouncedFetchEntityList}
                onWppChange={e => setSuppliersValue(e.detail.value as AutocompleteDefaultOption[])}
                data-testid="supplier-autocomplete"
                multiple
                type='regular'
                size='s'
                simpleSearch
                loading={isLoading}
                className={styles.autocomplete}
              >
                {entityList?.map(option => (
                  <WppListItem key={option.id} value={option} label={option.label}>
                    <p slot="label">{option.label}</p>
                  </WppListItem>
                ))}
              </WppAutocomplete>
              <WppPillGroup type='display' className={styles.groupBox}>
                {suppliersValue?.map((option) => (
                  <div className={styles.options} key={option?.id} title={option.label}>
                    <WppPill
                      className={styles.wppPill}
                      title={option.label}
                      label={option.label}
                      value={option?.label}
                      removable={true}
                      onWppClose={() => setSuppliersValue(suppliersValue.filter(i => i.id !== option.id))}
                    />
                  </div>
                ))}
              </WppPillGroup>
              {error && (
                <WppTypography type="l-strong" className={styles.error}>
                  Something went wrong please try again after some time.
                </WppTypography>
              )}
            </Grid>
          </Grid>
      </div>
      <div className={styles.btnSection} slot="actions">
      <WppButton
          className={styles.customBtnSecondary}
          id="create_processing_activity"
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </WppButton>
        <WppButton
          className={styles.customBtnSecondary}
          id="create_processing_activity"
          variant="secondary"
          loading={showSpinner}
          onClick={createProcessingActivity}
        >
          {launchPA ? 'Launch' : 'Create'}
        </WppButton>
      </div>
    </WppModal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProcessingActivity)
