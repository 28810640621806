import React from 'react'

import { WppTooltip } from '@wppopen/components-library-react'
import { renderToString } from 'react-dom/server'

interface MultipleValueWithToolTipProps {
  colDef: {
    field: string
  }
  data: {
    [key: string]: string[]
  }
  value: string
}

const MultipleValueWithToolTip = (params: MultipleValueWithToolTipProps) => {
  const data = params?.data?.[params?.colDef?.field]?.map((item) => <li key={item}>{item}</li>)

  const renderString = renderToString(
    <ul
      style={{
        padding: '0 5px',
        listStyle: 'none',
        margin: '5px 0',
        maxHeight: '500px',
        overflow: 'auto',
        scrollbarColor: '#ccc transparent',
        scrollbarWidth: 'thin'
      }}
    >
      {data}
    </ul>
  )

  const categoryColor = (params: MultipleValueWithToolTipProps) => {
    if (params?.data?.[params?.colDef?.field]?.length > 3) {
      return (
        <WppTooltip config={{ placement: 'right-start', allowHTML: true }} text={renderString}>
          [Multiple]
        </WppTooltip>
      )
    } else {
      return  <WppTooltip text={params?.data?.[params?.colDef?.field]?.map(item => item).join(', ')}>{params?.data?.[params?.colDef?.field]?.map(item => item).join(', ')}</WppTooltip>
    }
  }

  return <React.Fragment>{params?.value ? categoryColor(params) : null}</React.Fragment>
}

export default MultipleValueWithToolTip
