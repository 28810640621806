import React, { useState } from 'react'

import {
  WppModal,
  WppButton,
  WppIconClose,
  WppIconUpload,
  WppTag,
  WppIconTrash,
  WppSpinner
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import axios from 'axios'

import styles from './FileUpload.module.scss'
import appConfig from '../../app.config'

export const uploadFileName = (filename: any) => {
  const firstUnderscoreIndex = filename.indexOf('_')
  const nameAfterFirstUnderscore = filename.substring(firstUnderscoreIndex + 1)
  return nameAfterFirstUnderscore
}

interface FileUploadProps {
  open: boolean
  handleClose: (val: boolean) => void
  questionId: string
  assessmentId: string
  setAttachedFiles: (val: any) => void
  attachedFiles: Array<File>
}

export const FileUpload = ({
  open,
  handleClose,
  assessmentId,
  questionId,
  setAttachedFiles,
  attachedFiles = []
}: FileUploadProps) => {
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [formatError, setFormatError] = useState(false)
  const [duplicateError, setDuplicateError] = useState(false)
  const [uploading, setUploading] = useState(false)
  const isValidFilename = (filename: string) => {
    const regex = /^[a-zA-Z0-9$!~\-_.' ]+$/
    return regex.test(filename)
  }

  const sanitizeFileName = (filename: string) => {
    // eslint-disable-next-line no-useless-escape
    return filename.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9$!~\-_'\.]/g, '')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files) {
      return
    }

    const fileArray = Array.from(files)
    const validFiles: File[] = []
    const invalidFiles: string[] = []
    const duplicateFiles: string[] = []
    fileArray.forEach(file => {
      const lastDotIndex = file.name.lastIndexOf('.')
      const originalFileName = file.name.substring(0, lastDotIndex)
      const extension = file.name.substring(lastDotIndex)

      const sanitizedFileName = sanitizeFileName(originalFileName) + extension
      const isValid = isValidFilename(sanitizedFileName)
      const isDuplicate = attachedFiles?.some(
        (selectedFile: any) => uploadFileName(selectedFile.filename) === sanitizedFileName
      )

      if (isDuplicate) {
        duplicateFiles.push(sanitizedFileName)
      } else if (isValid) {
        const sanitizedFile = new File([file], sanitizedFileName, { type: file.type })
        validFiles.push(sanitizedFile)
      } else {
        invalidFiles.push(sanitizedFileName)
      }
    })

    setFormatError(invalidFiles.length > 0);
    setDuplicateError(duplicateFiles.length > 0);
    setSelectedFiles(validFiles);

    event.target.value = ''
  }

  const handleUpload = async () => {
    try {
      const allFiles = [...selectedFiles]
      if (allFiles.length > 0) {
        setUploading(true)
        const resp = await createSaveCalls(allFiles)
        if (resp.data.length > 0) {
          setAttachedFiles(attachedFiles && attachedFiles.length ? [...attachedFiles, ...resp.data] : [...resp.data])
          setSelectedFiles([])
          setFormatError(false)
          setDuplicateError(false)
          handleClose(false)
        }
      }
    } catch (e) {
      console.log('e', e)
    } finally {
      setUploading(false)
    }
  }

  const createSaveCalls = async (files: any) => {
    const apiUrl = appConfig.RA_HOST_URL + '/api/file/' + assessmentId
    const formData = new FormData()
    files?.forEach((file: any) => {
      formData.append('files', file)
    })
    formData.append('data', JSON.stringify({ questionBankId: questionId }))
    return axios
      .post(apiUrl, formData, {
        headers
      })
      .then((res: any) => {
        return res
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  const handleFileDelete = (item: { name: string }) => {
    const filesAvailiable = selectedFiles.filter(file => file.name !== item.name)
    setSelectedFiles(filesAvailiable)
    duplicateError === true && setDuplicateError(false)
  }

  return (
    <div className={styles.container1}>
      <WppModal open={open}>
        <div slot="header" className={styles.fileHeader}>
          <h3>Attachments</h3>
          <WppIconClose className={styles.closeIcon} onClick={() => handleClose(false)} />
        </div>

        <div slot="body" className={styles.fileBody}>
          <div>Click to browse the files</div>
          <div>You can upload multiple files.</div>
          <WppIconUpload color="primary" size="m" />
          <div className={styles.uploadBtn}>
            <WppButton variant="primary" size="s" className={styles.selectBtn}>
              Select Files
            </WppButton>
            <input type="file" multiple className={styles.inputFile} onChange={handleChange} />
          </div>
          {selectedFiles.length > 0 && (
            <>
              <span className={styles.fileNameTitle}>File(s):</span>
              <div className={styles.fileNames}>
                {selectedFiles.map((file, index) => (
                  <div key={index} className={styles.fileName}>
                    <WppTag label={`${index + 1} - ${file.name}`} maxLabelLength={50} />
                    <WppIconTrash
                      style={{ cursor: 'pointer' }}
                      color="primary"
                      size="s"
                      onClick={() => handleFileDelete(file)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {formatError && (
            <WppTag
              label="Special characters and space not allowed in file name!!"
              variant="warning"
              maxLabelLength={180}
            />
          )}
          {duplicateError && (
            <p className={styles.duplicateError}>
              Since the file name already exists, we have not added the file to avoid duplication
            </p>
          )}
        </div>

        <div slot="actions" className={styles.fileButton}>
          <WppButton variant="secondary" size="s" className={styles.btn} 
            onClick={() => {
              setDuplicateError(false)
              handleClose(false)
            }}
          >
            Cancel
          </WppButton>
          <WppButton
            variant="secondary"
            size="s"
            onClick={() => handleUpload()}
            className={styles.btn}
            disabled={selectedFiles.length > 0 ? false : true}
          >
            {uploading ? <WppSpinner size="s" /> : 'Save'}
          </WppButton>
        </div>
      </WppModal>
    </div>
  )
}
