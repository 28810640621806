import React, { useState, useEffect } from 'react'

import { WppSideModal, WppButton, WppStep, WppStepper } from '@wppopen/components-library-react'
import { connect } from 'react-redux'

import { mapStateToProps, mapDispatchToProps } from './connect'
import styles from './CreateAssessment.module.scss'
import { CreateAssessmentProps, ValidationError } from './interface'
import Step1 from './step1/Step1'
import Step2 from './step2/Step2'
import Step3 from './step3/Step3'
import { validate } from '../../../../helper/validate'

const CreateAssessment = ({
  open,
  selectedTypeDispatcher,
  selectedOrgDispatcher,
  selectedInvDispatcher,
  selectedRegionDispatcher,
  selectedMarketDispatcher,
  selectedAgencyDispatcher,
  getInvList,
  getRegList,
  getMarketList,
  getAgencyList,
  getTemplates,
  getRespondent,
  getAssesser,
  selectedTemplateDispatcher,
  assessNameDispatcher,
  selectedRespondentDispatcher,
  selectedDaysDispatcher,
  respondentNoteDispatcher,
  selectedAssesserDispatcher,
  showToast,
  selectedTemplate,
  isLaunchAssess,
  selectedRegion,
  selectedMarket,
  selectedAgency,
  selectedTypeOfService,
  getRegListDispatcher,
  getServiceTypeList,
  selectedTypeOfServiceDispatcher,
  closeMainModal,
  setTriggerReloadDispatcher,
}: CreateAssessmentProps) => {
  const StepNumber1: number = 1
  const StepNumber2: number = 2
  const StepNumber3: number = 3
  const [currentStep, setNextStep] = useState(StepNumber1)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const initialState = { id: 0, name: '' }
  const initialStateTypeOfService = { id: 0, serviceType: '' }
  const initialStateEmail = { id: 0, email: '' }
  const steps: { id: number; name: string }[] = [
    { id: StepNumber1, name: 'Fill Details' },
    { id: StepNumber2, name: 'Select Template' },
    { id: StepNumber3, name: 'Launch Assessment' }
  ]

  useEffect(() => {
    if (isLaunchAssess && !isLaunchAssess.openAssess) {
      selectedTypeDispatcher?.(initialState)
      selectedInvDispatcher?.(initialState)
      selectedOrgDispatcher?.(initialState)
      selectedRegionDispatcher?.(initialState)
      selectedMarketDispatcher?.(initialState)
      selectedAgencyDispatcher?.(initialState)
      getRegListDispatcher?.()
    }
    getRegListDispatcher?.()
    selectedTemplateDispatcher?.(initialState)
    assessNameDispatcher?.('')
    selectedRespondentDispatcher?.([])
    selectedDaysDispatcher?.(0)
    respondentNoteDispatcher?.('')
    selectedAssesserDispatcher?.(initialStateEmail)
    getInvList?.('')
    getRegList?.('')
    getMarketList?.('')
    getAgencyList?.('')
    getTemplates?.('')
    getServiceTypeList?.('')
    getAssesser?.('')
    getRespondent?.('')

    selectedRegionDispatcher?.(initialState)
    selectedMarketDispatcher?.(initialState)
    selectedAgencyDispatcher?.(initialState)
    selectedTypeOfServiceDispatcher?.(initialStateTypeOfService)
    setTriggerReloadDispatcher?.(false)
  }, [])

  const handleNextStep = () => {
    let validationList: any[]
    let validateFields: object
    if (currentStep === StepNumber1) {
      validateFields = {
        regionId: selectedRegion,
        marketId: selectedMarket,
        agencyId: selectedAgency,
        serviceTypeId: selectedTypeOfService
      }
    } else {
      validateFields = {
        selectedTemplate: selectedTemplate
      }
    }
    validationList = validate(validateFields)
    setValidationErrors(validationList)
    const found = validationList.map((item: any) => !!item.error).includes(true)
    if (!found) {
      if (currentStep <= steps.length - 1) {
        setNextStep(currentStep + 1)
      }
    }
  }
  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setNextStep(currentStep - 1)
    }
  }

  return (
    <WppSideModal open={open} size="2xl" onWppSideModalClose={closeMainModal}>
      <h3 slot="header" className={styles.assessmentHeader}>
        Create Assessment
      </h3>
      <div slot="body">
        <WppStepper className={styles.riskAssessContainer} activeStep={currentStep} orientation="horizontal">
          {steps.map((item: { id: number; name: string }) => (
            <WppStep key={item.id} className={styles.steps}>
              <p slot="label" className={styles.stepText}>
                {item.name}
              </p>
            </WppStep>
          ))}
        </WppStepper>
        <div className={styles.innerPage}>
          {currentStep === 1 && <Step1 validationErrors={validationErrors} setValidationErrors={setValidationErrors} />}
          {currentStep === 2 && <Step2 validationErrors={validationErrors} setValidationErrors={setValidationErrors} />}
          {currentStep === 3 && <Step3 closeSideModal={closeMainModal} showToast={showToast} />}
        </div>
        <div className={styles.button}>
          {currentStep !== 1 && (
            <WppButton className={styles.customBtnSecondary} variant="secondary" onClick={handlePreviousStep}>
              Previous Step
            </WppButton>
          )}
          {currentStep !== 3 && (
            <WppButton variant="secondary" className={styles.customBtnSecondary} onClick={handleNextStep}>
              Next Step
            </WppButton>
          )}
        </div>
      </div>
    </WppSideModal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssessment)
