import { SELECTED_SIDE_NAV_ORG, SELECTED_AGENCY } from './types'

export const selectedSideNavOrg = (org: object) => ({
  type: SELECTED_SIDE_NAV_ORG,
  payload: org
})

export const selectedSideNavOrgDispatcher = (org: { id: number; name: string }) => (dispatch: any) => {
  dispatch(selectedSideNavOrg(org))
}

export const selectedAgency = (agency: object) => ({
  type: SELECTED_AGENCY,
  payload: agency
})
