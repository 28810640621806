import React, { useState, useEffect } from 'react'

import {
  WppButton,
  WppSideModal,
  WppToast,
  WppBanner,
  WppActionButton,
  WppIconClose,
  WppTag
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import DataGrid from 'components/dataGrid/DataGrid'
import DataGridOfflineComp from 'components/dataGrid/DataGridOfflineComp'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'
import { PROCESS_ASSESSMENT_GRID_HEADER_DETAILS } from 'helper/constants'
import { getLocalDate } from 'helper/Helper'

import { getInventoryByIdDispatcher } from './action'
import styles from './RowDetails.module.scss'
import { RowDetailsModalProps } from '../../../../helper/interface'
import serviceURL from '../../../../helper/serviceURL'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import CreateProcessingActivity from '../createProcessingActivity/CreateProcessingActivity'

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryByIdDispatcher: (type: string, id: string, head: object) =>
    dispatch(getInventoryByIdDispatcher(type, id, head)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})

const mapStateToProps = (state: any) => {
  return {
    rowDetails: state.inventoryRowDetailsRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    isTriggerReload: state?.triggerReload?.data
  }
}

interface CardHeaderProps {
  id: string
  name: string
  description: string
  businessProcessOwner: string
  createdBy: string
  createdAt: string
  modifiedBy: string
  modifiedAt: string
  purpose: string
  agency: {
    id: number
    name: string
    market: {
      id: number
      name: string
      region: {
        id: number
        name: string
      }
    }
  }
  externalLegalEntities: {
    client: { id: number; label: string }[]
    supplier: { id: number; label: string }[]
  }
}

const CARDHEADER_INITIAL_STATE = {
  id: '',
  name: '',
  description: '',
  businessProcessOwner: '',
  createdBy: '',
  createdAt: '',
  modifiedBy: '',
  modifiedAt: '',
  purpose: '',
  agency: {
    id: 0,
    name: '',
    market: {
      id: 0,
      name: '',
      region: {
        id: 0,
        name: ''
      }
    }
  },
  externalLegalEntities: {
    client: [],
    supplier: []
  }
}

const RowDetails = ({
  open,
  onClose,
  clickedRowData,
  isTriggerReload,
  setTriggerReloadDispatcher
}: RowDetailsModalProps) => {
  console.log('clickedRowData', clickedRowData)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showAssessmentSuccessToast, setShowAssessmentSuccessToast] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cardHeader, setCardHeader] = useState<CardHeaderProps>(CARDHEADER_INITIAL_STATE)
  const {
    osApi: { getAccessToken }
  } = useOs()
  const { axiosInstance } = useAxiosInterceptors()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/pa/`

  useEffect(() => {
    fetchInventoryById()
  }, [])

  const fetchInventoryById = () => {
    setIsLoading(true)
    const apiUrl = baseApiUrl + clickedRowData?.id
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then(res => {
        setCardHeader(res?.data)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  return (
    <>
      <WppSideModal open={open} size="2xl" onWppSideModalClose={onClose} className={styles.modalBox}>
        {isLoading ? (
          <div slot="body">
            <DataGridOfflineComp />
          </div>
        ) : (
          <>
            {showAssessmentSuccessToast && (
              <WppToast
                className={styles.toast}
                message="Assessment launched successfully"
                type="success"
                duration={2000}
                onWppToastComplete={() => setShowAssessmentSuccessToast(false)}
                data-testid="vendor-delete-toast"
              />
            )}
            <div slot="header" className={styles.header}>
              <h3>{cardHeader?.name}</h3>
              <div className={styles.buttonAction}>
                <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                  <WppIconClose className={styles.close} />
                </WppActionButton>
              </div>
            </div>
            <div slot="body" className={styles.sideModalBody}>
              {isToShowBanner && (
                <div className={styles.banner}>
                  <WppBanner id="banner" type="information" show={isToShowBanner}>
                    Unable to fetch inventory. Please refresh or try after some time.
                  </WppBanner>
                </div>
              )}
              {showToast && (
                <WppToast
                  className={styles.toast}
                  message="Inventory linked successfully"
                  type="success"
                  duration={2000}
                  onWppToastComplete={() => setShowToast(false)}
                />
              )}
              <div className={styles.secInventoryDetailsTop}>
                <div className={styles.upperHeader}>
                  <span className={styles.key}>Description: </span>
                  <span className={styles.value}>{cardHeader?.description}</span>
                </div>
                <div className={styles.upperHeader}>
                  <span className={styles.key}>Purpose of Processing: </span>
                  <span className={styles.value}>{cardHeader?.purpose}</span>
                </div>
                <div className={styles.upperHeader}>
                  <span className={styles.key}>Business Process Owner: </span>
                  <span className={styles.value}>{cardHeader?.businessProcessOwner}</span>
                </div>
                <div className={styles.lowerHeader}>
                  <div className={styles.label}>
                    <span className={styles.key}>Created By: </span>
                    <span className={styles.value}>{cardHeader?.createdBy}</span>
                  </div>
                  <div className={styles.label}>
                    <span className={styles.key}>Modified By: </span>
                    <span className={styles.value}>{cardHeader?.modifiedBy}</span>
                  </div>
                  <div className={styles.label}>
                    <span className={styles.key}>Created At: </span>
                    <span className={styles.value}>{getLocalDate(cardHeader?.createdAt, true)}</span>
                  </div>
                  <div className={styles.label}>
                    <span className={styles.key}>Modified At: </span>
                    <span className={styles.value}>{getLocalDate(cardHeader?.modifiedAt, true)}</span>
                  </div>
                  <div className={styles.label}>
                    <span className={styles.key}>Clients: </span>
                    {cardHeader?.externalLegalEntities?.client?.length ? (
                      <span className={styles.value}>
                        <WppTag
                          label={cardHeader?.externalLegalEntities?.client?.map(item => item?.label).join(', ')}
                          className={styles.tag}
                          maxLabelLength={40}
                          tooltipConfig={{ placement: 'bottom' }}
                          data-testid="pa-client"
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={styles.label}>
                    <span className={styles.key}>Suppliers: </span>
                    {cardHeader?.externalLegalEntities?.supplier?.length ? (
                      <span className={styles.value}>
                        <WppTag
                          label={cardHeader?.externalLegalEntities?.supplier?.map(item => item?.label).join(', ')}
                          className={styles.tag}
                          maxLabelLength={40}
                          tooltipConfig={{ placement: 'bottom' }}
                          data-testid="pa-supplier"
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.assessmentBox}>
                <h2 className={styles.heading}>Assessments</h2>
                <DataGrid
                  apiEndpoint={baseApiUrl + clickedRowData?.id + '/assessments'}
                  headerDetails={PROCESS_ASSESSMENT_GRID_HEADER_DETAILS}
                  apiMethod="GET"
                  pageSize={5}
                  isTriggerReload={isTriggerReload}
                />
              </div>
            </div>
            <div slot="actions">
              <WppButton className={styles.customBtnSecondary} variant="secondary" onClick={() => setIsModalOpen(true)}>
                Launch Assessment
              </WppButton>
            </div>
          </>
        )}
      </WppSideModal>
      {isModalOpen && (
        <CreateProcessingActivity
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          triggerReload={setTriggerReloadDispatcher}
          paData={cardHeader}
          launchPA={true}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)
