import { SELECTED_SIDE_NAV_ORG, SELECTED_AGENCY } from './types'

const initialState = {
  data: ''
}

export function selectedSideNavOrgRed(state = initialState, action: any) {
  if (action.type === SELECTED_SIDE_NAV_ORG) {
    return {
      ...state,
      data: action.payload
    }
  } else {
    return state
  }
}

export function selectedAgencyRed(state = { data: '' }, action: any) {
  if (action.type === SELECTED_AGENCY) {
    return {
      ...state,
      data: action.payload
    }
  } else {
    return state
  }
}
