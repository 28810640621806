const nodeDetails = {
  parentOnlineIdentifiers: {
    position: { x: 70, y: 50 },
    data: { label: 'Online Identifiers' },
    style: {
      borderColor: '#FDF4F5',
      color: 'black',
      fontSize: '1.4rem',
      fontWeight: 600,
      width: '80rem',
      background: '#e9f5db',
      height: '11rem'
    }
  },
  parentOfflineIdentifiersAmer: {
    position: { x: 975, y: 50 },
    data: { label: 'Offline Identifiers (AMER)' },
    style: {
      borderColor: '#FDEAE2',
      color: 'black',
      fontSize: '1.4rem',
      fontWeight: 600,
      width: '20rem',
      background: '#FDEAE2',
      height: '14.5rem'
    }
  },
  parentOfflineIdentifiersNonAmer: {
    position: { x: 1250, y: 50 },
    data: { label: 'Offline Identifiers (Non AMER)' },
    style: {
      borderColor: '#DAF5FF',
      color: 'black',
      fontSize: '1.4rem',
      fontWeight: 600,
      width: '20rem',
      background: '#DAF5FF',
      height: '11rem'
    }
  },
  parentIDN: {
    position: { x: 450, y: 510 },
    data: { label: 'IDN' },
    style: {
      borderColor: '#DAF5FF',
      color: 'black',
      textAlign: 'right',
      fontSize: '1.4rem',
      fontWeight: 600,
      width: '65rem',
      background: '#dcdcdd',
      height: '7rem'
    }
  },
  mookieID: {
    position: { x: 100, y: 100 },
    data: { label: 'Mookie ID - {Name: id}, {Source: CPP}' },
    parentNode: 'parentOnlineIdentifiers',
    style: {
      borderColor: '#A4BC92',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '12rem',
      height: '6.5rem'
    }
  },
  activateCookie: {
    position: { x: 250, y: 100 },
    data: { label: 'Activate Cookie - {Name: ibkukinet}, {Source: CPP}' },
    parentNode: 'parentOnlineIdentifiers',
    style: {
      borderColor: '#f20089',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '13rem',
      height: '6.5rem'
    }
  },
  deviceID: {
    position: { x: 410, y: 100 },
    data: { label: 'Device ID - {Name: deviceId}, {Source: DAA}' },
    parentNode: 'parentOnlineIdentifiers',
    style: {
      borderColor: '#f28f3b',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '12.5rem',
      height: '6.5rem'
    }
  },
  hashedPhone: {
    position: { x: 565, y: 100 },
    data: { label: 'Hashed Phone - {Name: md5Phone, sha256Phone}, {Source: DAA}' },
    parentNode: 'parentOnlineIdentifiers',
    style: {
      borderColor: '#c8553d',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '12rem',
      height: '9.5rem'
    }
  },
  hashedEmails: {
    position: { x: 720, y: 100 },
    data: { label: 'Hashed Emails - {Name: md5Email, sha256Email}, {Source: DAA}' },
    parentNode: 'parentOnlineIdentifiers',
    style: {
      borderColor: '#227c9d',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '12rem',
      height: '9.5rem'
    }
  },
  PII: {
    position: { x: 1000, y: 100 },
    data: {
      label: 'PII - {Name: First name, last name, Email, St name, Post code, Address, Phone number}, {Source: DAA})'
    },
    parentNode: 'parentOfflineIdentifiersAmer',
    style: {
      borderColor: '#fe6d73',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '15rem',
      height: '10.5rem'
    }
  },
  email: {
    position: { x: 1300, y: 100 },
    data: { label: 'Email - {Name: email}, {Source: CPP}' },
    parentNode: 'parentOfflineIdentifiersNonAmer',
    style: {
      borderColor: '#b8b8ff',
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 400,
      borderRadius: '1rem',
      width: '12rem',
      height: '7rem'
    }
  },
  mInsights: {
    position: { x: 150, y: 400 },
    data: { label: '[m]Insights' },
    style: {
      background: '#B91C1C',
      color: 'white',
      fontSize: '1.4rem',
      fontWeight: 400,
      borderColor: '#B91C1C',
      borderRadius: '1rem',
      width: '12rem',
      height: '5rem'
    }
  },
  activateProdbase: {
    position: { x: 480, y: 500 },
    data: { label: 'Activate / Prodbase' },
    style: {
      background: '#697077',
      color: 'white',
      fontSize: '1.4rem',
      fontWeight: 400,
      borderColor: '#697077',
      borderRadius: '1rem',
      width: '12rem',
      height: '5rem'
    }
  },
  intelAmerilink: {
    position: { x: 700, y: 500 },
    data: { label: 'Intellisight / Amerilink' },
    style: {
      background: '#0E7490',
      color: 'white',
      fontSize: '1.4rem',
      fontWeight: 400,
      borderColor: '#0E7490',
      borderRadius: '1rem',
      width: '12rem',
      height: '5rem'
    }
  },
  clientSybase: {
    position: { x: 920, y: 500 },
    data: { label: 'Client Service / Sybase' },
    style: {
      background: '#0D4F25',
      color: 'white',
      fontSize: '1.4rem',
      fontWeight: 400,
      borderColor: '#0D4F25',
      borderRadius: '1rem',
      width: '12rem',
      height: '5rem'
    }
  },
  audienceOrigin: {
    position: { x: 1350, y: 430 },
    data: { label: 'Audience Origin' },
    style: {
      background: '#2F0069',
      color: 'white',
      fontSize: '1.4rem',
      fontWeight: 400,
      borderColor: '#2F0069',
      borderRadius: '1rem',
      width: '12rem',
      height: '5rem'
    }
  }
}

const node = [
  {
    id: 'parent-online-identifiers',
    position: nodeDetails['parentOnlineIdentifiers'].position,
    data: nodeDetails['parentOnlineIdentifiers'].data,
    style: nodeDetails['parentOnlineIdentifiers'].style,
    type: 'input'
  },
  {
    id: 'mookie-id',
    position: nodeDetails['mookieID'].position,
    data: nodeDetails['mookieID'].data,
    style: nodeDetails['mookieID'].style,
    type: 'input'
  },
  {
    id: 'activate-cookie',
    position: nodeDetails['activateCookie'].position,
    data: nodeDetails['activateCookie'].data,
    style: nodeDetails['activateCookie'].style,
    type: 'input'
  },
  {
    id: 'device-id',
    position: nodeDetails['deviceID'].position,
    data: nodeDetails['deviceID'].data,
    style: nodeDetails['deviceID'].style,
    type: 'input'
  },
  {
    id: 'hashed-phone',
    position: nodeDetails['hashedPhone'].position,
    data: nodeDetails['hashedPhone'].data,
    style: nodeDetails['hashedPhone'].style,
    type: 'input'
  },
  {
    id: 'hashed-emails',
    position: nodeDetails['hashedEmails'].position,
    data: nodeDetails['hashedEmails'].data,
    style: nodeDetails['hashedEmails'].style,
    type: 'input'
  },
  {
    id: 'parent-offline-identifiers-amer',
    position: nodeDetails['parentOfflineIdentifiersAmer'].position,
    data: nodeDetails['parentOfflineIdentifiersAmer'].data,
    style: nodeDetails['parentOfflineIdentifiersAmer'].style,
    type: 'input'
  },
  {
    id: 'pii',
    position: nodeDetails['PII'].position,
    data: nodeDetails['PII'].data,
    style: nodeDetails['PII'].style,
    type: 'input'
  },
  {
    id: 'parent-offline-identifiers-non-amer',
    position: nodeDetails['parentOfflineIdentifiersNonAmer'].position,
    data: nodeDetails['parentOfflineIdentifiersNonAmer'].data,
    style: nodeDetails['parentOfflineIdentifiersNonAmer'].style,
    type: 'input'
  },
  {
    id: 'email',
    position: nodeDetails['email'].position,
    data: nodeDetails['email'].data,
    style: nodeDetails['email'].style,
    type: 'input'
  },
  {
    id: 'm-insights',
    position: nodeDetails['mInsights'].position,
    data: nodeDetails['mInsights'].data,
    style: nodeDetails['mInsights'].style,
    type: 'output'
  },
  {
    id: 'parent-idn',
    position: nodeDetails['parentIDN'].position,
    data: nodeDetails['parentIDN'].data,
    style: nodeDetails['parentIDN'].style,
    type: 'output'
  },
  {
    id: 'activate-prodbase',
    position: nodeDetails['activateProdbase'].position,
    data: nodeDetails['activateProdbase'].data,
    style: nodeDetails['activateProdbase'].style,
    type: 'output'
  },
  {
    id: 'intel-amerilink',
    position: nodeDetails['intelAmerilink'].position,
    data: nodeDetails['intelAmerilink'].data,
    style: nodeDetails['intelAmerilink'].style,
    type: 'output'
  },
  {
    id: 'client-sybase',
    position: nodeDetails['clientSybase'].position,
    data: nodeDetails['clientSybase'].data,
    style: nodeDetails['clientSybase'].style,
    type: 'output'
  },
  {
    id: 'audience-origin',
    position: nodeDetails['audienceOrigin'].position,
    data: nodeDetails['audienceOrigin'].data,
    style: nodeDetails['audienceOrigin'].style,
    type: 'output'
  }
]

export default node
