// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OWzaJ{border:1px solid #d3d3d3;padding:2rem;margin:.5rem;border-radius:1rem;box-shadow:0 4px 12px 0 rgba(94,118,181,.3)}.wcRst{font-size:1.4rem;font-weight:600;font-family:inherit;line-height:2.4rem;color:#000}.rAGN7{font-size:1.4rem}`, "",{"version":3,"sources":["webpack://./src/components/linkedInvCard/LinkedInvCard.module.scss"],"names":[],"mappings":"AAAA,OACE,wBAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,2CAAA,CAGF,OACE,gBAAA,CACA,eAAA,CACA,mBAAA,CACA,kBAAA,CACA,UAAA,CAGF,OACE,gBAAA","sourcesContent":[".innerAccord {\n  border: 1px solid lightgray;\n  padding: 2rem;\n  margin: 0.5rem;\n  border-radius: 1rem;\n  box-shadow: 0 4px 12px 0 rgba(94, 118, 181, 0.3);\n}\n\n.key {\n  font-size: 1.4rem;\n  font-weight: 600;\n  font-family: inherit;\n  line-height: 2.4rem;\n  color: black;\n}\n\n.childValue {\n  font-size: 1.4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerAccord": `OWzaJ`,
	"key": `wcRst`,
	"childValue": `rAGN7`
};
export default ___CSS_LOADER_EXPORT___;
