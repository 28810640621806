import { GET_INITIAL_VENDOR_DATA, GET_Assessment_VENDOR_DATA, SET_CREATE_VENDOR_DATA, SET_TRIGGER_RELOAD } from './types'

const initialState = {
  vendor: {},
  assessment: {}
}

export function vendorDetails(state = initialState, action: any) {
  if (action.type === GET_INITIAL_VENDOR_DATA) {
    return {
      ...state,
      vendor: action.payload.vendorData,
      assessment: action.payload.assessmentData
    }
  } else if (action.type === GET_Assessment_VENDOR_DATA) {
    return {
      ...state,
      assessment: action.payload
    }
  } else {
    return state
  }
}

export function createVendorDetails(state = { data: {} }, action: any) {
  if (action.type === SET_CREATE_VENDOR_DATA) {
    return {
      ...state,
      data: action.payload
    }
  } else {
    return state
  }
}

export function triggerReload(state = { data: '' }, action: any) {
  if (action.type === SET_TRIGGER_RELOAD) {
    return {
      ...state,
      data: action?.payload
    }
  }
  else {
    return state
  }
}
