import React, { useCallback, useState, useEffect } from 'react'

import { Container, Grid } from '@mui/material'
import { WppButton, WppIconAdd } from '@wppopen/components-library-react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import DataGrid from 'components/dataGrid/DataGrid'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'

import { createInventoryDispatcher } from './createProcessingActivity/action'
import CreateProcessingActivity from './createProcessingActivity/CreateProcessingActivity'
import { InventoryListProps } from './interface'
import styles from './ProcessingActivityList.module.scss'
import RowDetails from './rowDetails/RowDetails'
import { PROCESSING_ACTIVITY_GRID_HEADER_DETAILS } from '../../../helper/constants'
import isObjectEmpty from '../../../helper/Helper'
import serviceURL from '../../../helper/serviceURL'

const mapDispatchToProps = (dispatch: any) => ({
  createInventoryDispatcher: (obj: object) => dispatch(createInventoryDispatcher(obj)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})
const mapStateToProps = (state: any) => {
  return {
    createInventory: state.createInventoryRed.data,
    isTriggerReload: state?.triggerReload?.data
  }
}

const InventoryList = ({ isTriggerReload, setTriggerReloadDispatcher }: InventoryListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const initialRowData = {
    id: '',
    name: '',
    description: '',
    legalEntityInventory: '',
    vendorInventory: '',
    daInventory: '',
    paInventory: ''
  }
  const [clickedRowData, setClickedRowData] = useState<any>(initialRowData)
  const [openClickedRowModal, setOpenClickedRowModal] = useState(false)
  const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/pa`
  const navigate = useNavigate()
  let { inventoryId } = useParams()

  useEffect(() => {
    if (inventoryId) {
      setTriggerReloadDispatcher(true)
      setClickedRowData({ id: inventoryId })
      setOpenClickedRowModal(true)
    }
  }, [inventoryId, setTriggerReloadDispatcher])

  const getClickedRow = useCallback(
    (data: object): void => {
      if (!isObjectEmpty(data)) {
        setClickedRowData(data)
        setOpenClickedRowModal(true)
        setTriggerReloadDispatcher(false)
      }
    },
    [clickedRowData]
  )
  const handleCreatePa = useCallback((): void => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = () => {
    setOpenClickedRowModal(false)
    navigate('/dm/processing-activity')
  }

  return (
    <div className={styles.container}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3, sm: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          rowSpacing={10}
          style={{ position: 'relative' }}
        >
          <div className={styles.inventoryContainer}>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              display="flex"
              justifyContent="flex-start"
              alignSelf="flex-start"
              className={styles.createInventoryButton}
            >
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                onClick={handleCreatePa}
                size="s"
              >
                <WppIconAdd slot="icon-start" className={styles.customIcon} />
                Create Processing Activity
              </WppButton>
            </Grid>
          </div>
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '3rem', marginBottom: '5rem', height: '100vh' }}>
            <DataGrid
              apiEndpoint={baseApiUrl + '/search'}
              headerDetails={PROCESSING_ACTIVITY_GRID_HEADER_DETAILS}
              apiMethod="POST"
              pageSize={25}
              onRowClickedHandler={getClickedRow}
              isClearFilters={true}
              isTriggerReload={isTriggerReload}
            />
          </Grid>
        </Grid>
        {isModalOpen && (
          <CreateProcessingActivity
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            triggerReload={setTriggerReloadDispatcher}
          />
        )}
        {openClickedRowModal && (
          <RowDetails open={openClickedRowModal} clickedRowData={clickedRowData} onClose={handleModalClose} />
        )}
      </Container>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryList)
