// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fwnbB{font-size:1.4rem;font-weight:600;font-family:inherit;line-height:2.4rem;color:#000;word-break:break-word}.pAuEK{font-size:1.4rem;word-break:break-word}.udF6y{box-shadow:0 1px 4px 0 rgba(21,118,214,.15)}.ujT7k{background-image:linear-gradient(45deg, #E0FAEA, transparent);padding:2rem;border-radius:1rem}.O8Ofl{margin:1.5rem 0 4rem 0}`, "",{"version":3,"sources":["webpack://./src/containers/piaModule/riskRegister/RowDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,eAAA,CACA,mBAAA,CACA,kBAAA,CACA,UAAA,CACA,qBAAA,CAGF,OACE,gBAAA,CACA,qBAAA,CAGF,OACE,2CAAA,CAGF,OACE,6DAAA,CACA,YAAA,CACA,kBAAA,CAIF,OACE,sBAAA","sourcesContent":[".key {\n  font-size: 1.4rem;\n  font-weight: 600;\n  font-family: inherit;\n  line-height: 2.4rem;\n  color: black;\n  word-break: break-word;\n}\n\n.childValue {\n  font-size: 1.4rem;\n  word-break: break-word;\n}\n\n.childContent {\n  box-shadow: 0 1px 4px 0 rgba(21, 118, 214, 0.15);\n}\n\n.secInventoryDetailsTop {\n  background-image: linear-gradient(45deg, #E0FAEA, transparent);\n  padding: 2rem;\n  border-radius: 1rem;\n  //box-shadow: 0 4px 12px 0 rgba(94, 118, 181, 0.3)\n}\n\n.sideModalBody {\n  margin: 1.5rem 0 4rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"key": `fwnbB`,
	"childValue": `pAuEK`,
	"childContent": `udF6y`,
	"secInventoryDetailsTop": `ujT7k`,
	"sideModalBody": `O8Ofl`
};
export default ___CSS_LOADER_EXPORT___;
