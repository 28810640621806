import React from 'react'

import { Grid, Container } from '@mui/material'
import { WppSideModal } from '@wppopen/components-library-react'

import styles from './RowDetails.module.scss'
import StatusIndicator from '../../../components/dataGrid/StatusIndicator'
const RowDetails = ({ open, onClose, clickedRowData }: any) => {
  return (
    <WppSideModal open={open} size="m" onWppSideModalClose={onClose}>
      <h3 slot="header">{clickedRowData && clickedRowData.name}</h3>
      <div slot="body" className={styles.sideModalBody}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.secInventoryDetailsTop}>
                <div>
                  <span className={styles.key}>Status: </span>
                  <StatusIndicator params={{ value: clickedRowData?.status }} />
                </div>
                <div>
                  <span className={styles.key}>Id: </span>
                  <span>{clickedRowData?.id}</span>
                </div>
                <div>
                  <span className={styles.key}>Inventory Id: </span>
                  <span>{clickedRowData?.inventoryId}</span>
                </div>
                <div>
                  <span className={styles.key}>Mitigation Steps: </span>
                  <span>{clickedRowData?.mitigationSteps}</span>
                </div>
                <div>
                  <span className={styles.key}>Identification Date: </span>
                  <span>{clickedRowData?.dateIdentified}</span>
                </div>
                <div>
                  <span className={styles.key}>Last Updated Date: </span>
                  <span>{clickedRowData?.lastUpdated}</span>
                </div>
                <div>
                  <span className={styles.key}>Risk Score: </span>
                  <span>{clickedRowData?.riskScore}</span>
                </div>
                <div>
                  <span className={styles.key}>Risk Level </span>
                  <StatusIndicator params={{ value: clickedRowData?.riskLevel }} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </WppSideModal>
  )
}

export default RowDetails
